import { Edit } from 'react-admin'

import MeditationBackgroundForm from './MeditationBackgroundForm'

const MeditationBackgroundEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <MeditationBackgroundForm />
    </Edit>
  )
}

export default MeditationBackgroundEdit
