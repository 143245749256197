import { Create } from 'react-admin'

import SlideshowForm from './SlideshowForm'

const SlideshowCreate = props => (
  <Create redirect="list" {...props}>
    <SlideshowForm />
  </Create>
)

export default SlideshowCreate
