import { SimpleForm, ReferenceInput, AutocompleteInput } from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const ReferralBookForm = props => {
  return (
    <SimpleForm {...props} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <ReferenceInput label="Book title" source="bookId" reference="books">
            <AutocompleteInput
              optionText="title"
              options={{
                fullWidth: true,
              }}
              required
              variant="standard"
              validate={validateRequired('Book title')}
              filterToQuery={searchText => ({
                title: { $iLike: `%${searchText}%` },
              })}
            />
          </ReferenceInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default ReferralBookForm
