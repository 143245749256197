export const hyphenToCamelCaseWithPrefix = (input, prefix) => {
  const words = input.split('-')
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return prefix + word.charAt(0).toUpperCase() + word.slice(1)
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  })
  return camelCaseWords.join('')
}

export const camelCaseWithPrefixToHyphen = (input, prefix) => {
  const regex = new RegExp(`^${prefix}`, 'i')
  const withoutPrefix = input.replace(regex, '')
  const hyphenSeparated = withoutPrefix.replace(/([a-z])([A-Z])/g, '$1-$2')
  return hyphenSeparated.toLowerCase()
}
