import {
  TextInput,
  FileInput,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
  usePermissions,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomFileField from '../../components/CustomFileField'
import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import { ROLES } from '../../components/constants'
import GridContainer from '../../components/GridContainer'

import { PRICE_CHOICES } from './priceTiers'

const isFree = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]
const statuses = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]

const EbookForm = () => {
  const { permissions } = usePermissions()

  return (
    <SimpleForm
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="title"
            fullWidth
            variant="standard"
            validate={[validateRequired('Title')]}
          />
        </Grid>

        <Grid item xs={6}>
          <ReferenceInput
            label="Related Book"
            source="relatedBookId"
            reference="books">
            <AutocompleteInput
              optionText="title"
              fullWidth
              variant="standard"
              alwaysOn
              filterToQuery={searchText => ({
                title: { $iLike: `%${searchText}%` },
              })}
              allowEmpty
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6}>
          <ReferenceInput
            label="Related BookSummary"
            source="relatedBookSummaryId"
            reference="book-summaries">
            <AutocompleteInput
              optionText="title"
              fullWidth
              variant="standard"
              filterToQuery={searchText => ({
                title: { $iLike: `%${searchText}%` },
              })}
              allowEmpty
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            label="Is free?"
            source="isFree"
            choices={isFree}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Is Free Ebook')}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            label="Publish"
            source="status"
            choices={statuses}
            default={false}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Publish')}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectInput
            source="price"
            choices={PRICE_CHOICES}
            required
            fullWidth
            variant="standard"
            margin="normal"
            validate={validateRequired('Price')}
          />
        </Grid>

        <Grid item xs={4}>
          <ReferenceInput
            label="Primary category"
            source="primaryCategoryId"
            reference="categories"
            filter={{
              type: 'ebook',
              primary: true,
            }}>
            <AutocompleteInput
              optionText="name"
              fullWidth
              required
              variant="standard"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                type: 'ebook',
                primary: true,
              })}
              validate={validateRequired('Primary category')}
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={8}>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData.primaryCategoryId) return null
              return (
                <ReferenceArrayInput
                  label="Secondary categories"
                  source="secondaryCategoryIds"
                  filter={{
                    type: 'ebook',
                    primary: true,
                    parentId: formData.primaryCategoryId,
                  }}
                  reference="categories">
                  <AutocompleteArrayInput
                    optionText="name"
                    fullWidth
                    required
                    variant="standard"
                    filterToQuery={searchText => ({
                      name: { $iLike: `%${searchText}%` },
                      type: 'ebook',
                      primary: false,
                      parentId: formData.primaryCategoryId,
                    })}
                  />
                </ReferenceArrayInput>
              )
            }}
          </FormDataConsumer>
        </Grid>

        <Grid item xs={12}>
          <ReferenceArrayInput
            label="Topics"
            source="topicIds"
            reference="topics"
            filter={{
              type: 'ebook',
            }}>
            <AutocompleteArrayInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                type: 'ebook',
              })}
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ReferenceArrayInput
            label="Authors"
            source="authorIds"
            reference="authors">
            <AutocompleteArrayInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
              })}
              validate={validateRequired('Authors')}
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <FileInput
            source="fileUrl"
            label="File Epub"
            accept="application/epub+zip"
            multiple={false}
            validate={validateRequired('File Epub')}>
            <CustomFileField
              source="src"
              title="Uploaded file"
              canDownload={permissions === ROLES.ADMIN}
            />
          </FileInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default EbookForm
