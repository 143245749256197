import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  DateField,
} from 'react-admin'

import CustomSwitchField from '../../components/CustomSwitchField'

const CategoryList = props => (
  <List {...props} perPage={1000} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <CustomSwitchField
        resource="category-groups"
        label="Status"
        switchField="status"
      />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default CategoryList
