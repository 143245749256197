import { Edit } from 'react-admin'

import PromotionForm from './PromotionForm'

const PromotionEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <PromotionForm />
    </Edit>
  )
}

export default PromotionEdit
