import {
  TextInput,
  ImageInput,
  NumberInput,
  SimpleForm,
  SelectInput,
  DateInput,
  minValue,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import { BOOK_STATUSES } from '../../components/constants'

const enableOptions = [
  { id: true, name: 'true' },
  { id: false, name: 'false' },
]

const validateDimension = [minValue(0)]

const CampaignForm = ({ ...props }) => {
  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={10}>
          <TextInput
            label="Campaign name"
            source="name"
            resettable
            fullWidth
            variant="standard"
            multiline
            validate={validateRequired('Campaign name')}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            label="Enable"
            source="status"
            choices={enableOptions}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Enable')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>

        <Grid item xs={6}>
          <DateInput
            source="startDate"
            fullWidth
            variant="standard"
            validate={validateRequired('startDate')}></DateInput>
        </Grid>
        <Grid item xs={6}>
          <DateInput
            source="endDate"
            fullWidth
            variant="standard"
            validate={validateRequired('endDate')}></DateInput>
        </Grid>

        {/* Banner */}

        {/* <------ Bg Image - Phone --------> */}
        <Grid item xs={12}>
          <ImageInput
            accept="image/*"
            source="bannerInfo.bgImagePhone.url"
            label="Bg Image (phone)"
            multiple={false}
            validate={validateRequired('Bg Image (phone)')}>
            <CustomImageField
              originalSrc={true}
              source="src"
              title="Bg Image"
            />
          </ImageInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Bg Image Width (phone)'),
            ]}
            label={'Bg Image Width (phone)'}
            source="bannerInfo.bgImagePhone.width"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Bg Image Height (phone)'),
            ]}
            label={'Bg Image Height (phone)'}
            source="bannerInfo.bgImagePhone.height"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>

        {/* <------ Bg image - Tablet --------> */}
        <Grid item xs={12}>
          <ImageInput
            accept="image/*"
            source="bannerInfo.bgImageTablet.url"
            label="Bg Image (tablet)"
            multiple={false}
            validate={validateRequired('Bg Image (tablet)')}>
            <CustomImageField
              originalSrc={true}
              source="src"
              title="Bg Image"
            />
          </ImageInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Bg Image Width (tablet)'),
            ]}
            label={'Bg Image Width (tablet)'}
            source="bannerInfo.bgImageTablet.width"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Bg Image Height (tablet)'),
            ]}
            label={'Bg Image Height (tablet)'}
            source="bannerInfo.bgImageTablet.height"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>

        {/* <------ Text Layer - Phone --------> */}
        <Grid item xs={12}>
          <ImageInput
            accept="image/*"
            source="bannerInfo.textLayerPhone.url"
            label="Text Layer (phone)"
            multiple={false}
            validate={validateRequired('Text Layer (phone)')}>
            <CustomImageField
              originalSrc={true}
              source="src"
              title="Text Layer"
            />
          </ImageInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Text Layer Width (phone)'),
            ]}
            label={'Text Layer Width (phone)'}
            source="bannerInfo.textLayerPhone.width"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Text Layer Height (phone)'),
            ]}
            label={'Text Layer Height (phone)'}
            source="bannerInfo.textLayerPhone.height"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>

        {/* <------ Text Layer - Tablet --------> */}
        <Grid item xs={12}>
          <ImageInput
            accept="image/*"
            source="bannerInfo.textLayerTablet.url"
            label="Text Layer (tablet)"
            multiple={false}
            validate={validateRequired('Text Layer (tablet)')}>
            <CustomImageField
              originalSrc={true}
              source="src"
              title="Text Layer"
            />
          </ImageInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Text Layer Width (tablet)'),
            ]}
            label={'Text Layer Width (tablet)'}
            source="bannerInfo.textLayerTablet.width"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Text Layer Height (tablet)'),
            ]}
            label={'Text Layer Height (tablet)'}
            source="bannerInfo.textLayerTablet.height"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>

        <ArrayInput
          label="Books"
          source="books"
          fullWidth
          validate={[
            validateRequired('Books'),
            // validateUnique('bookId', true),
          ]}>
          <SimpleFormIterator>
            <ReferenceInput
              label="Book"
              source={'id'}
              reference="books"
              filter={{
                isFree: false,
                membership_included: false,
                status: BOOK_STATUSES.PUBLISHED,
              }}
              rows={6}>
              <AutocompleteInput
                optionText="title"
                options={{
                  fullWidth: true,
                }}
                variant="standard"
                filterToQuery={searchText => ({
                  title: { $iLike: `%${searchText}%` },
                  isFree: false,
                  membership_included: false,
                  status: BOOK_STATUSES.PUBLISHED,
                })}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>

        <Grid item xs={10}>
          <TextInput
            source="tagTitle"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="tagDescription"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default CampaignForm
