import { Edit } from 'react-admin'

import AffiliatePaymentsForm from './AffiliatePaymentsForm'

const AffiliatePaymentsEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <AffiliatePaymentsForm editing />
    </Edit>
  )
}

export default AffiliatePaymentsEdit
