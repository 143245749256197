import { Create } from 'react-admin'

import MeditationQuoteForm from './MeditationQuoteForm'

const MeditationQuoteCreate = props => {
  return (
    <Create redirect="list" {...props}>
      <MeditationQuoteForm />
    </Create>
  )
}

export default MeditationQuoteCreate
