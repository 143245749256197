export const CONFIGURATION_SETTING_DATA_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
}

export const CONFIGURATION_SETTING_DATA_TYPE_LABEL = {
  [CONFIGURATION_SETTING_DATA_TYPE.STRING]: 'String',
  [CONFIGURATION_SETTING_DATA_TYPE.NUMBER]: 'Number',
  [CONFIGURATION_SETTING_DATA_TYPE.BOOLEAN]: 'Boolean',
}

export const CONFIGURATION_SETTING_USE_SCOPE = {
  HOMEPAGE_CONFIGURATIONS: 'homepage-configurations',
  PODCAST_TAB_CONFIGURATIONS: 'podcast-tab-configurations',
  ENGLISH_BOOK_TAB_CONFIGURATIONS: 'english-book-tab-configurations',
  COURSE_TAB_CONFIGURATIONS: 'course-tab-configurations',
}

export const CONFIGURATION_SETTING_USE_SCOPE_LABEL = {
  [CONFIGURATION_SETTING_USE_SCOPE.HOMEPAGE_CONFIGURATIONS]:
    'Homepage Configurations',
  [CONFIGURATION_SETTING_USE_SCOPE.PODCAST_TAB_CONFIGURATIONS]:
    'Podcast Tab Configurations',
  [CONFIGURATION_SETTING_USE_SCOPE.ENGLISH_BOOK_TAB_CONFIGURATIONS]:
    'English Book Tab Configurations',
  [CONFIGURATION_SETTING_USE_SCOPE.COURSE_TAB_CONFIGURATIONS]:
    'Course Tab Configurations',
}
