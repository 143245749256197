import { Fragment } from 'react'
import {
  TextInput,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  ImageInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'
import CustomSvgField from '../../components/CustomSvgField'
import { validateRequired } from '../../utils/validate'

const CATEGORY_TYPES = [
  { title: 'Books', type: 'book' },
  { title: 'Book summaries', type: 'book_summary' },
  { title: 'Ebooks', type: 'ebook' },
  { title: 'Podcasts', type: 'podcast' },
  { title: 'English books', type: 'english_book' },
  { title: 'Courses', type: 'course' },
]
const statuses = [
  { id: true, name: 'True' },
  { id: false, name: 'False' },
]

const CategoryGroupForm = props => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={8}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={[validateRequired('Type')]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Status"
            source="status"
            choices={statuses}
            default={false}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Status')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
            disabled={props.editing}
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="icon"
            label="Icon in SVG"
            accept="image/svg+xml"
            multiple={false}>
            <CustomSvgField />
          </ImageInput>
        </Grid>

        {CATEGORY_TYPES.map(({ title, type }) => (
          <Fragment key={title}>
            <h3>{title}</h3>
            <Grid item xs={12}>
              <ReferenceArrayInput
                label="Strong correlation categories"
                source={`${type}.strongCorrelationCategoryIds`}
                filter={{ type }}
                reference="categories">
                <AutocompleteArrayInput
                  optionText="name"
                  fullWidth
                  required
                  variant="standard"
                  filterToQuery={searchText => ({
                    name: { $iLike: `%${searchText}%` },
                    type,
                  })}
                />
              </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12}>
              <ReferenceArrayInput
                label="Weak correlation categories"
                source={`${type}.weakCorrelationCategoryIds`}
                filter={{ type }}
                reference="categories">
                <AutocompleteArrayInput
                  optionText="name"
                  fullWidth
                  required
                  variant="standard"
                  filterToQuery={searchText => ({
                    name: { $iLike: `%${searchText}%` },
                    type,
                  })}
                />
              </ReferenceArrayInput>
            </Grid>
          </Fragment>
        ))}
      </GridContainer>
    </SimpleForm>
  )
}

export default CategoryGroupForm
