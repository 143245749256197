import { Fragment } from 'react'
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  useGetOne,
} from 'react-admin'
import Grid from '@mui/material/Grid'
import get from 'lodash/get'
import { useWatch } from 'react-hook-form'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const Subscription = () => {
  const userId = useWatch({ name: 'userId' })

  const { data, isLoading } = useGetOne(
    'users',
    { id: userId },
    {
      enabled: !!userId,
    },
  )

  if (isLoading) {
    return null
  }

  const subscription = get(data, 'subscription', null)

  const isExpired =
    subscription &&
    subscription.endDate &&
    new Date() > new Date(subscription.endDate)

  return (
    <Fragment>
      <Grid item xs={6}>
        <TextInput
          required
          source="invoiceEmail"
          fullWidth
          variant="standard"
          defaultValue={subscription && subscription.invoiceEmail}
        />
      </Grid>
      <Grid item xs={6}>
        {subscription && subscription.code && !isExpired ? (
          <TextInput
            source="code"
            fullWidth
            variant="standard"
            defaultValue={subscription && subscription.code}
            disabled
          />
        ) : (
          <TextInput source="code" fullWidth variant="standard" />
        )}
      </Grid>
      {subscription && !!subscription.amount && !isExpired && (
        <Grid item xs={12}>
          <TextInput
            label="Amount"
            source="amount"
            fullWidth
            variant="standard"
            defaultValue={subscription && subscription.amount}
            disabled
          />
        </Grid>
      )}
    </Fragment>
  )
}

const ManageSubscriptionCreate = props => {
  return (
    <Create redirect="list" undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <ReferenceInput label="Username" source="userId" reference="users">
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
                validate={validateRequired('user')}
                filterToQuery={searchText => ({
                  username: { $iLike: `%${searchText}%` },
                })}
              />
            </ReferenceInput>
          </Grid>

          <Subscription />
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default ManageSubscriptionCreate
