import { Create } from 'react-admin'

import BookSummaryForm from './BookSummaryForm'

const BookSummaryCreate = props => (
  <Create redirect="list" {...props}>
    <BookSummaryForm permissions={props.permissions} />
  </Create>
)

export default BookSummaryCreate
