import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  ReferenceInput,
  NumberField,
  AutocompleteInput,
  SelectInput,
  DateInput,
  ReferenceField,
  useListContext,
  useRecordContext,
} from 'react-admin'
import moment from 'moment'

import FilterComponent from '../../components/Filter'
import ApproveButtons from '../../components/ApproveAction/ApproveButtons'
import ApproveStatus from '../../components/ApproveAction/ApproveStatus'
import { ROLES } from '../../components/constants'
import { exportExcel } from '../../utils/exportExcel'

const TYPE_CREDIT_NAME = {
  monthly: 'Monthly',
  annually: 'Annually',
  bundle_3_v2: 'Bundle 3',
  bundle_5_v2: 'Bundle 5',
  bundle_10_v2: 'Bundle 10',
  bundle: 'Bundle 3',
  bundle_5: 'Bundle 5',
  bundle_10: 'Bundle 10',
  free: 'Free',
}

const UserCreditsFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <ReferenceInput
        label="Username"
        source="userId"
        reference="users"
        alwaysOn>
        <AutocompleteInput
          optionText="username"
          filterToQuery={searchText => ({
            username: searchText,
          })}
        />
      </ReferenceInput>

      <ReferenceInput
        label="Approved By"
        source="createdBy"
        reference="users"
        filter={{
          role: [ROLES.ADMIN, ROLES.MARKETING_MANAGER, ROLES.STATISTICAL_STAFF],
        }}
        alwaysOn>
        <SelectInput
          optionText="name"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>

      <SelectInput
        label="Filter"
        source="date"
        choices={[
          { id: 'all', name: 'All' },
          { id: 'this_month', name: 'This month' },
          { id: 'last_month', name: 'Last month' },
          { id: 'custom', name: 'Choose date' },
        ]}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const CustomUsernameField = () => {
  const record = useRecordContext()

  const { user, userId } = record
  return (
    <a style={{ color: '#0000EE' }} href={`#/user-details/${userId}/show`}>
      {user && user.username}
    </a>
  )
}

const ApproveByField = () => {
  const record = useRecordContext()

  if (!record?.approveRole) return null

  return <TextField source="updatedUser.name" {...record} />
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Username: record.user.username,
      Credits: record.credits,
      'Credit type': TYPE_CREDIT_NAME[record.type] || '',
      Status: record.status,
      Reason: record.reason,
      'Created At': moment(record.createdAt).format('MM/DD/YYYY'),
      'Created By': record.createdUser.name,
      'Approved At': moment(record.updatedAt).format('MM/DD/YYYY'),
      'Approved By': record.updatedUser.name,
    }
  })
  exportExcel({
    data,
    title: `Add_credits_${moment().format('MM_DD_YYYY')}`,
    type: 'xlsx',
  })
}

const UserCreditsList = props => {
  return (
    <List
      {...props}
      filters={<UserCreditsFilter />}
      bulkActionButtons={false}
      exporter={exporter}
      sort={{ field: 'updatedAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" sortByOrder="DESC" />
        <CustomUsernameField label="Username" />
        <NumberField source="credits" />
        <FunctionField
          label="Type credit"
          source="type"
          render={record => TYPE_CREDIT_NAME[record.type] || '-'}
        />
        <TextField source="reason" />
        <DateField source="createdAt" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ApproveByField label="Approved By" />
        <ApproveStatus label="Status" />
        <ApproveButtons service="user-credits" />
      </Datagrid>
    </List>
  )
}

export default UserCreditsList
