import type { Components } from '@mui/material/styles'

import type { Theme } from '../types'

export const MuiSelect = {
  defaultProps: {
    displayEmpty: true,
  },
  styleOverrides: { select: { height: 'auto', minHeight: 'auto' } },
} satisfies Components<Theme>['MuiSelect']
