import Grid from '@mui/material/Grid'

const GridContainer = props => {
  return (
    <Grid container spacing={2} sx={{ width: 'unset' }}>
      {props.children}
    </Grid>
  )
}

export default GridContainer
