import { Labeled } from 'react-admin'

const CustomAudioField = props => {
  const { record, label } = props
  if (!record || !record.audioUrl) {
    return <></>
  }
  return (
    <Labeled label={label}>
      <audio controls>
        <source src={record.audioUrl} />
      </audio>
    </Labeled>
  )
}

export default CustomAudioField
