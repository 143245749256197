import type { Components } from '@mui/material/styles'

import type { Theme } from '../types'

export const MuiInputLabel = {
  styleOverrides: {
    // root: { maxWidth: '100%', position: 'static', transform: 'none' },
    filled: {
      maxWidth: '100%',
      position: 'static',
      transform: 'none',
      fontSize: '0.75rem',
    },
    outlined: {
      maxWidth: '100%',
      position: 'static',
      transform: 'none',
      fontSize: '0.75rem',
    },
  },
} satisfies Components<Theme>['MuiInputLabel']
