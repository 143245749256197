import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  ReferenceField,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const ArticleFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const B2BOrganizationContentList = props => (
  <List
    {...props}
    filters={<ArticleFilter />}
    bulkActionButtons={false}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={true}>
      <TextField source="id" label="ID" />
      <ReferenceField
        source="organizationId"
        reference="b2b-organizations"
        label="Organization">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="bookId" reference="books" label="Book">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="courseId" reference="courses" label="Course">
        <TextField source="title" />
      </ReferenceField>
      <EditButton basePath="/b2b-organization-contents" />
    </Datagrid>
  </List>
)

export default B2BOrganizationContentList
