const TIER_PRICE = {
  tier5: 129000,
  tier6: 149000,
  tier7: 179000,
  tier8: 199000,
  tier9: 229000,
  tier10: 249000,
  tier11: 279000,
  tier12: 299000,
  tier13: 329000,
  tier14: 349000,
  tier15: 379000,
  tier16: 399000,
  tier17: 429000,
  tier18: 449000,
  tier19: 479000,
  tier20: 499000,
}

const PRICE_CHOICES = Object.values(TIER_PRICE).map(price => ({
  id: price,
  name: new Number(price).toLocaleString('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }),
}))

export { PRICE_CHOICES }
