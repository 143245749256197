import { Edit } from 'react-admin'

import MusicForm from './MusicForm'

const MusicEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <MusicForm permissions={props.permissions} />
    </Edit>
  )
}

export default MusicEdit
