import { Create } from 'react-admin'

import EnglishBookForm from './EnglishBookForm'

const UserCreate = props => {
  return (
    <Create redirect="list" {...props}>
      <EnglishBookForm isCreating />
    </Create>
  )
}

export default UserCreate
