import {
  TextInput,
  SimpleForm,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import { BOOK_STATUSES, COURSE_STATUSES } from '../../components/constants'
import CustomImageField from '../../components/CustomImageField'

const enableOptions = [
  { id: true, name: 'true' },
  { id: false, name: 'false' },
]

const LandingPageForm = ({ ...props }) => {
  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={10}>
          <TextInput
            label="Name"
            source="name"
            resettable
            fullWidth
            variant="standard"
            multiline
            validate={validateRequired('Name')}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            label="Enable"
            source="status"
            choices={enableOptions}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Enable')}
          />
        </Grid>
        <Grid item xs={12}>
          <ImageInput
            source="audiobookImage"
            label="Audiobook cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Audiobook cover image')}>
            <CustomImageField source="src" title="Audiobook cover image" />
          </ImageInput>
        </Grid>
        <ArrayInput
          label="Featured Audiobook"
          source="books"
          fullWidth
          validate={[
            validateRequired('Featured Audiobook'),
            // validateUnique('bookId', true),
          ]}>
          <SimpleFormIterator>
            <ReferenceInput
              label="Book"
              source={'id'}
              reference="books"
              filter={{
                isFree: false,
                membership_included: false,
                status: BOOK_STATUSES.PUBLISHED,
              }}
              rows={6}>
              <AutocompleteInput
                optionText="title"
                options={{
                  fullWidth: true,
                }}
                variant="standard"
                filterToQuery={searchText => ({
                  title: { $iLike: `%${searchText}%` },
                  isFree: false,
                  membership_included: false,
                  status: BOOK_STATUSES.PUBLISHED,
                })}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>

        <Grid item xs={12}>
          <ImageInput
            source="courseImage"
            label="Course cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Course cover image')}>
            <CustomImageField source="src" title="Course cover image" />
          </ImageInput>
        </Grid>
        <Grid item xs={10}>
          <TextInput
            label="Youtube Video Id"
            source="courseYoutubeVideoId"
            resettable
            fullWidth
            variant="standard"
            multiline
            validate={validateRequired('Youtube Video Id')}
          />
        </Grid>
        <ArrayInput
          label="Featured PodCourse"
          source="courses"
          fullWidth
          validate={[validateRequired('Featured PodCourse')]}>
          <SimpleFormIterator>
            <ReferenceInput
              label="Course"
              source={'id'}
              reference="courses"
              filter={{
                status: COURSE_STATUSES.PUBLISHED,
              }}
              rows={6}>
              <AutocompleteInput
                optionText="title"
                options={{
                  fullWidth: true,
                }}
                variant="standard"
                filterToQuery={searchText => ({
                  title: { $iLike: `%${searchText}%` },
                  status: COURSE_STATUSES.PUBLISHED,
                })}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </GridContainer>
    </SimpleForm>
  )
}

export default LandingPageForm
