import { TextInput, SimpleForm } from 'react-admin'
import Grid from '@mui/material/Grid'

import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const MeditationQuoteForm = ({ ...props }) => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="who"
            fullWidth
            variant="standard"
            validate={[validateRequired('Who')]}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="quote"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            validate={[validateRequired('Quote')]}
            multiline
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default MeditationQuoteForm
