import { Children, isValidElement, useState } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
import FormHelperText from '@mui/material/FormHelperText'
import {
  useInput,
  useTranslate,
  shallowEqual,
  RecordContextProvider,
} from 'ra-core'
import CircularProgress from '@mui/material/CircularProgress'
import { Button, Labeled } from 'react-admin'
import ReplayIcon from '@mui/icons-material/Replay'
import DoneIcon from '@mui/icons-material/Done'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { get } from 'lodash'
import { useTheme } from '@mui/material/styles'

import {
  removeFile,
  uploadAttachment,
  uploadChapter,
  uploadEpisode,
  uploadFileSample,
  uploadGuidedMeditationSession,
  uploadIntroVideo,
  uploadSubtitle,
  uploadTeaser,
} from '../uploadFileCapable'

import { FileInputPreview } from './FileInputPreview'
import { InputHelperText } from './InputHelperText'

export const sanitizeInputRestProps = ({
  afterSubmit,
  allowNull,
  alwaysOn,
  beforeSubmit,
  component,
  data,
  defaultValue,
  error,
  format,
  formatOnBlur,
  initializeForm,
  input,
  isEqual,
  isRequired,
  label,
  limitChoicesToValue,
  locale,
  meta,
  multiple,
  name,
  options,
  optionText,
  optionValue,
  parse,
  record,
  ref,
  refetch,
  render,
  resource,
  setFilter,
  setPagination,
  setSort,
  shouldUnregister,
  source,
  submitError,
  subscription,
  textAlign,
  translate,
  translateChoice,
  validate,
  validateFields,
  value,
  ...rest
}) => rest

const UPLOAD_FUNC = {
  chapter: uploadChapter,
  attachment: uploadAttachment,
  subtitle: uploadSubtitle,
  'meditation-session': uploadGuidedMeditationSession,
  episode: uploadEpisode,
  introVideo: uploadIntroVideo,
  fileSample: uploadFileSample,
  teaser: uploadTeaser,
}

const FileInput = props => {
  const {
    accept,
    children,
    className,
    format,
    helperText,
    inputProps: inputPropsOptions = {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    maxSize,
    minSize,
    multiple = false,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    options = {},
    onRemove: onRemoveProp,
    parse,
    placeholder,
    removeIcon,
    resource,
    source,
    validate,
    validateFileRemoval,
    disabled,
    readOnly,
    showProgress,
    ...rest
  } = props
  const { onDrop: onDropProp } = options
  const translate = useTranslate()
  const [uploadedStatus, setUploadedStatus] = useState(true)
  const [uploadProgress, setUploadProgress] = useState({})

  // turn a browser dropped file structure into expected structure
  const transformFile = file => {
    if (!(file instanceof File)) {
      return file
    }

    const preview = URL.createObjectURL(file)
    const transformedFile = {
      rawFile: file,
      src: preview,
      title: file.name,
    }

    return transformedFile
  }

  const transformFiles = files => {
    if (!files) {
      return multiple ? [] : null
    }

    if (Array.isArray(files)) {
      return files.map(transformFile)
    }

    return transformFile(files)
  }

  const {
    id,
    field: { onChange, onBlur, value },
    fieldState,
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    validate,
    disabled,
    readOnly,
    ...rest,
  })
  const { error, invalid } = fieldState
  const files = value ? (Array.isArray(value) ? value : [value]) : []

  const onDrop = async (newFiles, rejectedFiles, event) => {
    setUploadedStatus(false)
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles]

    if (multiple) {
      onChange(updatedFiles)
      onBlur()
    } else {
      onChange(updatedFiles[0])
      onBlur()
    }

    if (onDropProp) {
      onDropProp(newFiles, rejectedFiles, event)
    }

    if (props.upload) {
      for (const idx in updatedFiles) {
        // eslint-disable-next-line no-await-in-loop
        await uploadFile(updatedFiles[idx], idx)
      }
    }

    setUploadedStatus(true)
  }

  const uploadFile = async (file, index) => {
    try {
      const uploadFunc = UPLOAD_FUNC[props.type]
      file[source.split('.').slice(-1)[0]] = await uploadFunc(
        file,
        progressEvent => {
          setUploadProgress({
            ...uploadProgress,
            [index]: progressEvent,
          })
        },
      )
    } catch (e) {
      console.log('Upload failed error', index, file, e)
      setUploadProgress({
        ...uploadProgress,
        [index]: {
          loaded: -1,
        },
      })
    }
  }

  const onRemove = file => async () => {
    if (validateFileRemoval) {
      try {
        await validateFileRemoval(file)
      } catch (e) {
        return
      }
    }
    if (multiple) {
      const filteredFiles = files.filter(
        stateFile => !shallowEqual(stateFile, file),
      )
      onChange(filteredFiles)
      onBlur()
    } else {
      onChange(null)
      onBlur()
    }

    if (onRemoveProp) {
      onRemoveProp(file)
    }

    const s3Url = get(file, 'rawFile.S3Url.url', null)
    if (s3Url) {
      removeFile(s3Url)
    }
  }

  const childrenElement =
    children && isValidElement(Children.only(children))
      ? Children.only(children)
      : undefined

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize,
    minSize,
    multiple,
    disabled: disabled || readOnly,
    ...options,
    onDrop,
  })

  const renderHelperText = helperText !== false || invalid

  const theme = useTheme()

  const renderProgress = (file, index, progress) => {
    if (progress.loaded >= progress.total) {
      return (
        <>
          <span style={{ color: '#22bb33', marginRight: 5 }}>
            Upload success
          </span>
          <span style={{ color: '#22bb33' }}>
            <DoneIcon />
          </span>
        </>
      )
    }

    if (progress.loaded === -1) {
      return (
        <>
          <Typography color="secondary">Upload failed</Typography>
          <Box ml={2} />
          <Button
            color="secondary"
            variant="contained"
            label="Retry"
            onClick={() => uploadFile(file.rawFile, index)}>
            <ReplayIcon />
          </Button>
        </>
      )
    }

    return (
      <span
        style={{
          color: '#5bc0de',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
        }}>
        Uploading:
        <span style={{ marginLeft: 5, fontWeight: 700 }}>
          {bytesToMB(progress.loaded)} / {bytesToMB(progress.total)} MB
        </span>
      </span>
    )
  }

  const bytesToMB = bytes => {
    return (bytes / (1024 * 1024)).toFixed(2)
  }

  return (
    <StyledLabeled
      htmlFor={id}
      label={label}
      className={clsx('ra-input', `ra-input-${source}`, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      color={invalid ? 'error' : undefined}
      sx={{
        cursor: disabled || readOnly ? 'default' : 'pointer',
        ...rest.sx,
      }}
      {...sanitizeInputRestProps(rest)}>
      <>
        <div
          {...getRootProps({
            className: FileInputClasses.dropZone,
            'data-testid': 'dropzone',
            style: {
              color:
                disabled || readOnly
                  ? theme.palette.text.disabled
                  : inputPropsOptions?.color || theme.palette.text.primary,
              backgroundColor:
                disabled || readOnly
                  ? theme.palette.action.disabledBackground
                  : inputPropsOptions?.backgroundColor,
            },
          })}>
          <input
            id={id}
            name={id}
            {...getInputProps({
              ...inputPropsOptions,
            })}
          />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <p>{translate(labelMultiple)}</p>
          ) : (
            <p>{translate(labelSingle)}</p>
          )}
        </div>
        {renderHelperText ? (
          <FormHelperText error={invalid}>
            <InputHelperText error={error?.message} helperText={helperText} />
          </FormHelperText>
        ) : null}

        {children && (
          <div className="previews">
            {files.map((file, index) => (
              <FileInputPreview
                key={index}
                file={file}
                onRemove={onRemove(file)}
                className={FileInputClasses.removeButton}
                removeIcon={removeIcon}>
                <RecordContextProvider value={file}>
                  {childrenElement}
                </RecordContextProvider>
                {showProgress ? (
                  uploadProgress[index] ? (
                    <Box ml={2} display="flex" alignItems="center">
                      {renderProgress(file, index, uploadProgress[index])}
                    </Box>
                  ) : null
                ) : (
                  <CircularProgress
                    style={{
                      width: '1rem',
                      height: '1rem',
                      marginLeft: '0.5rem',
                      display: uploadedStatus ? 'none' : 'inline-block',
                    }}
                  />
                )}
              </FileInputPreview>
            ))}
          </div>
        )}
      </>
    </StyledLabeled>
  )
}

const PREFIX = 'RaFileInput'

export const FileInputClasses = {
  dropZone: `${PREFIX}-dropZone`,
  removeButton: `${PREFIX}-removeButton`,
}

export default FileInput

const StyledLabeled = styled(Labeled, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  width: '100%',
  [`& .${FileInputClasses.dropZone}`]: {
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  [`& .${FileInputClasses.removeButton}`]: {},
}))
