import { Fragment, useCallback } from 'react'
import {
  Create,
  email,
  FormDataConsumer,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import PeriodCommissionsInput from '../../components/PeriodCommissionsInput'

import { transformDataBeforeSubmit } from './UserEdit'

const UserCreate = props => {
  const renderPublisherExpand = useCallback(
    rest => (
      <Fragment>
        <Grid item xs={6}>
          <TextInput
            source="publisherCompany"
            fullWidth
            options={{
              fullWidth: true,
            }}
            validate={validateRequired('Publisher Company')}
            {...rest}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            source="defaultCommission"
            fullWidth
            options={{
              fullWidth: true,
            }}
            validate={[
              validateRequired('Commission'),
              minValue(0),
              maxValue(100),
            ]}
            {...rest}
          />
        </Grid>
        <Grid item xs={12}>
          <PeriodCommissionsInput
            fullWidth
            variant="standard"
            key={2}
            label="Period commissions"
            source="commissions"
          />
        </Grid>
      </Fragment>
    ),
    [],
  )

  const renderAffiliateExpand = useCallback(rest => {
    return (
      <Fragment>
        <Grid item xs={12}>
          <NumberInput
            source="defaultCommission"
            fullWidth
            options={{
              fullWidth: true,
            }}
            validate={[
              validateRequired('Commission'),
              minValue(0),
              maxValue(100),
            ]}
            {...rest}
          />
        </Grid>
      </Fragment>
    )
  }, [])

  return (
    <Create redirect="list" {...props} transform={transformDataBeforeSubmit}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <TextInput
              label="Name"
              source="name"
              fullWidth
              variant="standard"
              validate={validateRequired('Name')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Email Address"
              source="email"
              type="email"
              fullWidth
              variant="standard"
              validate={[validateRequired('Email'), email()]}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput
              label="Role"
              source="role"
              reference="roles"
              alwaysOn>
              <SelectInput
                optionText="name"
                fullWidth
                variant="standard"
                required
                validate={validateRequired('Role')}
              />
            </ReferenceInput>
          </Grid>

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (!formData || !formData.role) return null

              switch (formData.role) {
                case 'publisher':
                  return renderPublisherExpand(rest)
                case 'affiliate':
                  return renderAffiliateExpand(rest)
                default:
                  break
              }
            }}
          </FormDataConsumer>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default UserCreate
