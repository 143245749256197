import moment from 'moment'

const parseDate = (date, formatType) => {
  return moment(date).format(formatType)
}

const formatMonthWithZero = month => {
  const numericMonth = parseInt(month, 10)

  if (numericMonth >= 1 && numericMonth <= 12) {
    return numericMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 })
  } else {
    return 'Invalid Month'
  }
}

export { parseDate, formatMonthWithZero }
