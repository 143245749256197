import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
  FunctionField,
  Pagination,
  TextInput,
  SelectInput,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const CATEGORY_TYPE = {
  book: 'Book',
  english_book: 'English book',
  course: 'Course',
  music: 'Music',
  book_summary: 'Book summary',
  meditation: 'Meditation',
  sleep_story: 'Sleep story',
  kids_story: 'Kids story',
  ebook: 'Ebook',
  podcast: 'Podcast',
}

const CategoryPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 100, 1000]} {...props} />
)

const CategoryFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput
      source="type"
      choices={[
        { id: 'book', name: 'Book' },
        { id: 'english_book', name: 'English book' },
        { id: 'course', name: 'Course' },
        { id: 'music', name: 'Music' },
        { id: 'book_summary', name: 'Book summary' },
        { id: 'meditation', name: 'Meditation' },
        { id: 'sleep_story', name: 'Sleep story' },
        { id: 'kids_story', name: 'Kids story' },
        { id: 'ebook', name: 'Ebook' },
        { id: 'podcast', name: 'Podcast' },
      ]}
      alwaysOn></SelectInput>
    <SelectInput
      source="primary"
      choices={[
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
      ]}
      alwaysOn
      allowEmpty={true}></SelectInput>
  </FilterComponent>
)

const CategoryList = props => (
  <List
    {...props}
    perPage={1000}
    pagination={<CategoryPagination />}
    filters={<CategoryFilter />}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="name" />
      <FunctionField
        source="type"
        render={record => CATEGORY_TYPE[record.type]}
      />
      <TextField source="description" />
      <TextField source="slug" />
      <TextField source="primary" />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default CategoryList
