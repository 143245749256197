import get from 'lodash/get'
import { useRecordContext } from 'react-admin'

const CustomFileField = props => {
  const { source, title, target, canDownload } = props
  const record = useRecordContext()
  const sourceValue = get(record, source)
  const titleValue = get(record, title) || title

  if (record && !sourceValue) {
    return (
      <div style={{ display: 'inline-block' }}>
        {canDownload ? (
          <a
            href={`${process.env.REACT_APP_S3_URL}/${record}`}
            title={titleValue}
            target={target}>
            {titleValue}
          </a>
        ) : (
          <span>{titleValue}</span>
        )}
      </div>
    )
  }

  if (!sourceValue) {
    return <div />
  }

  return (
    <div>
      {canDownload ? (
        <a href={sourceValue} title={titleValue} target={target}>
          {titleValue}
        </a>
      ) : (
        <span>{titleValue}</span>
      )}
    </div>
  )
}

export default CustomFileField
