import {
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  Show,
  NumberField,
  FunctionField,
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  ReferenceField,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { CODE_TYPE_NAME } from '../../components/constants'
import CustomTextField from '../../components/CustomTextField'
import { PAYMENT_TYPE } from '../transaction-discounts/TransactionDiscountList'

const Title = ({ record }) => {
  return <span>User {record ? `${record.username}` : ''}</span>
}

const getProductNameByProductId = productId => {
  if (productId.includes('churn.sub.quarter.promo.50')) {
    return 'Quarter subscription for Churn'
  } else if (productId.includes('churn.sub.year.promo.30')) {
    return 'Annual subscription for Churn'
  } else if (productId.includes('sub.annually.discount.45')) {
    return 'Annual subscription discount 45%'
  } else if (productId.includes('mobile.sub.annually.pro')) {
    return 'Annually subscription Pro'
  } else if (productId.includes('mobile.sub.monthly.pro')) {
    return 'Monthly subscription Pro'
  } else if (productId.includes('mobile.subscription.monthly')) {
    return 'Monthly subscription'
  } else if (productId.includes('mobile.subscription.annually')) {
    return 'Annually subscription'
  } else if (productId.includes('mobile.subscription.annually.discount.30')) {
    return 'Annually subscription discount 30%'
  } else if (productId.includes('mobile.sub.year.pro.20')) {
    return 'Annual subscription Pro discount 20%'
  } else if (productId.includes('sub.year.free.3.credit')) {
    return 'Annual subscription with 3 free credits'
  } else {
    return `N/A ${productId}`
  }
}

// const getProductNamePrefix = productId => {
//   if (productId.includes('monthly')) {
//     return 'Monthly subscription'
//   }
//
//   return 'Annually subscription'
// }
//
// const getProductNameSuffix = productId => {
//   if (productId.includes('.pro')) {
//     return 'Pro'
//   }
//
//   return ''
// }

const getProductName = productId => {
  // const prefix = getProductNamePrefix(productId)
  // const suffix = getProductNameSuffix(productId)
  // return `${prefix}${suffix ? ` ${suffix}` : ''}`
  return getProductNameByProductId(productId)
}

const UserDetailsShow = props => {
  const { tabSyncWithLocation = true } = props

  return (
    <Show title={<Title />} {...props}>
      <TabbedShowLayout syncWithLocation={tabSyncWithLocation}>
        <Tab label="summary">
          <Grid container>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="id" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="username" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="email" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="phoneNumber" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="name" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <NumberField source="credits" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="lastSigninMethod" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <DateField source="createdAt" />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
        <Tab label="subscription" source="subscription">
          <ArrayField
            source="subscription"
            label=""
            sx={{
              '&> div': {
                width: '100%',
              },
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <FunctionField
                label="Subscription type"
                render={record => record && getProductName(record.productId)}
              />
              <DateField source="startDate" label="Start date" />
              <DateField source="endDate" label="End date" />
              <TextField source="paymentMethod" label="Payment method" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="purchase history">
          <ArrayField
            source="purchaseHistory"
            label=""
            sx={{
              '&> div': {
                width: '100%',
              },
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <TextField source="id" />
              <TextField source="type" label="Purchase type" />
              <TextField source="productName" />
              <TextField source="category" />
              <DateField source="createdAt" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="combo purchase">
          <ArrayField
            source="comboPurchase"
            label=""
            sx={{
              '&> div': {
                width: '100%',
              },
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <TextField source="id" />
              <TextField source="bundles[0].type" label="Combo type" />
              <TextField source="total" label="Number of credits used" />
              <TextField source="full" label="Number of books purchased" />
              <FunctionField
                label="Purchased books"
                render={record => {
                  if (!record.purchase_histories) {
                    return ''
                  }
                  return record.purchase_histories.map(ph => (
                    <>
                      <span>- {ph.book && ph.book.title}</span>
                      <br />
                    </>
                  ))
                }}
              />
              <DateField source="createdAt" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="redeemed codes">
          <ArrayField
            source="redeemedCodes"
            label=""
            sx={{
              '&> div': {
                width: '100%',
              },
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <TextField source="code" label="Code" />
              <DateField source="createdAt" />
              <FunctionField
                source="type"
                render={record => CODE_TYPE_NAME[record.type]}
              />

              <TextField label="Created By" source="createdUser.name" />

              <CustomTextField
                label="Book"
                source="bookId"
                reference="books"
                referenceField="title"
                {...props}
              />
              <CustomTextField
                label="Batch ID"
                source="batchCodeId"
                reference="batch-codes"
                referenceField="batchId"
                {...props}
              />
              <TextField source="source" label="Source" />
              <TextField source="paymentMethod" label="Payment method" />
              <FunctionField
                label="Recipient email"
                render={record => record.recipientEmail || record.invoiceEmail}
              />
              <FunctionField
                label="Phone number"
                render={record => {
                  if (record.payment_order && record.payment_order.phone) {
                    return record.payment_order.phone
                  }
                  return null
                }}
              />
              <FunctionField
                label="Copy email"
                render={record => {
                  if (record.recipientEmail) {
                    return record.invoiceEmail
                  }
                  return null
                }}
              />
              <DateField source="redeemedAt" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="promo transactions">
          <ArrayField
            source="paymentOrders"
            label=""
            sx={{
              '&> div': {
                width: '100%',
              },
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <TextField source="id" />
              <TextField source="code" />
              <TextField source="productName" />
              <TextField source="productType" />
              <FunctionField
                label="Payment type"
                render={record => PAYMENT_TYPE[record.paymentType]}
              />
              <NumberField source="amount" label="Price" />
              <DateField source="createdAt" label="Purchased at" />
              <TextField source="provider" label="Payment method" />
              <CustomTextField
                label="User"
                source="userId"
                reference="users"
                referenceField="id"
                {...props}
              />
              <TextField source="name" label="Name" />
              <TextField source="phone" label="Phone number" />
              <TextField source="email" label="Email" />

              <ReferenceField
                source="employeeId"
                reference="users"
                label="Employee">
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default UserDetailsShow
