import { Create } from 'react-admin'

import PromotionNotificationForm from './PromotionNotificationForm'

const PromotionNotificationCreate = props => {
  return (
    <Create redirect="list" undoable={false} {...props}>
      <PromotionNotificationForm />
    </Create>
  )
}

export default PromotionNotificationCreate
