import { Create } from 'react-admin'

import TopicForm from './TopicForm'

const TopicCreate = props => (
  <Create redirect="list" {...props}>
    <TopicForm />
  </Create>
)

export default TopicCreate
