import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { B2B_CONTENT_TYPES } from '../../components/constants'
import GridContainer from '../../components/GridContainer'

const B2BOrganizationContentForm = props => {
  return (
    <SimpleForm {...props} redirect="list" variant="standard" margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <ReferenceInput
            label="Organization"
            source="organizationId"
            reference="b2b-organizations"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
            })}>
            <AutocompleteInput optionText="name" fullWidth variant="standard" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Type"
            source="type"
            choices={B2B_CONTENT_TYPES}
            fullWidth
            variant="standard"
            name="type"
          />
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData || !formData.type) {
                return null
              }
              return (
                <ReferenceInput
                  label={
                    B2B_CONTENT_TYPES.find(({ id }) => id === formData.type)
                      .name
                  }
                  source={'entityId'}
                  reference={
                    B2B_CONTENT_TYPES.find(({ id }) => id === formData.type)
                      .entity
                  }
                  rows={6}
                  filterToQuery={searchText => ({
                    title: { $iLike: `%${searchText}%` },
                  })}
                  name="entity">
                  <AutocompleteInput
                    optionText="title"
                    options={{
                      fullWidth: true,
                    }}
                    variant="standard"
                    name="entityId"
                    sx={{ width: '100%' }}
                  />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default B2BOrganizationContentForm
