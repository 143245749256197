import { forwardRef } from 'react'
import { AppBar, UserMenu, Layout, useLogout, Menu } from 'react-admin'
import SettingsIcon from '@mui/icons-material/Settings'
import { MenuItem } from '@mui/material'
import ExitIcon from '@mui/icons-material/PowerSettingsNew'

const MyLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout()
  const handleClick = () => logout()
  return (
    <MenuItem onClick={handleClick} ref={ref} {...props}>
      <ExitIcon style={{ marginRight: 15 }} /> Logout
    </MenuItem>
  )
})

const MyUserMenu = props => (
  <UserMenu {...props}>
    <Menu.Item
      to="/changePassword"
      primaryText="Change Password"
      leftIcon={<SettingsIcon />}
    />
    <MyLogoutButton />
  </UserMenu>
)

const MyAppBar = props => (
  <AppBar color="primary" {...props} userMenu={<MyUserMenu />} />
)

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />

export default MyLayout
