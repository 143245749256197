import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  TextInput,
  usePermissions,
} from 'react-admin'

import FilterComponent from '../../components/Filter'
import { CustomS3AvatarField } from '../../components/CustomAvatarField'
import { ROLES } from '../../components/constants'
import PodcasterStatusField from '../../components/PodcasterStatusField'

const PodcasterFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
  </FilterComponent>
)

const PodcasterList = () => {
  const { permissions } = usePermissions()

  return (
    <List
      filters={<PodcasterFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}>
      <Datagrid bulkActionButtons={false} rowClick={false} size="medium">
        <TextField source="name" label="Podcaster" />
        <CustomS3AvatarField source="coverImageUrl" label="Cover Image" />
        <ReferenceField
          source="publisherId"
          reference="users"
          label="Publisher">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="datePublished" label="Publish Date" />
        <ReferenceField
          label="Published by"
          source="publishedBy"
          reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="updatedAt" />
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <PodcasterStatusField disabled={permissions !== ROLES.ADMIN} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default PodcasterList
