import { useEffect, useState, Fragment, useCallback } from 'react'
import moment from 'moment'
import debounce from 'lodash/debounce'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  useDataProvider,
  SelectInput,
  useNotify,
  DateInput,
  ExportButton,
  FunctionField,
  useListContext,
} from 'react-admin'
import { Grid } from '@mui/material'

import { parseDate } from '../../utils/parseDate'
import { exportExcel } from '../../utils/exportExcel'
import { formatCurrency } from '../../utils/formatCurrency'
import {
  calcCommissionAmount,
  getSubscriptionPlan,
} from '../../utils/subscription'
import { FILTER_DATE, SUBSCRIPTION } from '../constants'
import FilterComponent from '../../components/Filter'

const Actions = ({ resource, currentSort }) => {
  const { filterValues } = useListContext()

  return (
    <ExportButton
      disabled={false}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  )
}

const exporter = records => {
  const data = records.map(record => {
    const date = new Date(record.startDate)

    return {
      Id: record.id,
      Code: record.code,
      Plan: getSubscriptionPlan(record.productId),
      Price: record.amount,
      Date: date.toLocaleDateString(),
      Commission: record.commission,
      Amount: calcCommissionAmount(record.commission),
    }
  })

  exportExcel({
    data,
    title: 'Dashboard',
    type: 'xlsx',
  })
}

const CustomDatagrid = () => {
  const [revenue, setRevenue] = useState(0)
  const [balance, setBalance] = useState(0)
  const [usersRedeemed, setUsersRedeemed] = useState(0)
  const [newUsersRedeemed, setNewUsersRedeemed] = useState(0)
  const [isShowing = {}, setIsShowing] = useState({
    showFD: false,
    showTD: false,
  })
  const { filterValues } = useListContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleSetRevenue = useCallback(() => {
    dataProvider
      .getOne('affiliate-dashboard-revenue', {
        id: null,
        date: filterValues && filterValues.date,
        fromDate: filterValues && filterValues.fromDate,
        toDate: filterValues && filterValues.toDate,
      })
      .then(({ data }) => {
        setRevenue(data.result)
        setBalance(data.balance)
      })
      .catch(console.log)
    dataProvider
      .getOne('affiliate-dashboard-install', {
        id: null,
        date: filterValues && filterValues.date,
        fromDate: filterValues && filterValues.fromDate,
        toDate: filterValues && filterValues.toDate,
      })
      .then(({ data }) => {
        setUsersRedeemed(data.usersRedeemed)
        setNewUsersRedeemed(data.newUsersRedeemed)
      })
      .catch(console.log)
  }, [dataProvider, filterValues])

  useEffect(() => {
    if (isShowing.showFD)
      setTimeout(() => setIsShowing({ ...isShowing, showFD: false }), 5000)
    if (isShowing.showTD)
      setTimeout(() => setIsShowing({ ...isShowing, showTD: false }), 5000)
  }, [isShowing])

  useEffect(() => {
    const debounced = debounce(() => {
      if ('custom' !== filterValues.date) {
        handleSetRevenue()
      } else {
        const fromDate = parseDate(filterValues.fromDate, 'YYYY-MM-DD'),
          toDate = parseDate(filterValues.toDate, 'YYYY-MM-DD'),
          presentDate = parseDate(new Date(), 'YYYY-MM-DD')
        if (fromDate > toDate && !isShowing.showFD) {
          notify(`End date isn't less than start date`, { type: 'error' })
          setIsShowing({ ...isShowing, showFD: true })
        } else if (toDate > presentDate && !isShowing.showTD) {
          notify(`End date isn't larger than present date`, { type: 'error' })
          setIsShowing({ ...isShowing, showTD: true })
        } else {
          handleSetRevenue()
        }
      }
    }, 1000)
    debounced.call()
  }, [
    filterValues.date,
    filterValues.fromDate,
    filterValues.toDate,
    handleSetRevenue,
    isShowing,
    notify,
  ])

  const PlanTextField = useCallback(({ record = {} }) => {
    const { productId } = record
    const plan = getSubscriptionPlan(productId)
    return <span>{plan}</span>
  }, [])

  const CommissionAmountField = useCallback(({ record = {} }) => {
    const { commission } = record
    let amountCommission = (commission / 100) * SUBSCRIPTION.YEARLY_PRICE * 0.9
    return <span>{formatCurrency.format(amountCommission.toFixed(0))}</span>
  }, [])

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <h3 style={{ marginLeft: 12 }}>
            Total commission: {formatCurrency.format(revenue)}
          </h3>
          <h3 style={{ marginLeft: 12 }}>
            Received: {formatCurrency.format(balance)}
          </h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h3 style={{ marginLeft: 12 }}>
            Users redeemed a free book: {usersRedeemed}
          </h3>
          <h3 style={{ marginLeft: 12 }}>
            New users redeemed a free book: {newUsersRedeemed}
          </h3>
        </Grid>
      </Grid>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="code" />
        <PlanTextField source="Plan" />
        <FunctionField
          label="Discount"
          render={record => `${record.discount}%`}
        />
        <NumberField source="amount" label="Price (after discount)" />
        <FunctionField
          label="Commission"
          render={record => `${record.commission}%`}
        />
        <CommissionAmountField source="amount" label="Commission amount" />
        <TextField source="originalTransactionId" label="Transaction Id" />
        <DateField source="createdAt" label="Purchased at" />
        <TextField source="paymentMethod" label="Payment method" />
      </Datagrid>
    </Fragment>
  )
}

const CustomFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <SelectInput
        label="Filter"
        source="date"
        choices={FILTER_DATE}
        alwaysOn></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const DashboardAffiliate = () => {
  return (
    <List
      title="Affiliate dashboard"
      resource="affiliate-purchase-histories"
      perPage={1000}
      bulkActionButtons={false}
      filters={<CustomFilter />}
      actions={<Actions />}
      filterDefaultValues={{
        date: 'all',
        fromDate: moment()
          .subtract(7, 'days')
          .startOf('day')
          .format('MM-DD-YYYY'),
        toDate: moment().endOf('day').format('MM-DD-YYYY'),
      }}>
      <CustomDatagrid />
    </List>
  )
}

export default DashboardAffiliate
