import { Edit } from 'react-admin'

import ConfigurationSettingForm from './ConfigurationSettingForm'

const ConfigurationSettingEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <ConfigurationSettingForm />
    </Edit>
  )
}

export default ConfigurationSettingEdit
