import { SimpleForm, ReferenceInput, AutocompleteInput } from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const FullAccessAccountsForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <ReferenceInput label="Username" source="userId" reference="users">
            <AutocompleteInput
              optionText="username"
              options={{
                fullWidth: true,
                disabled: props.editing ? true : false,
              }}
              variant="standard"
              validate={validateRequired('user')}
              filterToQuery={searchText => ({
                username: { $iLike: `%${searchText}%` },
              })}
            />
          </ReferenceInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default FullAccessAccountsForm
