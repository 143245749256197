import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  FormTab,
  ImageInput,
  List,
  maxLength,
  ReferenceField,
  TabbedForm,
  TextField,
  TextInput,
  usePermissions,
  useRecordContext,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomImageField from '../../components/CustomImageField'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'
import { CustomS3AvatarField } from '../../components/CustomAvatarField'
import { ROLES } from '../../components/constants'
import ChannelStatusField from '../../components/ChannelStatusField'

const PREFIX = 'PodcasterForm'
const classes = {
  actionButton: `${PREFIX}-actionButton`,
}

// const Root = styled('div')(({ theme }) => ({
//   [`&.${classes.actionButton}`]: {
//     position: 'absolute',
//     right: '50px',
//     marginTop: '-50px',
//   },
// }))

const PodcasterForm = ({ ...props }) => {
  const { isCreating, ...rest } = props
  const record = useRecordContext()
  const { permissions } = usePermissions()

  const podcasterId = record?.id
  const validateMaxLength = maxLength(
    255,
    'Do not enter more than 255 characters',
  )

  return (
    <TabbedForm {...rest} redirect="list" toolbar={<BookToolbar />}>
      <FormTab
        label="Podcaster Info"
        redirect="list"
        variant="standard"
        margin="normal">
        <GridContainer>
          <Grid item xs={4} className={classes.actionButton}>
            {record && permissions === ROLES.ADMIN && (
              <DeleteButton
                label="Delete"
                record={record}
                confirmTitle={`Delete channel: ${record.name}`}
                mutationMode="pessimistic"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <ImageInput
              source="coverImageUrl"
              label="Cover image"
              accept="image/*"
              multiple={false}
              validate={validateRequired('Cover Image')}>
              <CustomImageField source="src" title="Cover image" />
            </ImageInput>
          </Grid>

          <Grid item xs={10}>
            <TextInput
              source="name"
              fullWidth
              variant="standard"
              validate={[validateRequired('Podcaster Name'), validateMaxLength]}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="description"
              resettable
              rows={10}
              fullWidth
              variant="standard"
              multiline
            />
          </Grid>
        </GridContainer>
      </FormTab>
      {!isCreating && (
        <FormTab label="Channels" variant="filled" margin="normal">
          <List
            fullWidth
            resource="channels"
            basePath="/channels"
            sort={{ field: 'updatedAt', order: 'DESC' }}
            bulkActionButtons={false}
            exporter={false}
            filterDefaultValues={{
              podcasterId: podcasterId,
            }}>
            <Datagrid bulkActionButtons={false} rowClick={false}>
              <TextField source="name" label="Channel name" />
              <CustomS3AvatarField source="coverImageUrl" label="Cover Image" />
              <ReferenceField
                source="podcasterId"
                reference="podcasters"
                label="Podcaster">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="datePublished" label="Date Publish" />
              <ReferenceField
                label="Published by"
                source="publishedBy"
                reference="users">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="updatedAt" label="Last update" />
              <ReferenceField
                label="Updated by"
                source="updatedBy"
                reference="users">
                <TextField source="name" />
              </ReferenceField>
              <ChannelStatusField disabled={permissions !== ROLES.ADMIN} />
              <EditButton basePath="/channels" />
            </Datagrid>
          </List>
        </FormTab>
      )}
    </TabbedForm>
  )
}

export default PodcasterForm
