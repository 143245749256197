import {
  List,
  Datagrid,
  EditButton,
  DeleteButton,
  ReferenceField,
  TextField,
  DateField,
  useRecordContext,
} from 'react-admin'

import BannerStatusField from '../../components/BannerStatusField'
import { CustomS3ImageField } from '../../components/CustomImageField'

const BookImageField = ({ ...rest }) => {
  const record = useRecordContext()
  if (!record.bookId && !record.bookSummaryId && !record.entityId)
    return <span>-</span>

  switch (record.type) {
    case 'book': {
      return (
        <ReferenceField
          source="bookId"
          reference="books"
          record={record}
          {...rest}>
          <CustomS3ImageField source="coverImageUrl" />
        </ReferenceField>
      )
    }
    case 'book_summary': {
      return (
        <ReferenceField
          source="bookSummaryId"
          reference="book-summaries"
          record={record}
          {...rest}>
          <CustomS3ImageField source="coverImageUrl" />
        </ReferenceField>
      )
    }
    case 'podcast_channel': {
      return (
        <ReferenceField
          source="entityId"
          reference="channels"
          record={record}
          {...rest}>
          <CustomS3ImageField source="coverImageUrl" />
        </ReferenceField>
      )
    }
    case 'podcast_episode': {
      return (
        <ReferenceField
          source="entityId"
          reference="episodes"
          record={record}
          {...rest}>
          <CustomS3ImageField source="coverImageUrl" />
        </ReferenceField>
      )
    }
    case 'author': {
      return (
        <ReferenceField
          source="entityId"
          reference="authors"
          record={record}
          {...rest}>
          <CustomS3ImageField source="avatar" />
        </ReferenceField>
      )
    }
  }
}

const BookTitleField = ({ ...rest }) => {
  const record = useRecordContext()

  if (!record.bookId && !record.bookSummaryId && !record.entityId)
    return <span>-</span>

  switch (record.type) {
    case 'book': {
      return (
        <ReferenceField
          source="bookId"
          reference="books"
          record={record}
          {...rest}>
          <TextField source="title" />
        </ReferenceField>
      )
    }
    case 'book_summary': {
      return (
        <ReferenceField
          source="bookSummaryId"
          reference="book-summaries"
          record={record}
          {...rest}>
          <TextField source="title" />
        </ReferenceField>
      )
    }
    case 'podcast_channel': {
      return (
        <ReferenceField
          source="entityId"
          reference="channels"
          record={record}
          {...rest}>
          <TextField source="name" />
        </ReferenceField>
      )
    }
    case 'podcast_episode': {
      return (
        <ReferenceField
          source="entityId"
          reference="episodes"
          record={record}
          {...rest}>
          <TextField source="title" />
        </ReferenceField>
      )
    }
    case 'author': {
      return (
        <ReferenceField
          source="entityId"
          reference="authors"
          record={record}
          {...rest}>
          <TextField source="name" />
        </ReferenceField>
      )
    }
  }
}

const BannerList = props => (
  <List {...props}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <BookImageField label="Book cover" />
      <BookTitleField label="Title" />
      <TextField source="type" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <BannerStatusField label="Status" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default BannerList
