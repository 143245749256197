import {
  Button,
  useRefresh,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin'

const EnglishBookActionButton = props => {
  const { action, ...rest } = props
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const getDataUpdate = () => {
    switch (action) {
      case 'approve':
        return {
          status: 'Published',
        }
      case 'reject':
        return {
          status: 'Rejected',
        }

      default:
        return {}
    }
  }

  const handleClick = async () => {
    const data = getDataUpdate()

    dataProvider
      .update('english-books', { id: record.id, data })
      .then(() => {
        notify('EnglishBook updated')
        refresh()
      })
      .catch(error => {
        notify(`Error: english-books not updated ${error.message}`, {
          type: 'error',
        })
      })
  }

  return (
    <Button
      sx={{ margin: '5px 0', minWidth: 90 }}
      variant="contained"
      onClick={handleClick}
      {...rest}
    />
  )
}

export default EnglishBookActionButton
