import { useState } from 'react'
import { useRecordContext, useRedirect, useNotify } from 'react-admin'
import { Switch, FormControlLabel } from '@mui/material'
import get from 'lodash/get'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'

import { BOOK_STATUSES } from './constants'

const BookStatusField = props => {
  const { disabled } = props
  const record = useRecordContext()
  const updateDataProvider = useUpdateDataProvider()
  const [status, setStatus] = useState(record.status)
  const notify = useNotify()
  const redirectTo = useRedirect()

  const handleChange = async () => {
    const data = {
      status:
        status === BOOK_STATUSES.PUBLISHED
          ? BOOK_STATUSES.UNPUBLISHED
          : BOOK_STATUSES.PUBLISHED,
    }

    setStatus(data.status)

    const response = await updateDataProvider('books', { id: record.id, data })

    if (get(response, 'data.status', null)) {
      setStatus(response.data.status)
      notify('Book updated', { type: 'success' })
      redirectTo('/books')
    }
  }

  return [BOOK_STATUSES.PUBLISHED, BOOK_STATUSES.UNPUBLISHED].includes(
    record.status,
  ) ? (
    <FormControlLabel
      control={
        <Switch
          checked={status === BOOK_STATUSES.PUBLISHED}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={status}
    />
  ) : (
    status
  )
}

export default BookStatusField
