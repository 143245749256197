import { Create } from 'react-admin'

import DiscountsForm from './DiscountsForm'

const DiscountsCreate = props => (
  <Create redirect="list" {...props}>
    <DiscountsForm />
  </Create>
)

export default DiscountsCreate
