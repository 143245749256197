import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  email,
  minValue,
  ImageInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { validateRequired } from '../../utils/validate'
import CustomImageField from '../../components/CustomImageField'
import GridContainer from '../../components/GridContainer'

const statuses = [
  { id: true, name: 'True' },
  { id: false, name: 'False' },
]

const AffiliateEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <TextInput disabled fullWidth source="webLink"></TextInput>
          </Grid>
          <Grid item xs={12}>
            <TextInput disabled fullWidth source="deepLink"></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label="Name"
              source="name"
              fullWidth
              validate={validateRequired('Name')}
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              label="Status"
              source="status"
              choices={statuses}
              fullWidth
              required
              variant="standard"
              defaultValue={true}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label="Email Address"
              source="email"
              type="email"
              fullWidth
              variant="standard"
              validate={email()}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label="Slug"
              source="slug"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <DateInput
              label="Start date"
              source="startAt"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <DateInput
              label="Expire date"
              source="expireAt"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={12}>
            <ImageInput
              source="picture"
              label="Picture"
              accept="image/*"
              multiple={false}>
              <CustomImageField source="src" title="Picture" />
            </ImageInput>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label="Quote"
              source="quote"
              resettable
              rows={5}
              fullWidth
              validate={[validateRequired('Quote')]}
              multiline
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <NumberInput
              label="Quantity Limit"
              source="quantityLimit"
              fullWidth
              options={{
                fullWidth: true,
              }}
              validate={minValue(0)}
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              label="Is commission included?"
              source="commissionIncluded"
              choices={[
                { id: true, name: 'True' },
                { id: false, name: 'False' },
              ]}
              fullWidth
              required
              variant="standard"
              defaultValue={false}
            />
          </Grid>

          <Grid item xs={12}>
            <ReferenceInput
              variant="standard"
              label="Book"
              source="bookId"
              reference="books">
              <AutocompleteInput
                optionText="title"
                options={{
                  fullWidth: true,
                }}
                filterToQuery={searchText => ({
                  title: { $iLike: `%${searchText}%` },
                })}
              />
            </ReferenceInput>
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Edit>
  )
}

export default AffiliateEdit
