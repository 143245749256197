import { Create } from 'react-admin'

import B2BOrganizationForm from './B2BTrialUserForm'

const B2BTrialUserCreate = props => (
  <Create {...props}>
    <B2BOrganizationForm />
  </Create>
)

export default B2BTrialUserCreate
