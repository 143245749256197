import { Create } from 'react-admin'

import FreeAccountsForm from './FreeAccountsForm'

const FreeAccountsCreate = props => (
  <Create redirect="list" {...props}>
    <FreeAccountsForm />
  </Create>
)

export default FreeAccountsCreate
