import { Fragment, useEffect, useState } from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateInput,
  SelectInput,
  useListContext,
  useDataProvider,
} from 'react-admin'
import moment from 'moment'

import FilterComponent from '../../components/Filter'

const CustomFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <SelectInput
        label="Filter"
        source="date"
        choices={[
          { id: 'all', name: 'All' },
          { id: 'this_month', name: 'This month' },
          { id: 'last_month', name: 'Last month' },
          { id: 'custom', name: 'Choose date' },
        ]}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const CustomDataGrid = ({ ...props }) => {
  const { filterValues } = useListContext()
  const dataProvider = useDataProvider()
  const [data, setData] = useState({
    newAccounts: 0,
    referralReceiveBooks: 0,
  })

  useEffect(() => {
    dataProvider
      .getOne('referral-dashboard-analytic', {
        id: null,
        date: filterValues && filterValues.date,
        fromDate: filterValues && filterValues.fromDate,
        toDate: filterValues && filterValues.toDate,
      })
      .then(({ data }) => setData(data))
      .catch(console.log)
  }, [dataProvider, filterValues])

  return (
    <Fragment>
      <h4 style={{ marginLeft: 12 }}>New account: {data.newAccounts}</h4>
      <h4 style={{ marginLeft: 12 }}>
        Referral account received book: {data.referralReceiveBooks}
      </h4>
      <Datagrid bulkActionButtons={false} rowClick={false} {...props}>
        <TextField source="id" />
        <TextField source="title" />
        <NumberField source="count" label="Total books given" />
        <TextField />
      </Datagrid>
    </Fragment>
  )
}

const ReferralList = props => {
  return (
    <List
      {...props}
      filters={<CustomFilter />}
      bulkActionButtons={false}
      exporter={false}
      filterDefaultValues={{
        date: 'all',
        fromDate: moment()
          .subtract(7, 'days')
          .startOf('day')
          .format('MM-DD-YYYY'),
        toDate: moment().endOf('day').format('MM-DD-YYYY'),
      }}
      sort={{ field: 'id', order: 'DESC' }}>
      <CustomDataGrid />
    </List>
  )
}

export default ReferralList
