import { Show } from 'react-admin'

import PromotionNotificationForm from './PromotionNotificationForm'

const PromotionNotificationEdit = props => {
  return (
    <Show undoable={false} {...props}>
      <PromotionNotificationForm isShow={true} />
    </Show>
  )
}

export default PromotionNotificationEdit
