import { inputBaseClasses } from '@mui/material/InputBase'

import type { Components } from '@mui/material/styles'

import type { Theme } from '../types'

export const MuiAutocomplete = {
  styleOverrides: {
    root: {
      '& .MuiInputBase-root': {
        borderRadius: '4px',
        padding: '0 8px', // Adjust padding for inner spacing
        disableUnderline: true,
      },
    },
    inputRoot: {
      padding: 0,
      height: 'auto',
      [`&.${inputBaseClasses.formControl}`]: {
        '&::placeholder': {
          opacity: '1 !important',
        },
      },
      '&:-webkit-autofill': {
        marginInline: 'calc(var(--Input-paddingInline) * -1)',
        paddingInline: 'var(--Input-paddingInline)',
      },
    },
    popper: {
      // Customize dropdown styles if needed
    },
    listbox: {
      // Style the dropdown list to align with your design
    },
  },
} satisfies Components<Theme>['MuiAutocomplete']
