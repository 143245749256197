export const COMMISSION_EFFECTIVE_SCOPE = {
  PUBLISHER: 'publisher',
  BOOK: 'book',
}

export const COMMISSION_EFFECTIVE_SCOPE_LABEL = {
  [COMMISSION_EFFECTIVE_SCOPE.PUBLISHER]: 'Publisher',
  [COMMISSION_EFFECTIVE_SCOPE.BOOK]: 'Book',
}

export const COMMISSION_DURATION_TYPE = {
  PERMANENT: 'permanent',
  PERIOD: 'period',
}

export const COMMISSION_DURATION_TYPE_LABEL = {
  [COMMISSION_DURATION_TYPE.PERMANENT]: 'Permanent',
  [COMMISSION_DURATION_TYPE.PERIOD]: 'Period',
}

export const COMMISSION_STATUSES = {
  WAITING_FOR_APPROVAL: 'waiting_for_approval',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}
