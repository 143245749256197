import { Edit } from 'react-admin'

import SleepStoryForm from './SleepStoryForm'

const SleepStoryEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <SleepStoryForm permissions={props.permissions} />
    </Edit>
  )
}

export default SleepStoryEdit
