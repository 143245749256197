import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
} from 'react-admin'

const CategoryList = props => (
  <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <ReferenceField label="Book" source="bookId" reference="books">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default CategoryList
