import { Edit, SimpleForm, TextInput } from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'

const CrmCODPaymentEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid container item xs={12}>
            <TextInput
              disabled
              fullWidth
              source="sessionId"
              variant="standard"></TextInput>
          </Grid>
          <Grid container item xs={12}>
            <TextInput
              label="Product name"
              disabled
              fullWidth
              source="payment_order.productName"
              variant="standard"></TextInput>
          </Grid>
          <Grid container item xs={6}>
            <TextInput
              label="Product amount (+20.000đ cod fee)"
              disabled
              fullWidth
              source="codAmount"
              variant="standard"></TextInput>
          </Grid>
          <Grid container item xs={6}>
            <TextInput
              label="Product discount code"
              disabled
              fullWidth
              source="payment_order.code"
              variant="standard"></TextInput>
          </Grid>
          <Grid item xs={12}>
            <TextInput source="recipientName" fullWidth variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="recipientPhone" fullWidth variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="recipientAddress" fullWidth variant="standard" />
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Edit>
  )
}

export default CrmCODPaymentEdit
