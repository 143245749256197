import {
  TextInput,
  ImageInput,
  NumberInput,
  SimpleForm,
  SelectInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  minValue,
  maxValue,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const enableOptions = [
  { id: true, name: 'true' },
  { id: false, name: 'false' },
]
const notificationChannelOptions = [
  { id: 'email', name: 'Email' },
  { id: 'pushNotification', name: 'Push Notification' },
]
const notificationAudienceOptions = [
  { id: 'subscriber', name: 'Subscriber' },
  { id: 'unsubscriber', name: 'Unsubscriber' },
]
const validateDimension = [minValue(0)]

const PromotionForm = ({ ...props }) => {
  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={10}>
          <DateInput
            source="dateStart"
            fullWidth
            variant="standard"
            validate={validateRequired('dateStart')}></DateInput>
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            label="Enable"
            source="enable"
            choices={enableOptions}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Enable')}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[validateRequired('displayPrice')]}
            source="displayPrice"
            resettable
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              minValue(0),
              maxValue(100),
              validateRequired('salePercent'),
            ]}
            source="salePercent"
            resettable
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Product Id"
            source="productId"
            resettable
            fullWidth
            variant="standard"
            required
            validate={validateRequired('productId')}
          />
        </Grid>
        <Grid item xs={12}>
          <NumberInput
            validate={[
              minValue(0),
              maxValue(100),
              validateRequired('amountDays'),
            ]}
            source="amountDays"
            resettable
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Banner Title"
            source="bannerInfo.title"
            resettable
            fullWidth
            variant="standard"
            multiline
            validate={validateRequired('Banner Title')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Banner text color"
            source="bannerInfo.textColor"
            resettable
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Banner Subtitle"
            source="bannerInfo.subTitle"
            resettable
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <ImageInput
            accept="image/*"
            source="bannerInfo.phone.url"
            label="Banner image (phone)"
            multiple={false}
            validate={validateRequired('Banner image')}>
            <CustomImageField
              originalSrc={true}
              source="src"
              title="Banner image"
            />
          </ImageInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Banner width (phone)'),
            ]}
            label={'Banner width (phone)'}
            source="bannerInfo.phone.width"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Banner height (phone)'),
            ]}
            label={'Banner height (phone)'}
            source="bannerInfo.phone.height"
            resettable
            fullWidth
            variant="standard"
            // validate={validateRequired('Banner height (phone)')}
          />
        </Grid>

        {/* <------ tablet --------> */}

        <Grid item xs={12}>
          <ImageInput
            accept="image/*"
            source="bannerInfo.tablet.url"
            label="Banner image (tablet)"
            multiple={false}
            validate={validateRequired('Banner image')}>
            <CustomImageField
              originalSrc={true}
              source="src"
              title="Banner image"
            />
          </ImageInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Banner width (tablet)'),
            ]}
            label={'Banner width (tablet)'}
            source="bannerInfo.tablet.width"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Banner height (tablet)'),
            ]}
            label={'Banner height (tablet)'}
            source="bannerInfo.tablet.height"
            resettable
            fullWidth
            variant="standard"
          />
        </Grid>

        {/* <--------- Promotion Screen ------------> */}

        <Grid item xs={6}>
          <TextInput
            label="Sale screen title"
            source="saleScreenInfo.title"
            resettable
            fullWidth
            variant="standard"
            multiline
            validate={validateRequired('Sale screen title')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Sale screen text color"
            source="saleScreenInfo.textColor"
            resettable
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Sale screen subtitle"
            source="saleScreenInfo.subTitle"
            resettable
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            accept="image/*"
            source="saleScreenInfo.iconTheme.phone.url"
            label="Theme Image (phone)"
            multiple={false}
            validate={validateRequired('Theme Image')}>
            <CustomImageField
              originalSrc={true}
              source="src"
              title="Theme Image"
            />
          </ImageInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Image Theme width (phone)'),
            ]}
            label={'Image Theme width (phone)'}
            source="saleScreenInfo.iconTheme.phone.width"
            resettable
            fullWidth
            variant="standard"
            // validate={validateRequired('Image Theme width (phone)')}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Image Theme height (phone)'),
            ]}
            label={'Image Theme height (phone)'}
            source="saleScreenInfo.iconTheme.phone.height"
            resettable
            fullWidth
            variant="standard"
            // validate={validateRequired('Image Theme height (phone)')}
          />
        </Grid>

        {/* <------ tablet --------> */}

        <Grid item xs={12}>
          <ImageInput
            accept="image/*"
            source="saleScreenInfo.iconTheme.tablet.url"
            label="Theme Image (tablet)"
            multiple={false}
            validate={validateRequired('Theme Image')}>
            <CustomImageField
              originalSrc={true}
              source="src"
              title="Theme Image"
            />
          </ImageInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Image Theme width (tablet)'),
            ]}
            label={'Image Theme width (tablet)'}
            source="saleScreenInfo.iconTheme.tablet.width"
            resettable
            fullWidth
            variant="standard"
            // validate={validateRequired('Image Theme width (tablet)')}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            validate={[
              ...validateDimension,
              validateRequired('Image Theme height (tablet)'),
            ]}
            label={'Image Theme height (tablet)'}
            source="saleScreenInfo.iconTheme.tablet.height"
            resettable
            fullWidth
            variant="standard"
            // validate={validateRequired('Image Theme height (tablet)')}
          />
        </Grid>
        <Grid item xs={12}>
          <ArrayInput
            source="saleScreenInfo.listBenefits"
            label="List benefits"
            fullWidth
            variant="standard"
            validate={validateRequired('List benefits')}>
            <SimpleFormIterator>
              <TextInput
                label="benefit"
                resettable
                fullWidth
                variant="standard"
                validate={validateRequired('benefit')}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12}>
          <ArrayInput
            source="saleScreenInfo.backgroundColors"
            label="Color of Page"
            fullWidth
            variant="standard"
            validate={validateRequired('Color of Page')}>
            <SimpleFormIterator>
              <TextInput
                label="color"
                fullWidth
                variant="standard"
                validate={validateRequired('color')}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        {/* <------ Promotion Notifiation Config ------------> */}
        <Grid item xs={12}>
          <ArrayInput
            source="notificationConfig.channel"
            label="Channels"
            fullWidth
            variant="standard"
            validate={validateRequired('Color of Page')}>
            <SimpleFormIterator>
              <SelectInput
                label="Name"
                source="name"
                choices={notificationChannelOptions}
                fullWidth
                variant="standard"
                required
                validate={validateRequired('Name')}
              />
              <SelectInput
                label="Audience"
                source="audience"
                choices={notificationAudienceOptions}
                fullWidth
                variant="standard"
                required
                validate={validateRequired('Audience')}
              />
              <TextInput
                source="templateId"
                label="Template ID"
                variant="standard"
              />
              <TextInput
                source="notificationType"
                label="Notification type"
                variant="standard"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default PromotionForm
