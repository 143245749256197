import { memo, useState } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import UserDetailSummary from '../user-details/UserDetailsShow'

const CsDeleteAccountPreview = ({ userId }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {userId && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}>
          <Typography variant="caption">SHOW USER PREVIEW</Typography>
        </Button>
      )}
      <SwipeableDrawer
        anchor="right"
        open={open}
        onBackdropClick={() => setOpen(false)}>
        <Container sx={{ padding: 24, minWidth: 1024 }}>
          <Box component="div">
            <Typography variant="h5">Delete user: </Typography>
            {userId && (
              <UserDetailSummary
                id={userId}
                basePath="/user-details-1"
                resource="user-details"
                hasEdit={false}
                tabSyncWithLocation={false}
              />
            )}
          </Box>
        </Container>
      </SwipeableDrawer>
    </>
  )
}

export default memo(CsDeleteAccountPreview)
