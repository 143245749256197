import { useRecordContext } from 'react-admin'

const UserDetailField = ({ idField = 'userId' }) => {
  const record = useRecordContext()
  const userId = record[idField]

  return (
    <a style={{ color: '#0000EE' }} href={`#/user-details/${userId}/show`}>
      {userId}
    </a>
  )
}

export default UserDetailField
