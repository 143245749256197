import Grid from '@mui/material/Grid'
import {
  SimpleForm,
  ImageInput,
  AutocompleteInput,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
} from 'react-admin'

import { validateRequired } from '../../utils/validate'
import CustomImageField from '../../components/CustomImageField'
import GridContainer from '../../components/GridContainer'

const SLIDESHOW_TYPES = [
  { id: 'book', name: 'Book' },
  { id: 'book_list', name: 'Book List' },
  { id: 'invite', name: 'Gift' },
  { id: 'audio_list', name: 'Audio list' },
  { id: 'audio_series', name: 'Audio Series' },
  { id: 'news_feed', name: 'News feed' },
]

const AUDIO_TYPES = [
  { id: 'music', name: 'Music' },
  { id: 'book_summary', name: 'Book summary' },
  { id: 'meditation', name: 'Meditation' },
  { id: 'sleep_story', name: 'Sleep story' },
  { id: 'kids_story', name: 'Kids story' },
]

const SlideshowForm = props => {
  const { renderCover, ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <SelectInput
            source="type"
            choices={SLIDESHOW_TYPES}
            fullWidth
            required
            variant="standard"
            validate={validateRequired('Type')}
          />
        </Grid>

        <FormDataConsumer>
          {({ formData }) => {
            if (!formData) return null
            const type = formData.type

            switch (type) {
              case 'book':
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Book"
                      source="bookId"
                      reference="books">
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                        validate={validateRequired('Book')}
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                      />
                    </ReferenceInput>
                  </Grid>
                )

              case 'audio_list':
                return (
                  <Grid item xs={12}>
                    <SelectInput
                      source="audioType"
                      choices={AUDIO_TYPES}
                      fullWidth
                      required
                      variant="standard"
                      validate={validateRequired('Audio type')}
                    />
                  </Grid>
                )

              case 'audio_series':
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Audio Series"
                      source="audioSeriesId"
                      reference="audio-series">
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                        validate={validateRequired('Audio Series')}
                        filterToQuery={searchText => ({
                          name: { $iLike: `%${searchText}%` },
                        })}
                      />
                    </ReferenceInput>
                  </Grid>
                )

              case 'news_feed':
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="News feed"
                      source="newsFeedId"
                      reference="news-feeds">
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                        validate={validateRequired('News feed')}
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                      />
                    </ReferenceInput>
                  </Grid>
                )

              default:
                return null
            }
          }}
        </FormDataConsumer>

        <Grid item xs={12}>
          {renderCover ? (
            renderCover(props)
          ) : (
            <ImageInput
              source="url"
              label="Image"
              accept="image/*"
              multiple={false}
              validate={validateRequired('Image')}>
              <CustomImageField source="src" title="Image" />
            </ImageInput>
          )}
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default SlideshowForm
