import { Fragment } from 'react'
import {
  List,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  TextField,
} from 'react-admin'

const DownloadField = ({ record }) => {
  const { downloadUrl } = record
  return (
    downloadUrl && (
      <a style={{ color: '#0000EE' }} href={downloadUrl}>
        Download
      </a>
    )
  )
}

const RANGE = {
  today: 'Today',
  last_week: 'Last week',
  last_month: 'Last month',
  last_3_months: 'Last 3 months',
  custom: 'Custom',
}

const CrmRevenueReportList = props => {
  return (
    <Fragment>
      <List {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <NumberField source="id" />
          <TextField source="providers" label="Payment providers" />
          <FunctionField
            label="Date Range"
            source="range"
            render={record => RANGE[record.range] || '-'}
          />
          <DateField source="fromDate" showTime label="From" />
          <DateField source="toDate" showTime label="To" />
          <NumberField source="totalGrossRevenue" label="Gross Revenue" />
          <NumberField source="totalNetRevenue" label="Net Revenue" />
          <DateField source="createdAt" label="Created At" />
          <DownloadField label="File Report" />
        </Datagrid>
      </List>
    </Fragment>
  )
}

export default CrmRevenueReportList
