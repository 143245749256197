import { Create } from 'react-admin'

import BannerForm from './BannerForm'

const BannerCreate = props => (
  <Create redirect="list" {...props}>
    <BannerForm />
  </Create>
)

export default BannerCreate
