import { Edit } from 'react-admin'

import EnglishBookForm from './EnglishBookForm'

const EnglishBookEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <EnglishBookForm />
    </Edit>
  )
}

export default EnglishBookEdit
