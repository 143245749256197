import { Create } from 'react-admin'

import BatchCodesForm from './BatchCodesForm'

const BatchCodesCreate = props => (
  <Create redirect="list" {...props}>
    <BatchCodesForm permissions={props.permissions} />
  </Create>
)

export default BatchCodesCreate
