import { Switch, FormControlLabel } from '@mui/material'
import { useRecordContext } from 'react-admin'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'

const SlideshowStatusField = () => {
  const updateDataProvider = useUpdateDataProvider()
  const record = useRecordContext()

  const handleChange = async () => {
    const data = {
      status: record.status === true ? false : true,
    }

    updateDataProvider(
      'slideshows',
      { id: record.id, data },
      {
        onSuccess: {
          notification: { body: 'Slideshow updated', level: 'info' },
          redirectTo: '/slideshows',
        },
        onFailure: {
          notification: {
            body: 'Error: slideshows not updated',
            level: 'warning',
          },
        },
      },
    )
  }

  return (
    <FormControlLabel
      control={<Switch checked={record.status} onChange={handleChange} />}
    />
  )
}

export default SlideshowStatusField
