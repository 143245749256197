import { Edit } from 'react-admin'

import AudioSeriesForm from './AudioSeriesForm'

const AudioSeriesEdit = props => (
  <Edit title={`Audio-series #${props.id}`} undoable={false} {...props}>
    <AudioSeriesForm />
  </Edit>
)

export default AudioSeriesEdit
