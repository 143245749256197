import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Toolbar, SaveButton, Button, Confirm } from 'react-admin'
import IconCancel from '@mui/icons-material/Cancel'
import { useLocation } from 'react-router-dom'

const CourseToolbar = props => {
  const [isOpen, setOpen] = useState(false)
  const location = useLocation()
  const path = location.pathname
  const navigate = useNavigate()

  const handleCancel = e => {
    e.preventDefault()
    setOpen(true)
  }

  const handleDelete = () => {
    navigate(-1)
  }

  return (
    <Toolbar {...props}>
      <SaveButton
        label={path.includes('create') ? 'create' : 'update'}></SaveButton>
      <Button
        label="ra.action.cancel"
        onClick={handleCancel}
        style={{ marginLeft: 10 }}>
        <IconCancel />
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Are you sure?"
        content="You won't able to revert this"
        onConfirm={handleDelete}
        onClose={() => setOpen(false)}
      />
    </Toolbar>
  )
}

export default CourseToolbar
