import { Create } from 'react-admin'

import BundleForm from './BundleForm'

const BundleCreate = props => (
  <Create redirect="list" {...props}>
    <BundleForm />
  </Create>
)

export default BundleCreate
