import { useRecordContext } from 'react-admin'
import { Switch, FormControlLabel } from '@mui/material'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'
const BannerStatusField = () => {
  const record = useRecordContext()
  const updateDataProvider = useUpdateDataProvider()

  const handleChange = async () => {
    const data = {
      status: record.status === true ? false : true,
    }

    updateDataProvider(
      'banners',
      { id: record.id, data },
      {
        onSuccess: {
          notification: { body: 'Banner updated', level: 'info' },
          redirectTo: '/banners',
        },
        onFailure: {
          notification: {
            body: 'Error: banners not updated',
            level: 'warning',
          },
        },
      },
    )
  }

  return (
    <FormControlLabel
      control={<Switch checked={record.status} onChange={handleChange} />}
    />
  )
}

export default BannerStatusField
