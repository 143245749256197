import { Fragment } from 'react'
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  AutocompleteInput,
  DateInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import { CODE_TYPES, CODE_SOURCE } from '../../components/constants'

const ApiCodeForm = () => {
  return (
    <SimpleForm redirect="list">
      <GridContainer>
        <Grid item xs={4}>
          <SelectInput
            label="Type"
            source="type"
            choices={CODE_TYPES}
            fullWidth
            variant="standard"
          />
        </Grid>
        <FormDataConsumer fullWidth>
          {({ formData }) => {
            if (!formData) return null

            switch (formData.type) {
              case 'book':
                return (
                  <Fragment>
                    <Grid item xs={8}>
                      <ReferenceInput
                        label="Book"
                        source="bookId"
                        reference="books">
                        <AutocompleteInput
                          optionText="title"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                          filterToQuery={searchText => ({
                            title: { $iLike: `%${searchText}%` },
                          })}
                        />
                      </ReferenceInput>
                    </Grid>
                  </Fragment>
                )

              case 'collection':
                return (
                  <Fragment>
                    <Grid item xs={8}>
                      <ReferenceInput
                        label="Collection"
                        source="collectionCodeId"
                        reference="collection-codes">
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                          filterToQuery={searchText => ({
                            name: { $iLike: `%${searchText}%` },
                          })}
                        />
                      </ReferenceInput>
                    </Grid>
                  </Fragment>
                )
              default:
                return null
            }
          }}
        </FormDataConsumer>
        <Grid item xs={6}>
          <SelectInput
            source="source"
            choices={CODE_SOURCE}
            defaultValue="web"
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Source')}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            source="expiredAt"
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Expired at')}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default ApiCodeForm
