import { Create } from 'react-admin'

import PodcasterForm from './ChannelForm'

const ChannelCreate = props => (
  <Create redirect="list" {...props}>
    <PodcasterForm isCreating permissions={props.permissions} />
  </Create>
)

export default ChannelCreate
