import { Fragment } from 'react'
import {
  TextInput,
  ImageInput,
  minLength,
  maxLength,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import MarkdownInput from '../../components/MarkdownInput'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const NEWS_FEED_TYPES = [
  { id: 'book', name: 'Book' },
  { id: 'audio', name: 'Audio' },
  { id: 'audio_list', name: 'Audio list' },
  { id: 'link_url', name: 'Link url' },
]

const AUDIO_TYPES = [
  { id: 'music', name: 'Music' },
  { id: 'book_summary', name: 'Book summary' },
  { id: 'meditation', name: 'Meditation' },
  { id: 'sleep_story', name: 'Sleep story' },
  { id: 'kids_story', name: 'Kids story' },
]

const AUDIO_TYPE_REFERENCE = {
  music: 'musics',
  book_summary: 'book-summaries',
  meditation: 'meditations',
  sleep_story: 'sleep-stories',
  kids_story: 'kids-stories',
}

const AUDIO_TYPE_TITLE = {
  music: 'Music',
  book_summary: 'Book summary',
  meditation: 'Meditation',
  sleep_story: 'Sleep story',
  kids_story: 'Kids story',
}

const NewsFeedForm = props => {
  const validateDescription = [
    validateRequired('Description'),
    minLength(20),
    maxLength(2000, 'Do not enter more than 2000 characters'),
  ]

  const validateBlogPost = [validateRequired('Blog post'), minLength(20)]

  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="title"
            fullWidth
            variant="standard"
            validate={validateRequired('Title')}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectInput
            source="type"
            choices={NEWS_FEED_TYPES}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Type')}
          />
        </Grid>

        <FormDataConsumer>
          {({ formData }) => {
            if (!formData) return null
            const newsFeedType = formData && formData.type
            const audioType = formData && formData.audioType

            switch (newsFeedType) {
              case 'book':
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Book title"
                      source="bookId"
                      reference="books">
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                        required
                        validate={validateRequired('Book title')}
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                      />
                    </ReferenceInput>
                  </Grid>
                )

              case 'audio':
                return (
                  <Fragment>
                    <Grid item xs={4}>
                      <SelectInput
                        source="audioType"
                        choices={AUDIO_TYPES}
                        fullWidth
                        variant="standard"
                        required
                        validate={validateRequired('Audio type')}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      {audioType && (
                        <ReferenceInput
                          label={`${AUDIO_TYPE_TITLE[audioType]} title`}
                          source="audioId"
                          reference={AUDIO_TYPE_REFERENCE[audioType]}
                          validate={validateRequired(
                            `${AUDIO_TYPE_TITLE[audioType]} title`,
                          )}>
                          <SelectInput
                            optionText="title"
                            fullWidth
                            variant="standard"
                            required
                          />
                        </ReferenceInput>
                      )}
                    </Grid>
                  </Fragment>
                )
              case 'audio_list':
                return (
                  <Grid item xs={12}>
                    <SelectInput
                      source="audioType"
                      choices={AUDIO_TYPES}
                      fullWidth
                      variant="standard"
                      required
                      validate={validateRequired('Audio type')}
                    />
                  </Grid>
                )

              case 'link_url':
                return (
                  <Grid item xs={12}>
                    <TextInput
                      source="linkUrl"
                      fullWidth
                      variant="standard"
                      required
                      validate={validateRequired('Link URL')}
                    />
                  </Grid>
                )

              default:
                return null
            }
          }}
        </FormDataConsumer>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            validate={validateDescription}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <MarkdownInput
            source="blogPost"
            validate={validateBlogPost}
            label="Blog post"
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default NewsFeedForm
