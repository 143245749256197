import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  DateField,
  FunctionField,
  Pagination,
  TextInput,
  SelectInput,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const BUNDLE_STATUS = {
  draft: 'DRAFT',
  new: 'NEW',
  running: 'RUNNING',
  expired: 'EXPIRED',
  canceled: 'CANCELED',
}

const BundlePagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 100, 1000]} {...props} />
)

const BundleFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput
      source="type"
      choices={[
        { id: 'daily', name: 'Daily' },
        { id: 'weekly', name: 'Weekly' },
      ]}
      alwaysOn
    />
  </FilterComponent>
)

const BundleList = props => (
  <List
    {...props}
    perPage={1000}
    pagination={<BundlePagination />}
    filters={<BundleFilter />}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="id" />
      <TextField source="title" />
      <FunctionField
        source="status"
        render={record => BUNDLE_STATUS[record.status]}
      />
      <TextField source="description" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <EditButton />
      <DeleteButton mutationMode="undoable" />
    </Datagrid>
  </List>
)

export default BundleList
