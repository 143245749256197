import { Edit } from 'react-admin'

import TopicForm from './TopicForm'

const TopicEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <TopicForm editing />
    </Edit>
  )
}

export default TopicEdit
