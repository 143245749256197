import { Edit } from 'react-admin'

import BatchCodesForm from './BatchCodesForm'

const BatchCodesEdit = props => (
  <Edit undoable={false} {...props}>
    <BatchCodesForm permissions={props.permissions} />
  </Edit>
)

export default BatchCodesEdit
