import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  TextInput,
  EmailField,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const UserDetailsFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Id" source="id" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Username" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Phone number" source="phoneNumber" alwaysOn />
  </FilterComponent>
)

const UserDetailsList = props => (
  <List
    filters={<UserDetailsFilter />}
    {...props}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <DateField source="createdAt" />
      <TextField source="credits" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default UserDetailsList
