import Avatar from '@mui/material/Avatar'
import { useRecordContext } from 'react-admin'

const CustomAvatarField = props => {
  const { className, source, originalSrc = false } = props
  const record = useRecordContext()
  return (
    <Avatar
      src={
        record[source] ||
        (originalSrc ? record : `${process.env.REACT_APP_S3_URL}/${record}`)
      }
      alt={record[source] || record}
      className={`${className}`}
    />
  )
}

export const CustomS3AvatarField = props => {
  const { source } = props
  const record = useRecordContext()
  if (!record || !source) {
    return <></>
  }

  return <Avatar src={`${process.env.REACT_APP_S3_URL}/${record[source]}`} />
}

export default [CustomAvatarField, CustomS3AvatarField]
