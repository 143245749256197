import { Create } from 'react-admin'

import CollectionForm from './ChallengeRewardForm'

const ChallengeRewardCreate = props => (
  <Create redirect="list" {...props}>
    <CollectionForm />
  </Create>
)

export default ChallengeRewardCreate
