import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'

const B2BTrialUserForm = props => {
  return (
    <SimpleForm {...props} redirect="list" variant="standard" margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <ReferenceInput
            label="Organization"
            name="organizationId"
            source="organizationId"
            reference="b2b-organizations"
            filter={searchText => ({
              name: { $iLike: `%${searchText}%` },
              isTrial: true,
            })}>
            <AutocompleteInput
              name="organizationId"
              optionText="name"
              fullWidth
              variant="standard"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput name="email" source="email" />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default B2BTrialUserForm
