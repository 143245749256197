import { Create } from 'react-admin'

import B2BOrganizationForm from './B2BOrganizationContentForm'

const B2BOrganizationContentCreate = props => (
  <Create redirect="list" {...props}>
    <B2BOrganizationForm />
  </Create>
)

export default B2BOrganizationContentCreate
