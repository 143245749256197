import { Create } from 'react-admin'

import AudioSeriesForm from './AudioSeriesForm'

const AudioSeriesCreate = props => (
  <Create redirect="list" title={'Create Audio-series'} {...props}>
    <AudioSeriesForm />
  </Create>
)

export default AudioSeriesCreate
