import { memo, useState } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import UserDetailSummary from '../user-details/UserDetailsShow'

const CsMergeAccountPreview = ({ fromUserId, toUserId, classes }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {fromUserId && toUserId && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}>
          <Typography variant="caption">SHOW USERS PREVIEW</Typography>
        </Button>
      )}
      <SwipeableDrawer
        anchor="right"
        open={open}
        onBackdropClick={() => setOpen(false)}>
        <Container
          className={classes.drawer}
          sx={{ padding: 24, minWidth: 1024 }}>
          <Box component="div">
            <Typography variant="h5">From User: </Typography>
            {fromUserId && (
              <UserDetailSummary
                key={fromUserId}
                id={fromUserId}
                basePath="/user-details-1"
                resource="user-details"
                hasEdit={false}
                tabSyncWithLocation={false}
              />
            )}
          </Box>
          <Box component="div" mt={8}>
            <Typography variant="h5">To User: </Typography>
            {toUserId && (
              <UserDetailSummary
                key={toUserId}
                id={toUserId}
                basePath="/user-details-2"
                resource="user-details"
                tabSyncWithLocation={false}
              />
            )}
          </Box>
        </Container>
      </SwipeableDrawer>
    </>
  )
}

export default memo(CsMergeAccountPreview)
