import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  FormDataConsumer,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import { ROLES } from '../../components/constants'

import CsSwitchAccountPreview from './CsSwitchAccountPreview'

const CsSwitchAccountCreate = props => {
  return (
    <Create redirect="list" undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={6}>
            <ReferenceInput
              label="From User username"
              source="fromUserId"
              filter={{
                role: ROLES.USER,
              }}
              reference="users">
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
                validate={validateRequired('user')}
                filterToQuery={searchText => ({
                  username: { $iLike: `%${searchText}%` },
                  role: ROLES.USER,
                })}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              label="To User username"
              source="toUserId"
              helperText="To user username"
              reference="users">
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
                validate={validateRequired('user')}
                filterToQuery={searchText => ({
                  username: { $iLike: `%${searchText}%` },
                  role: ROLES.USER,
                })}
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12}>
            <FormDataConsumer>
              {({ formData }) =>
                formData ? (
                  <CsSwitchAccountPreview
                    fromUserId={formData.fromUserId}
                    toUserId={formData.toUserId}
                  />
                ) : null
              }
            </FormDataConsumer>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="reason"
              fullWidth
              variant="standard"
              required
              validate={validateRequired('Reason')}
            />
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default CsSwitchAccountCreate
