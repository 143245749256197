import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DateField,
  TextInput,
  usePermissions,
} from 'react-admin'

import DeleteButton from '../../components/DeleteButton'
import CustomSwitchField from '../../components/CustomSwitchField'
import { ROLES } from '../../components/constants'
import FilterComponent from '../../components/Filter'

const Filter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
  </FilterComponent>
)

const MeditationBackgroundList = ({ ...props }) => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<Filter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="name" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <CustomSwitchField
          resource="meditation-backgrounds"
          label="Status"
          switchField="status"
          disabled={
            ![ROLES.ADMIN, ROLES.CPSO, ROLES.CONTENT_EDITOR].includes(
              permissions,
            )
          }
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export default MeditationBackgroundList
