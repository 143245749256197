import { deepmerge } from '@mui/utils'
import { defaultTheme } from 'react-admin'
import { experimental_extendTheme as extendTheme } from '@mui/material'

import { colorPalette } from './constants/color-schemes'
import { components } from './components/components'
import { shadows } from './constants/shadows'
import { typography } from './constants/typography'

export function createTheme() {
  const extendedTheme = extendTheme({
    breakpoints: { values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 } },
    direction: 'ltr',
    shadows: shadows.light,
    shape: { borderRadius: 8 },
    typography,
    components: {
      ...defaultTheme.components,
      ...components,
    } as any,
    colorSchemes: {
      light: {
        palette: {
          ...defaultTheme.palette,
          ...colorPalette,
        },
      },
    },
  })

  const theme = deepmerge(defaultTheme, {
    components: extendedTheme.components,
    typography: extendedTheme.typography,
  })

  return { theme, extendedTheme }
}
