import { useRecordContext } from 'react-admin'

const DateTimeField = ({ source = 'createdAt' }) => {
  const record = useRecordContext()
  const dateTime = record[source] // Assuming your API returns a DateTime field
  const formattedDateTime = dateTime ? new Date(dateTime).toLocaleString() : ''

  return <span>{formattedDateTime}</span>
}

export default DateTimeField
