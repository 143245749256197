import {
  Button,
  useRefresh,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin'

import { EPISODE_STATUS } from './constants'

const EpisodeActionButton = props => {
  const { action, ...rest } = props
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  console.log(record)

  const getDataUpdate = () => {
    switch (action) {
      case 'disable':
        return {
          status: EPISODE_STATUS.DISABLED,
        }
      case 'enable':
        return {
          status: EPISODE_STATUS.UNPUBLISHED,
        }
      case 'published':
        return {
          status: EPISODE_STATUS.PUBLISHED,
        }
      case 'unpublished':
        return {
          status: EPISODE_STATUS.UNPUBLISHED,
        }
      default:
        return {}
    }
  }

  const handleClick = async () => {
    const data = getDataUpdate()

    dataProvider
      .update('episodes', { id: record.id, data })
      .then(() => {
        notify('Episode updated')
        refresh()
      })
      .catch(error => {
        notify(`Error: Episode not updated ${error.message}`, { type: 'error' })
      })
  }

  return (
    <Button
      sx={{ margin: '5px 0' }}
      variant="contained"
      onClick={handleClick}
      {...rest}></Button>
  )
}

export default EpisodeActionButton
