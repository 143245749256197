import React, { useMemo, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { Switch, FormControlLabel } from '@mui/material'
import { debounce, get } from 'lodash'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'

const CustomSwitchField = props => {
  const { resource, switchField, disabled } = props
  const record = useRecordContext()

  const updateDataProvider = useUpdateDataProvider()

  const [checked, setChecked] = useState(get(record, switchField))

  const onChange = useMemo(() => {
    return debounce(
      checked =>
        updateDataProvider(
          resource,
          {
            id: record.id,
            data: {
              [switchField]: checked,
            },
          },
          {
            onSuccess: {
              notification: { body: 'Update successfully', level: 'info' },
              redirectTo: `/${resource}`,
            },
            onFailure: {
              notification: {
                body: `Error: ${resource} not updated`,
                level: 'warning',
              },
            },
          },
        ),
      500,
    )
  }, [record.id, resource, switchField, updateDataProvider])

  const handleChange = async () => {
    setChecked(prev => {
      onChange(!prev)
      return !prev
    })
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          disabled={
            typeof disabled === 'function' ? disabled(record) : disabled
          }
        />
      }
    />
  )
}

export default CustomSwitchField
