import { Create } from 'react-admin'

import MeditationForm from './MeditationForm'

const MeditationCreate = props => (
  <Create redirect="list" {...props}>
    <MeditationForm permissions={props.permissions} />
  </Create>
)

export default MeditationCreate
