import { Create } from 'react-admin'

import B2BOrganizationForm from './B2BOrganizationForm'

const B2BOrganizationCreate = props => (
  <Create redirect="list" {...props}>
    <B2BOrganizationForm />
  </Create>
)

export default B2BOrganizationCreate
