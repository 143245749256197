import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  TextInput,
  ReferenceField,
  SelectInput,
  ReferenceInput,
  usePermissions,
} from 'react-admin'

import FilterComponent from '../../components/Filter'
import { CustomS3AvatarField } from '../../components/CustomAvatarField'
import { ROLES } from '../../components/constants'
import EpisodeStatusField from '../../components/EpisodeStatusField'

const EpisodeFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
    <ReferenceInput
      label="Channel"
      source="channelId"
      reference="channels"
      alwaysOn>
      <SelectInput
        optionText="name"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>
  </FilterComponent>
)

const EpisodeList = () => {
  const { permissions } = usePermissions()

  return (
    <List
      filters={<EpisodeFilter />}
      sort={{ field: 'publishDate', order: 'DESC' }}
      bulkActionButtons={false}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        {/*<TextField source="episodeIndex" label="Order" />*/}
        <TextField source="title" label="Title" />
        <CustomS3AvatarField source="coverImageUrl" label="Cover Image" />
        <ReferenceField source="channelId" reference="channels" label="Channel">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="publishDate" label="Published date" />
        <EpisodeStatusField disabled={permissions !== ROLES.ADMIN} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default EpisodeList
