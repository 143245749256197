import { Admin, Resource, CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'

import { authClient } from './provider'
import feathersClient from './feathersClient'
// import CustomRoutes from './customRoutes'
import getDataProvider from './dataProvider'
import MyLayout from './MyUserMenu'
import LoginPage from './components/LoginPage'
import Dashboard from './components/Dashboard/index'
import resources from './resources'
import { ROLE_DETAIL_KEY } from './provider/authClient'
import { hyphenToCamelCaseWithPrefix } from './utils/case'
import withAwsWaf from './withAwsWaf'
import ThemeProvider from './styles/Theme/ThemeProvider'
import ChangePasswordPage from './components/ChangePasswordPage'
import ForgotPasswordPage from './components/ForgotPasswordPage'
import ResetPasswordPage from './components/ResetPasswordPage'

const authClientOptions = {
  storageKey: 'feathers-jwt',
  usernameField: 'email', // Our example database might use 'username' rather than 'email'
  redirectTo: '/login',
  permissionsField: 'role',
}

const App = () => {
  feathersClient.get().reAuthenticate()

  return (
    <ThemeProvider>
      {theme => (
        <Admin
          title="Fonos"
          loginPage={LoginPage}
          layout={MyLayout}
          authProvider={authClient(feathersClient.get(), authClientOptions)}
          dashboard={Dashboard}
          dataProvider={getDataProvider()}
          theme={theme}
          darkTheme={null}>
          {() => {
            const roleDetail = JSON.parse(
              localStorage.getItem(ROLE_DETAIL_KEY) || '{}',
            )
            const can =
              (roleDetail &&
                roleDetail.permission &&
                roleDetail.permission.can) ||
              {}

            const readResources = (can.read || []).map(r =>
              hyphenToCamelCaseWithPrefix(r, 'read'),
            )

            const manageResources = can.manage || []

            const routes = [...readResources, ...manageResources]
              .map(route => resources[route])
              .filter(Boolean)
            if (routes.length) {
              return routes
            }
            return [<Resource key={1} name="users" />]
          }}
          {/* <CustomRoutes /> */}

          <CustomRoutes>
            <Route path="/changePassword" element={<ChangePasswordPage />} />
          </CustomRoutes>

          <CustomRoutes noLayout>
            <Route path="/forgot" element={<ForgotPasswordPage />} />
            <Route path="/reset" element={<ResetPasswordPage />} />
          </CustomRoutes>
        </Admin>
      )}
    </ThemeProvider>
  )
}

export default withAwsWaf(App)
