import { Create } from 'react-admin'

import BookForm from './BookForm'

const UserCreate = props => (
  <Create redirect="list" {...props}>
    <BookForm isCreating />
  </Create>
)

export default UserCreate
