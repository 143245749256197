import { Create } from 'react-admin'

import MusicForm from './MusicForm'

const MusicCreate = props => (
  <Create redirect="list" {...props}>
    <MusicForm permissions={props.permissions} />
  </Create>
)

export default MusicCreate
