import {
  TextInput,
  maxLength,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import CustomSvgField from '../../components/CustomSvgField'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'

const validateMaxLength = maxLength(50, 'Do not enter more than 50 characters')

const SHOW_ON_OPTIONS = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]
const choices = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]

const CategoryForm = props => {
  const TYPES = [
    { id: 'book', name: 'Book', disabled: props.editing },
    { id: 'english_book', name: 'English book', disabled: props.editing },
    { id: 'course', name: 'Course', disabled: props.editing },
    { id: 'music', name: 'Music', disabled: props.editing },
    { id: 'sleep_story', name: 'Sleep story', disabled: props.editing },
    { id: 'kids_story', name: 'Kids story', disabled: props.editing },
    { id: 'meditation', name: 'Meditation', disabled: props.editing },
    { id: 'book_summary', name: 'Book summary', disabled: props.editing },
    { id: 'ebook', name: 'Ebook', disabled: props.editing },
    { id: 'podcast', name: 'Podcast', disabled: props.editing },
  ]

  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        {props.editing && (
          <Grid item xs={12}>
            <TextInput source="slug" fullWidth variant="standard" disabled />
          </Grid>
        )}
        <Grid item xs={8}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={[validateRequired('Type'), validateMaxLength]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            source="type"
            choices={TYPES}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Type')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <SelectInput
            label="Primary"
            choices={choices}
            source="primary"
            fullWidth
            required
            variant="standard"
            disabled={props.editing}
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="icon"
            label="Icon in SVG"
            accept="image/svg+xml"
            multiple={false}>
            <CustomSvgField />
          </ImageInput>
        </Grid>

        <FormDataConsumer>
          {({ formData }) => {
            if (!formData) return null
            if (!formData.primary) {
              return (
                <Grid item xs={12}>
                  <ReferenceInput
                    label="Parent category"
                    source="parentId"
                    filter={{
                      primary: true,
                      type: formData.type,
                    }}
                    reference="categories">
                    <AutocompleteInput
                      optionText="name"
                      options={{
                        fullWidth: true,
                      }}
                      variant="standard"
                      filterToQuery={searchText => ({
                        name: { $iLike: `%${searchText}%` },
                        primary: true,
                        type: formData.type,
                      })}
                    />
                  </ReferenceInput>
                </Grid>
              )
            } else {
              return (
                <Grid item xs={12}>
                  <ReferenceArrayInput
                    label="Child categories"
                    source="childCategoryIds"
                    reference="categories">
                    <AutocompleteArrayInput
                      optionText="name"
                      fullWidth
                      required
                      variant="standard"
                      filterToQuery={searchText => ({
                        name: { $iLike: `%${searchText}%` },
                        primary: false,
                        parentId: { $eq: 'null' },
                        type: formData.type,
                      })}
                    />
                  </ReferenceArrayInput>
                </Grid>
              )
            }
          }}
        </FormDataConsumer>

        <Grid item xs={6}>
          <SelectInput
            source="showOnList"
            choices={SHOW_ON_OPTIONS}
            fullWidth
            variant="standard"
            required
            defaultValue={true}
            validate={validateRequired('Show on list')}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            source="showOnDetail"
            choices={SHOW_ON_OPTIONS}
            fullWidth
            variant="standard"
            defaultValue={true}
            required
            validate={validateRequired('Show on detail')}
          />
        </Grid>

        <FormDataConsumer>
          {({ formData }) => {
            if (!formData) return null
            const type = formData.type

            switch (type) {
              case 'book':
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Banner Book"
                      source="bannerBookId"
                      reference="books">
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                      />
                    </ReferenceInput>
                  </Grid>
                )

              case 'ebook':
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Banner ebook"
                      source="bannerEbookId"
                      reference="ebooks">
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                      />
                    </ReferenceInput>
                  </Grid>
                )

              case 'book_summary':
                return (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Book Summary"
                      source="bannerBookSummaryId"
                      reference="book-summaries">
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                      />
                    </ReferenceInput>
                  </Grid>
                )
              default:
                return null
            }
          }}
        </FormDataConsumer>
      </GridContainer>
    </SimpleForm>
  )
}

export default CategoryForm
