import { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  SelectInput,
  DateInput,
  useListContext,
} from 'react-admin'

import UserDetailField from '../../components/UserDetailField'
import ApproveButtons from '../../components/ApproveAction/ApproveButtons'
import ApproveStatus from '../../components/ApproveAction/ApproveStatus'
import FilterComponent from '../../components/Filter'

const CsSwitchAccountFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <SelectInput
        label="Filter"
        source="date"
        choices={[
          { id: 'all', name: 'All' },
          { id: 'this_month', name: 'This month' },
          { id: 'last_month', name: 'Last month' },
          { id: 'custom', name: 'Choose date' },
        ]}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const CsSwitchAccountList = props => {
  return (
    <Fragment>
      <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<CsSwitchAccountFilter />}
        bulkActionButtons={false}>
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <UserDetailField label="From User" idField="fromUserId" />
          <UserDetailField label="To User" idField="toUserId" />
          <TextField source="reason" />
          <ReferenceField
            label="Created by"
            source="createdBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Approved by"
            source="approvedBy"
            reference="users">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ApproveStatus label="Status" />
          <ApproveButtons service="cs-switch-accounts" />
        </Datagrid>
      </List>
    </Fragment>
  )
}

export default CsSwitchAccountList
