import { Button, useRefresh, useRecordContext } from 'react-admin'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'

const CodActionButton = props => {
  const { action, ...rest } = props
  const record = useRecordContext()
  const updateDataProvider = useUpdateDataProvider()

  const refresh = useRefresh()

  const handleClick = async () => {
    await updateDataProvider(
      'payment-cod',
      {
        id: record.id,
        data: action === 'confirm' ? { confirm: true } : { canceled: true },
      },
      {
        onSuccess: {
          notification: {
            body:
              action === 'confirm'
                ? `Confirmed COD payment`
                : 'Canceled COD payment',
            level: 'info',
          },
          redirectTo: '/payment-cod',
        },
        onFailure: {
          notification: {
            body:
              action === 'confirm'
                ? 'Error: COD payment not confirmed'
                : 'Error: COD payment not canceled',
            level: 'warning',
          },
        },
      },
    )

    refresh()
  }

  return (
    <Button
      sx={{ margin: '5px 0', minWidth: 90 }}
      variant="contained"
      onClick={handleClick}
      {...rest}
    />
  )
}

export default CodActionButton
