import { useRef, useState } from 'react'
import get from 'lodash/get'
import { useRecordContext } from 'react-admin'

const TextFieldTruncate = ({ source, maxLine = 4, ...props }) => {
  const [toggle, setToggle] = useState(false)
  const contentRef = useRef(null)
  const record = useRecordContext()
  const text = get(record, source, '')

  return (
    <div onClick={() => setToggle(prev => !prev)}>
      <span
        style={{
          WebkitLineClamp: toggle ? 'unset' : maxLine.toString,
          'word-wrap': 'break-word',
          overflow: 'hidden',
          ' text-overflow': 'ellipsis',
          display: '-webkit-box',
          '-webkit-line-clamp': 2,
          '-webkit-box-orient': 'vertical',
        }}
        ref={contentRef}
        {...props}>
        {text}
      </span>
    </div>
  )
}

export default TextFieldTruncate
