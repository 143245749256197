'use client'

import * as React from 'react'
import Box from '@mui/material/Box'

const HEIGHT = 40
const WIDTH = 101

export interface LogoProps {
  size?: number
}

export function Logo({ size = 1 }: LogoProps): React.JSX.Element {
  const height = HEIGHT * size
  const width = WIDTH * size
  return (
    <Box
      alt="logo"
      component="img"
      height={height}
      src={'/logo.webp'}
      width={width}
    />
  )
}
