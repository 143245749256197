import { useEffect, useState, Fragment, useCallback } from 'react'
import moment from 'moment'
import debounce from 'lodash/debounce'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  SelectInput,
  ReferenceInput,
  DateInput,
  ExportButton,
  useListContext,
  useDataProvider,
  useNotify,
} from 'react-admin'
import { Grid } from '@mui/material'

import { parseDate } from '../../utils/parseDate'
import { exportExcel } from '../../utils/exportExcel'
import { formatCurrency } from '../../utils/formatCurrency'
import { SUBSCRIPTION } from '../../components/constants'
import FilterComponent from '../../components/Filter'

const Actions = ({ resource, currentSort }) => {
  const { filterValues } = useListContext()
  return (
    <ExportButton
      disabled={false}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  )
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      Code: record.code,
      Plan: record.plan,
      Price: record.amount,
      Date: record.createdAt,
      Commission: record.commission,
      Amount: formatCurrency.format(
        ((record.commission / 100) * SUBSCRIPTION.YEARLY_PRICE).toFixed(0),
      ),
      TransactionId: record.originalTransactionId,
      PaymentMethod: record.paymentMethod,
    }
  })

  exportExcel({
    data,
    title: 'Dashboard',
    type: 'xlsx',
  })
}

const CustomDatagrid = () => {
  const [revenue, setRevenue] = useState(0)
  const [totalInstall, setTotalInstall] = useState(0)
  const [totalInstallAmount, setTotalInstallAmount] = useState(0)
  const [isShowing = {}, setIsShowing] = useState({
    showFD: false,
    showTD: false,
  })

  const { filterValues } = useListContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleSetRevenue = useCallback(() => {
    dataProvider
      .getOne('affiliate-dashboard-revenue', {
        id: null,
        date: filterValues && filterValues.date,
        affiliateId: filterValues && filterValues.affiliateId,
        fromDate: filterValues && filterValues.fromDate,
        toDate: filterValues && filterValues.toDate,
      })
      .then(({ data }) => setRevenue(data.result))
      .catch(console.log)
    dataProvider
      .getOne('affiliate-dashboard-install', {
        id: null,
        date: filterValues && filterValues.date,
        affiliateId: filterValues && filterValues.affiliateId,
        fromDate: filterValues && filterValues.fromDate,
        toDate: filterValues && filterValues.toDate,
      })
      .then(({ data }) => {
        setTotalInstall(data.totalInstall)
        setTotalInstallAmount(data.totalInstallAmount)
      })
      .catch(console.log)
  }, [dataProvider, filterValues])

  useEffect(() => {
    if (isShowing.showFD)
      setTimeout(() => setIsShowing({ ...isShowing, showFD: false }), 5000)
    if (isShowing.showTD)
      setTimeout(() => setIsShowing({ ...isShowing, showTD: false }), 5000)
  }, [isShowing])

  useEffect(() => {
    const debounced = debounce(() => {
      if ('custom' !== filterValues.date) {
        handleSetRevenue()
      } else {
        const fromDate = parseDate(filterValues.fromDate, 'YYYY-MM-DD'),
          toDate = parseDate(filterValues.toDate, 'YYYY-MM-DD'),
          presentDate = parseDate(new Date(), 'YYYY-MM-DD')
        if (fromDate > toDate && !isShowing.showFD) {
          notify(`End date isn't less than start date`, { type: 'error' })
          setIsShowing({ ...isShowing, showFD: true })
        } else if (toDate > presentDate && !isShowing.showTD) {
          notify(`End date isn't larger than present date`, { type: 'error' })
          setIsShowing({ ...isShowing, showTD: true })
        } else {
          handleSetRevenue()
        }
      }
    }, 1000)
    debounced.call()
  }, [filterValues, handleSetRevenue, isShowing, notify])

  const PlanTextField = useCallback(({ record = {} }) => {
    const { productId } = record
    const plan = productId.split('.').pop()
    return <span>{plan}</span>
  }, [])

  const CommissionAmountField = useCallback(({ record = {} }) => {
    const { commission } = record
    const amount = ((commission / 100) * SUBSCRIPTION.YEARLY_PRICE).toFixed(0)
    return <span>{formatCurrency.format(amount.toString())}</span>
  }, [])

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <h3 style={{ marginLeft: 12 }}>Total install: {totalInstall}</h3>
          <h3 style={{ marginLeft: 12 }}>
            Total install amount: {formatCurrency.format(totalInstallAmount)}
          </h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h3 style={{ marginLeft: 12 }}>
            Total: {formatCurrency.format(revenue)}
          </h3>
        </Grid>
      </Grid>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <TextField source="code" />
        <PlanTextField source="Plan" />
        <NumberField source="amount" label="Price" />
        <DateField source="createdAt" label="Date" />
        <NumberField source="commission" />
        <CommissionAmountField source="amount" />
        <TextField source="originalTransactionId" label="Transaction Id" />
        <TextField source="paymentMethod" label="Payment method" />
      </Datagrid>
    </Fragment>
  )
}

const CustomFilter = () => {
  const { filterValues } = useListContext()

  return (
    <FilterComponent styles={{ marginLeft: '12px' }}>
      <ReferenceInput
        label="Affiliate name"
        source="affiliateId"
        reference="users"
        filter={{ role: 'affiliate' }}
        alwaysOn>
        <SelectInput
          optionText="name"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
      <SelectInput
        label="Filter"
        source="date"
        choices={[
          { id: 'all', name: 'All' },
          { id: 'this_month', name: 'This month' },
          { id: 'last_month', name: 'Last month' },
          { id: 'custom', name: 'Choose date' },
        ]}
        alwaysOn></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const DashboardAdmin = () => {
  return (
    <List
      title="Affiliate dashboard"
      resource="affiliate-purchase-histories"
      bulkActionButtons={false}
      filters={<CustomFilter />}
      actions={<Actions />}
      filterDefaultValues={{
        role: 'affiliate',
        date: 'all',
        fromDate: moment()
          .subtract(7, 'days')
          .startOf('day')
          .format('MM-DD-YYYY'),
        toDate: moment().endOf('day').format('MM-DD-YYYY'),
      }}
      sort={{ field: 'id', order: 'DESC' }}>
      <CustomDatagrid />
    </List>
  )
}

export default DashboardAdmin
