import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  EditButton,
  DeleteButton,
  downloadCSV,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import moment from 'moment'

import CustomSwitchField from '../../components/CustomSwitchField'

const ExportButton = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'userId', 'users').then(userOne => {
    fetchRelatedRecords(records, 'createdBy', 'users').then(userTwo => {
      const data = records.map(record => ({
        Id: record.id,
        Username: userOne[record.userId].username,
        'Influencer name': record.influencerName,
        'End date': moment(record.endDate).format('MM/DD/YYYY'),
        'Is renew': record.autoRenew ? 'Yes' : 'No',
        'Created by': userTwo[record.createdBy].name,
        'Updated by': userTwo[record.updatedBy].name,
      }))

      jsonExport(
        data,
        {
          headers: [
            'Id',
            'Username',
            'Influencer name',
            'End date',
            'Is renew',
            'Created by',
            'Updated by',
          ],
        },
        (err, csv) => {
          downloadCSV(csv, 'Free Account')
        },
      )
    })
  })
}

const FreeAccountsList = props => (
  <List
    {...props}
    exporter={ExportButton}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users" label="Username">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="influencerName" />
      <DateField source="endDate" />
      <CustomSwitchField
        resource="free-accounts"
        label="Is Renew"
        switchField="autoRenew"
      />
      <ReferenceField source="createdBy" reference="users" label="Created by">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="updatedBy" reference="users" label="Updated by">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default FreeAccountsList
