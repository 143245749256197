import { Create } from 'react-admin'

import CategoryForm from './CategoryForm'

const CategoryCreate = props => (
  <Create redirect="list" {...props}>
    <CategoryForm />
  </Create>
)

export default CategoryCreate
