import { Edit } from 'react-admin'

import GuidedMeditationForm from './GuidedMeditationForm'

const GuidedMeditationEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <GuidedMeditationForm />
    </Edit>
  )
}

export default GuidedMeditationEdit
