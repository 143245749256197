import { Edit } from 'react-admin'

import DiscountsForm from './DiscountsForm'

const DiscountsEdit = props => (
  <Edit undoable={false} {...props}>
    <DiscountsForm />
  </Edit>
)

export default DiscountsEdit
