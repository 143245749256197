import { Edit } from 'react-admin'

import RoleForm, { transformDataBeforeSubmit } from './RoleForm'

const RoleEdit = props => {
  return (
    <Edit undoable={false} {...props} transform={transformDataBeforeSubmit}>
      <RoleForm />
    </Edit>
  )
}

export default RoleEdit
