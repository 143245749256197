import { Edit } from 'react-admin'

import CourseForm from './CourseForm'

const CourseEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <CourseForm permissions={props.permissions} />
    </Edit>
  )
}

export default CourseEdit
