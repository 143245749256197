import { List, Datagrid, EditButton, DateField, TextField } from 'react-admin'

const CampaignList = ({ ...props }) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField label="Id" source="id" />
        <TextField label="Title" source="name" />
        <DateField label="Date start" source="startDate" />
        <DateField label="Date end" source="endDate" />
        <TextField label="Status" source="status" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default CampaignList
