import {
  TextInput,
  maxLength,
  SimpleForm,
  ImageInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  SelectInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import CustomImageField from '../../components/CustomImageField'
import { BOOK_STATUSES } from '../../components/constants'

const validateMaxLength = maxLength(50, 'Do not enter more than 50 characters')

const BundleForm = props => {
  const { renderImage } = props
  const TYPES = [
    { id: 'book', entity: 'books', name: 'Books', disabled: props.editing },
    // { id: 'book_summaries', name: 'Book summaries', disabled: props.editing },
    // { id: 'ebook', entity: 'ebooks', name: 'Ebooks', disabled: props.editing },
    // { id: 'english_books', name: 'English book', disabled: props.editing },
    // { id: 'kids_stories', name: 'Kids stories', disabled: props.editing },
    // { id: 'sleep_stories', name: 'Sleep stories', disabled: props.editing },
  ]

  const STATUS = [
    { id: 'draft', name: 'Draft' },
    { id: 'new', name: 'New' },
    { id: 'running', name: 'Running' },
    { id: 'completed', name: 'Completed' },
    { id: 'canceled', name: 'Canceled' },
    { id: 'expired', name: 'Expired' },
  ]

  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="title"
            fullWidth
            variant="standard"
            validate={[validateRequired('Type'), validateMaxLength]}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>

        <Grid item xs={4}>
          <DateInput
            source="startDate"
            rows={12}
            variant="standard"
            alwaysOn
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <DateInput
            source="endDate"
            rows={12}
            variant="standard"
            fullWidth
            alwaysOn
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Status"
            source="status"
            choices={STATUS}
            variant="standard"
            required
            fullWidth
            validate={validateRequired('Status')}
          />
        </Grid>

        <Grid item xs={12}>
          {renderImage ? (
            renderImage(props)
          ) : (
            <ImageInput
              source="imageUrl"
              label="Image"
              accept="image/*"
              multiple={false}
              validate={validateRequired('Image')}>
              <CustomImageField source="src" title="Image" canDownload />
            </ImageInput>
          )}
        </Grid>

        <ArrayInput
          label="Items"
          source="items"
          fullWidth
          validate={[
            validateRequired('Items'),
            // validateUnique('bookId', true),
          ]}>
          <SimpleFormIterator>
            <SelectInput
              label="Type"
              source="mediaType"
              choices={TYPES}
              variant="standard"
              required
              validate={validateRequired('Type')}
            />

            <FormDataConsumer>
              {({ scopedFormData }) => {
                if (!scopedFormData?.mediaType) {
                  return null
                }
                return (
                  <ReferenceInput
                    label={
                      TYPES.find(({ id }) => id === scopedFormData.mediaType)
                        .name
                    }
                    source={'bookId'}
                    reference={
                      TYPES.find(({ id }) => id === scopedFormData.mediaType)
                        .entity
                    }
                    filter={{
                      status: BOOK_STATUSES.PUBLISHED,
                      isFree: false,
                      membership_included: false,
                    }}>
                    <AutocompleteInput
                      optionText="title"
                      options={{
                        fullWidth: true,
                      }}
                      variant="standard"
                      filterToQuery={searchText => ({
                        title: { $iLike: `%${searchText}%` },
                        isFree: false,
                        membership_included: false,
                        status: BOOK_STATUSES.PUBLISHED,
                      })}
                    />
                  </ReferenceInput>
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </GridContainer>
    </SimpleForm>
  )
}

export default BundleForm
