import { Create } from 'react-admin'

import MeditationBackgroundForm from './MeditationBackgroundForm'

const MeditationBackgroundCreate = props => (
  <Create redirect="list" {...props}>
    <MeditationBackgroundForm />
  </Create>
)

export default MeditationBackgroundCreate
