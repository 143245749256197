import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceInput,
  DateInput,
  SelectInput,
  AutocompleteInput,
  useListContext,
} from 'react-admin'
import moment from 'moment'

import FilterComponent from '../../components/Filter'

const CustomFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <ReferenceInput
        label="Collection"
        source="collectionCodeId"
        reference="collection-codes"
        alwaysOn>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="Filter"
        source="date"
        choices={[
          { id: 'all', name: 'All' },
          { id: 'this_month', name: 'This month' },
          { id: 'last_month', name: 'Last month' },
          { id: 'custom', name: 'Choose date' },
        ]}
        alwaysOn></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const CollectionCodeTransactionList = props => {
  return (
    <List
      {...props}
      filters={<CustomFilter />}
      filterDefaultValues={{
        date: 'all',
        fromDate: moment()
          .subtract(7, 'days')
          .startOf('day')
          .format('MM-DD-YYYY'),
        toDate: moment().endOf('day').format('MM-DD-YYYY'),
      }}
      sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false} {...props}>
        <TextField source="id" />
        <TextField source="productName" />
        <NumberField source="amount" label="Price" />
        <DateField source="createdAt" label="Purchased at" />
        <TextField source="provider" label="Payment method" />
      </Datagrid>
    </List>
  )
}

export default CollectionCodeTransactionList
