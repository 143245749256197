import { ReferenceField, TextField, useRecordContext } from 'react-admin'

const CustomTextField = props => {
  const { source, reference, label, referenceField } = props
  const record = useRecordContext()
  if (!record[source]) return <span>{'-'}</span>

  return (
    <ReferenceField
      source={source}
      reference={reference}
      label={label}
      {...props}>
      <TextField source={referenceField} />
    </ReferenceField>
  )
}

export default CustomTextField
