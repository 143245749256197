import {
  DeleteButton,
  DeleteWithUndoButton,
  usePermissions,
  useRecordContext,
} from 'react-admin'

import { ROLES } from './constants'

const DeleteBtn = ({ undoable = false }) => {
  const { permissions } = usePermissions()
  const record = useRecordContext()

  if (!record || permissions !== ROLES.ADMIN) return null
  return undoable ? (
    <DeleteButton label="Delete" record={record} />
  ) : (
    <DeleteWithUndoButton label="Delete" record={record} />
  )
}

export default DeleteBtn
