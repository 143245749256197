import { Edit } from 'react-admin'

import NewsFeedForm from './NewsFeedForm'

const NewsFeedEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <NewsFeedForm />
    </Edit>
  )
}

export default NewsFeedEdit
