import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DateField,
  usePermissions,
} from 'react-admin'

import DeleteButton from '../../components/DeleteButton'

const MeditationBackgroundList = ({ ...props }) => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="who" />
        <TextField source="quote" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <EditButton />
        <DeleteButton permissions={permissions} />
      </Datagrid>
    </List>
  )
}

export default MeditationBackgroundList
