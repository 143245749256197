import { Edit } from 'react-admin'

import B2BOrganizationForm from './B2BOrganizationContentForm'

const B2BOrganizationContentEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <B2BOrganizationForm editing />
    </Edit>
  )
}

export default B2BOrganizationContentEdit
