import { Create } from 'react-admin'

import NewsFeedForm from './NewsFeedForm'

const NewsFeedCreate = props => (
  <Create redirect="list" {...props}>
    <NewsFeedForm />
  </Create>
)

export default NewsFeedCreate
