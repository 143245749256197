import { useDataProvider, useNotify, useRedirect } from 'react-admin'

const useUpdateDataProvider = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()

  return async (resource, data, options = {}) => {
    if (!resource) {
      throw new Error('useCustomDataProvider: missing resource')
    }

    if (!data) {
      throw new Error('useCustomDataProvider: missing data')
    }

    try {
      const res = await dataProvider.update(resource, data)

      if (options?.onSuccess) {
        notify(
          options.onSuccess?.notification?.body || 'Updated successfully',
          {
            type: options.onSuccess?.notification?.level || 'success',
          },
        )
      }

      if (options?.onSuccess?.redirectTo) {
        redirect(options.onSuccess.redirectTo || `/${resource}`)
      }

      return res
    } catch (e) {
      if (options.onFailure) {
        notify(
          options.onFailure?.notification?.body ||
            `${resource} not updated` + e?.message,
          {
            type: options.onFailure?.notification?.level || 'error',
          },
        )
      }
    }
  }
}

export default useUpdateDataProvider
