import { useMemo, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { Switch, FormControlLabel } from '@mui/material'
import debounce from 'lodash/debounce'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'

const ListStatusField = props => {
  const { resource } = props
  const updateDataProvider = useUpdateDataProvider()
  const record = useRecordContext()
  const [status, setStatus] = useState(record.status)

  const onChange = useMemo(() => {
    return debounce(
      status =>
        updateDataProvider(
          resource,
          { id: record.id, data: { status } },
          {
            onSuccess: {
              notification: { body: 'Update successfully', level: 'info' },
              redirectTo: `/${resource}`,
            },
            onFailure: {
              notification: {
                body: `Error: ${resource} not updated`,
                level: 'warning',
              },
            },
          },
        ),
      500,
    )
  }, [record.id, resource, updateDataProvider])

  const handleChange = () => {
    setStatus(prev => {
      onChange(!prev)
      return !prev
    })
  }

  return (
    <FormControlLabel
      control={<Switch checked={status} onChange={handleChange} />}
    />
  )
}

export default ListStatusField
