import {
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin'
import diff from 'object-diff'
import { paramsForServer } from 'feathers-hooks-common'

const defaultIdKey = 'id'

// const sanitizeData = data => {
//   return Object.keys(data).reduce((acc, key) => {
//     if (Array.isArray(data[key])) {
//       acc[key] = data[key].map(({ id }) => id)
//       return acc
//     }

//     acc[key] = data[key]
//     return acc
//   }, {})
// }

function getIdKey({ resource, options }) {
  return (
    (options[resource] && options[resource].id) || options.id || defaultIdKey
  )
}

function deleteProp(obj, prop) {
  let res = Object.assign({}, obj)
  delete res[prop]
  return res
}

export default (client, options = {}) => {
  const usePatch = !!options.usePatch
  const mapRequest = (type, resource, params) => {
    const idKey = getIdKey({ resource, options })

    const service = client.service(resource)
    const query = {}

    switch (type) {
      case GET_MANY: {
        const ids = params.ids

        query[idKey] = { $in: ids }
        query.$limit = ids.length

        return service.find(paramsForServer({ query, $skipCacheHook: true }))
      }
      case GET_MANY_REFERENCE:
        if (params.target && params.id) {
          query[params.target] = params.id
        }
        break
      case GET_LIST: {
        const { page, perPage } = params.pagination || {}
        const { field, order } = params.sort || {}

        if (perPage && page) {
          query.$limit = perPage
          query.$skip = perPage * (page - 1)
        }
        if (order) {
          query.$sort = {
            [field === defaultIdKey ? idKey : field]: order === 'DESC' ? -1 : 1,
          }
        }

        if (params.filter.title && typeof params.filter.title !== 'object') {
          params.filter.title = { $iLike: `%${params.filter.title}%` }
        }
        if (params.filter.name && typeof params.filter.name !== 'object') {
          params.filter.name = { $iLike: `%${params.filter.name}%` }
        }
        if (
          params.filter.username &&
          typeof params.filter.username !== 'object'
        ) {
          params.filter.username = { $iLike: `%${params.filter.username}%` }
        }

        Object.assign(query, params.filter)

        return service.find(paramsForServer({ query, $skipCacheHook: true }))
      }
      case GET_ONE:
        return service.get(
          params.id,
          paramsForServer({ query: params, $skipCacheHook: true }),
        )
      case UPDATE:
        if (usePatch) {
          const data = params.previousData
            ? diff(params.previousData, params.data)
            : params.data
          return service.patch(params.id, data)
        } else {
          const data =
            idKey !== defaultIdKey
              ? deleteProp(params.data, defaultIdKey)
              : params.data
          return service.update(params.id, data)
        }
      case UPDATE_MANY:
        if (usePatch) {
          const data = params.previousData
            ? diff(params.previousData, params.data)
            : params.data
          return Promise.all(params.ids.map(id => service.patch(id, data)))
        } else {
          const data =
            idKey !== defaultIdKey
              ? deleteProp(params.data, defaultIdKey)
              : params.data
          return Promise.all(params.ids.map(id => service.update(id, data)))
        }
      case CREATE:
        return service.create(params.data)
      case DELETE:
        return service.remove(params.id)
      case DELETE_MANY:
        if (service.options.multi) {
          return service.remove(null, {
            query: {
              [idKey]: {
                $in: params.ids,
              },
            },
          })
        }
        return Promise.all(params.ids.map(id => service.remove(id)))
      default:
        return Promise.reject(
          `Unsupported FeathersJS restClient action type ${type}`,
        )
    }
  }

  const mapResponse = (response, type, resource, params) => {
    const idKey = getIdKey({ resource, options })
    switch (type) {
      case GET_ONE:
        return { data: { ...response, id: response[idKey] } }
      case UPDATE:
      case DELETE:
        return { data: { ...response, id: response[idKey] } }
      case UPDATE_MANY:
      case DELETE_MANY:
        return { data: response.map(record => record[idKey]) }
      case CREATE:
        return { data: { ...params.data, ...response, id: response[idKey] } }
      case GET_MANY_REFERENCE: // fix GET_MANY_REFERENCE missing id
      case GET_MANY: // fix GET_MANY missing id
      case GET_LIST: {
        let res
        // support paginated and non paginated services
        if (!response.data) {
          response.total = response.length
          res = response
        } else {
          res = response.data
        }
        response.data = res.map(_item => {
          const item = _item
          if (idKey !== defaultIdKey) {
            item.id = _item[idKey]
          }
          return _item
        })
        // if (type === GET_LIST) {
        //   response.data = res.map(sanitizeData)
        //   console.log('TCL: mapResponse -> response.data', response.data)
        // }
        return response
      }
      default:
        return response
    }
  }

  return (type, resource, params) =>
    mapRequest(type, resource, params).then(response =>
      mapResponse(response, type, resource, params),
    )
}
