import { Fragment, useCallback, useState, useEffect } from 'react'
import {
  List,
  SelectInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  DateField,
  FunctionField,
  useListContext,
  usePermissions,
  useDataProvider,
} from 'react-admin'
import moment from 'moment'
import { Button, Grid } from '@mui/material'

import FilterComponent from '../../components/Filter'
import { FILTER_DATE, ROLES } from '../../components/constants'
import { formatCurrency } from '../../utils/formatCurrency'
import { exportExcel } from '../../utils/exportExcel'

const SUBSCRIPTION_PRICE = {
  MONTHLY_SUBSCRIPTION: 99000,
  QUARTERLY_SUBSCRIPTION: 249000,
  YEARLY_SUBSCRIPTION: 899000,
}

const PAYMENT_TYPE = {
  ONE_TIME: 'One time',
  SUBSCRIPTION: 'Subscription',
}

const exporter = records => {
  const data = records.map(record => {
    const { commission, productType } = record
    const amountCommission =
      (commission / 100) * SUBSCRIPTION_PRICE[productType] * 0.9

    return {
      Code: record.code,
      ProductName: record.productName,
      PaymentType: PAYMENT_TYPE[record.paymentType],
      PriceAfterDiscount: record.amount,
      Discount: `${record.discount}%`,
      Commission: `${record.commission}%`,
      CommissionAmount: amountCommission,
      PurchaseAt: moment(record.purchaseAt).format('DD/MM/YYYY HH:mm:ss'),
    }
  })

  exportExcel({
    data,
    title: `affiliate_web_orders_${moment().format('MM_DD_YYYY')}`,
    type: 'xlsx',
  })
}

const CustomFilter = props => {
  const { filterValues } = useListContext()
  const { permissions } = usePermissions()
  return (
    <FilterComponent {...props}>
      {permissions !== ROLES.AFFILIATE && (
        <ReferenceInput
          label="Affiliate"
          source="affiliateId"
          reference="users"
          filter={{ role: ROLES.AFFILIATE }}
          alwaysOn>
          <AutocompleteInput
            optionText="name"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
              role: ROLES.AFFILIATE,
            })}
          />
        </ReferenceInput>
      )}
      <SelectInput
        label="Filter"
        source="date"
        choices={FILTER_DATE}
        alwaysOn></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const CustomDatagrid = props => {
  const dataProvider = useDataProvider()

  const [isShowRevenue, toggleShowRevenue] = useState(true)

  const [state, setState] = useState({
    totalCommission: 0,
  })

  const CommissionAmountField = useCallback(({ record = {} }) => {
    const { commission, productType } = record
    let amountCommission =
      (commission / 100) * SUBSCRIPTION_PRICE[productType] * 0.9
    return <span>{formatCurrency.format(amountCommission.toFixed(0))}</span>
  }, [])

  const { filterValues } = useListContext()
  useEffect(() => {
    dataProvider
      .getOne('affiliate-payment-order-revenue', {
        id: null,
        date: filterValues && filterValues.date,
        affiliateId: filterValues && filterValues.affiliateId,
        fromDate: filterValues && filterValues.fromDate,
        toDate: filterValues && filterValues.toDate,
      })
      .then(({ data }) => {
        setState(v => ({ ...v, totalCommission: data.totalCommission }))
      })
      .catch(console.log)
  }, [dataProvider, filterValues])

  return (
    <Fragment>
      <div style={{ marginLeft: 12 }}>
        <Grid container>
          {state.affiliate && (
            <Grid item xs={12}>
              <h3>Name: {state.affiliate.name}</h3>
              <h3>Web link: {state.affiliate.webLink}</h3>
            </Grid>
          )}
          {isShowRevenue ? (
            <>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  onClick={() => toggleShowRevenue(false)}>
                  Hide details
                </Button>
                <h3>
                  Total commission:{' '}
                  {formatCurrency.format(state.totalCommission)}
                </h3>
              </Grid>
              <Datagrid bulkActionButtons={false} rowClick={false} {...props}>
                <TextField source="code" />
                <TextField source="productName" />
                <FunctionField
                  label="Payment type"
                  render={record => PAYMENT_TYPE[record.paymentType]}
                />
                <FunctionField
                  label="Discount"
                  render={record => `${record.discount}%`}
                />
                <NumberField source="amount" label="Price (after discount)" />
                <FunctionField
                  label="Commission"
                  render={record => `${record.commission}%`}
                />
                <CommissionAmountField
                  source="amount"
                  label="Commission amount"
                />
                <DateField source="createdAt" label="Purchased at" />
              </Datagrid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button color="primary" onClick={() => toggleShowRevenue(true)}>
                Show details
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Fragment>
  )
}

const AffiliateWebOrderList = props => {
  return (
    <List
      filters={<CustomFilter />}
      {...props}
      filterDefaultValues={{
        date: 'all',
      }}
      exporter={exporter}>
      <CustomDatagrid />
    </List>
  )
}

export default AffiliateWebOrderList
