import { useCallback, useEffect, useState } from 'react'
import {
  SimpleForm,
  TextInput,
  minValue,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'
import { useFormContext, useWatch } from 'react-hook-form'

import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

export const TYPE_CREDITS = [
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'annually',
    name: 'Annually',
  },
  {
    id: 'bundle_3_v2',
    name: 'Bundle 3-credit',
  },
  {
    id: 'bundle_5_v2',
    name: 'Bundle 5-credit',
  },
  {
    id: 'bundle_10_v2',
    name: 'Bundle 10-credit',
  },
  {
    id: 'free',
    name: 'Free',
  },
]

const PresetInput = props => {
  const preset = useWatch({ name: 'preset' })
  const { setValue } = useFormContext()

  useEffect(() => {
    if (!preset) return
    switch (preset) {
      case 'bundle_3_v2':
        setValue('type', 'bundle_3_v2')
        setValue('credits', 3)
        break
      case 'bundle_5_v2':
        setValue('type', 'bundle_5_v2')
        setValue('credits', 5)
        break
      case 'bundle_10_v2':
        setValue('type', 'bundle_10_v2')
        setValue('credits', 10)
        break
      default:
        setValue('type', undefined)
        setValue('credits', undefined)
        break
    }
  }, [preset, setValue])

  return <SelectInput {...props}></SelectInput>
}

const UserCreditsForm = props => {
  const [shouldDisableCreditSelector, setShouldDisableCreditSelector] =
    useState(false)

  const onPresetInputChange = useCallback(e => {
    setShouldDisableCreditSelector(e.target.value && e.target.value !== 'other')
  }, [])

  return (
    <SimpleForm {...props}>
      <GridContainer>
        <Grid item xs={6}>
          <ReferenceInput label="Username" source="userId" reference="users">
            <AutocompleteInput
              optionText="username"
              variant="standard"
              options={{
                fullWidth: true,
              }}
              validate={validateRequired('Username')}
              filterToQuery={searchText => ({
                username: { $iLike: `%${searchText}%` },
              })}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <PresetInput
            onChange={onPresetInputChange}
            label="Presets"
            source="preset"
            choices={[
              { id: 'bundle_3_v2', name: 'Bundle 3-credit' },
              { id: 'bundle_5_v2', name: 'Bundle 5-credit' },
              { id: 'bundle_10_v2', name: 'Bundle 10-credit' },
              { id: 'other', name: 'Other' },
            ]}
            fullWidth
            alwaysOn
            allowEmpty={false}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            source="credits"
            required
            fullWidth
            variant="standard"
            readOnly={shouldDisableCreditSelector}
            validate={[validateRequired('Credits'), minValue(1)]}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Type credit"
            source="type"
            choices={TYPE_CREDITS}
            alwaysOn
            fullWidth
            required
            readOnly={shouldDisableCreditSelector}
            validate={validateRequired('Type')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="reason"
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Reason')}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default UserCreditsForm
