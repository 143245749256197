import { Edit } from 'react-admin'

import MeditationQuoteForm from './MeditationQuoteForm'

const MeditationQuoteEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <MeditationQuoteForm />
    </Edit>
  )
}

export default MeditationQuoteEdit
