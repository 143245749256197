import {
  List,
  Datagrid,
  EditButton,
  DateField,
  TextField,
  NumberField,
} from 'react-admin'

const PromotionList = ({ ...props }) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField label="Id" source="id" />
        <TextField label="Title" source="bannerInfo.title" />
        <DateField label="Date start" source="dateStart" />
        <NumberField label="Days run promotion" source="amountDays" />
        <TextField label="Status" source="enable" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default PromotionList
