import { Fragment } from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  minValue,
  NumberInput,
  ReferenceInput,
  FormDataConsumer,
  AutocompleteInput,
  DateInput,
  usePermissions,
} from 'react-admin'
import Grid from '@mui/material/Grid'
import moment from 'moment'

import { validateRequired } from '../../utils/validate'
import { CODE_TYPES, CODE_SOURCE, ROLES } from '../../components/constants'
import GridContainer from '../../components/GridContainer'

const BatchCodesForm = () => {
  const { permissions } = usePermissions()

  return (
    <SimpleForm redirect="list">
      <GridContainer>
        <Grid item xs={6}>
          <TextInput
            label="Batch ID"
            source="batchId"
            required
            fullWidth
            variant="standard"
            validate={[validateRequired('Batch ID')]}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            label="Quantity"
            source="quantity"
            fullWidth
            variant="standard"
            required
            validate={[validateRequired('Quantity'), minValue(1)]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Type"
            source="type"
            choices={CODE_TYPES}
            fullWidth
            variant="standard"
          />
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            if (!formData) return null

            switch (formData.type) {
              case 'book':
                return (
                  <Fragment>
                    <Grid item xs={8}>
                      <ReferenceInput
                        label="Book"
                        source="bookId"
                        reference="books">
                        <AutocompleteInput
                          optionText="title"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                          filterToQuery={searchText => ({
                            title: { $iLike: `%${searchText}%` },
                          })}
                        />
                      </ReferenceInput>
                    </Grid>
                  </Fragment>
                )

              case 'collection':
                return (
                  <Fragment>
                    <Grid item xs={8}>
                      <ReferenceInput
                        label="Collection"
                        source="collectionCodeId"
                        reference="collection-codes">
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                          filterToQuery={searchText => ({
                            name: { $iLike: `%${searchText}%` },
                          })}
                        />
                      </ReferenceInput>
                    </Grid>
                  </Fragment>
                )
              default:
                return null
            }
          }}
        </FormDataConsumer>
        <Grid item xs={4}>
          <SelectInput
            source="source"
            choices={CODE_SOURCE}
            defaultValue="web"
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Source')}
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            label="Credit actual price"
            source="codeActualPrice"
            fullWidth
            variant="standard"
          />
        </Grid>

        {permissions === ROLES.ADMIN && (
          <Grid item xs={4}>
            <SelectInput
              source="status"
              choices={[
                { id: true, name: 'Active' },
                { id: false, name: 'Inactive' },
              ]}
              fullWidth
              variant="standard"
              required
              defaultValue={true}
            />
          </Grid>
        )}

        <Grid item xs={permissions === ROLES.ADMIN ? 4 : 8}>
          <DateInput
            source="expiredAt"
            fullWidth
            variant="standard"
            required
            validate={[
              validateRequired('Expired at'),
              minValue(
                moment().format('YYYY-MM-DD'),
                'Expired at must be greater than today',
              ),
            ]}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default BatchCodesForm
