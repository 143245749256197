import { Edit } from 'react-admin'

import UserCreditsForm from './UserCreditsForm'

const UserCreditsEdit = props => (
  <Edit undoable={false} {...props}>
    <UserCreditsForm />
  </Edit>
)

export default UserCreditsEdit
