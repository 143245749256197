import { useCallback } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  SelectInput,
  NumberField,
  FunctionField,
  DateField,
  Button,
  DateInput,
  useListContext,
  useRecordContext,
  usePermissions,
} from 'react-admin'
import moment from 'moment'

import { exportExcel } from '../../utils/exportExcel'
import CustomSwitchField from '../../components/CustomSwitchField'
import CustomTextField from '../../components/CustomTextField'
import { CODE_TYPES, CODE_TYPE_NAME, ROLES } from '../../components/constants'
import FilterComponent from '../../components/Filter'
import ApproveStatus from '../../components/ApproveAction/ApproveStatus'
import ApproveButtons from '../../components/ApproveAction/ApproveButtons'

const ExportBatchCodes = props => {
  const record = useRecordContext()

  const onExport = useCallback(() => {
    const data = record.codes.map(record => {
      return {
        Id: record.id,
        'Created at':
          record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
        Code: record.code,
        Type: record.type,
        Book: record.book && record.book.title,
        BookId: record.bookId,
        BatchId: record.batch_code && record.batch_code.batchId,
        Source: record.source,
        'Payment method': record.paymentMethod,
        'Invoice email': record.invoiceEmail,
        'Redeemed at':
          record.redeemedAt && moment(record.redeemedAt).format('DD/MM/YYYY'),
        'Redeemed user': record.redeemedUser && record.redeemedUser.username,
      }
    })

    exportExcel({
      data,
      title: `Batch_code_${moment().format('MM_DD_YYYY')}`,
      type: 'xlsx',
    })
  }, [record.codes])

  return <Button onClick={onExport} label="Export" />
}

const getCodePrice = record => {
  switch (record.type) {
    case 'book':
      return record.book ? record.book.price : 0

    case 'one_month_subscription':
      return 99000

    case 'one_year_subscription':
      return 899000

    case 'three_month_subscription':
      return 249000

    case 'three_year_subscription':
      return 1799000

    case 'collection':
      return record.collection_code.price
    default:
      return 0
  }
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      'Batch Id': record.batchId,
      Quantity: record.quantity,
      Type: CODE_TYPE_NAME[record.type],
      Book: record.book && record.book.title,
      BookId: record.bookId,
      Price: getCodePrice(record) * record.quantity,
      'Created by': record.createdUser && record.createdUser.name,
      'Created at':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      'Expired at':
        record.expiredAt && moment(record.expiredAt).format('DD/MM/YYYY'),
      Redeemed: record.redeemed,
      'Redeemed (New users)': record.newUsersRedeemed,
      Status: record.status,
    }
  })

  exportExcel({
    data,
    title: `Batch_codes_${moment().format('MM_DD_YYYY')}`,
    type: 'xlsx',
  })
}

const CustomFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <TextInput label="Batch ID" source="batchId" alwaysOn />
      <SelectInput source="type" choices={CODE_TYPES} alwaysOn />
      <SelectInput
        label="Filter"
        source="date"
        choices={[
          { id: 'all', name: 'All' },
          { id: 'this_month', name: 'This month' },
          { id: 'last_month', name: 'Last month' },
          { id: 'custom', name: 'Choose date' },
        ]}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const BatchCodesList = props => {
  const { permissions } = usePermissions()

  return (
    <List
      {...props}
      filters={<CustomFilter />}
      title="Batch codes"
      exporter={exporter}
      sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="batchId" label="Batch ID" />
        <TextField source="quantity" />
        <FunctionField
          source="type"
          render={record => CODE_TYPE_NAME[record.type]}
        />
        <CustomTextField
          label="Book"
          source="bookId"
          reference="books"
          referenceField="title"
          {...props}
        />
        <NumberField source="redeemed" />
        <NumberField
          source="newUsersRedeemed"
          sortable={false}
          label="Redeemed (New users)"
        />
        <DateField source="expiredAt" />

        <DateField source="createdAt" />
        <DateField source="updatedAt" />

        <TextField label="Created By" source="createdUser.name" />
        <TextField label="Updated By" source="updatedUser.name" />

        <CustomSwitchField
          resource="batch-codes"
          label="Status"
          switchField="status"
          disabled={record =>
            ![
              ROLES.ADMIN,
              ROLES.CPSO,
              ROLES.MARKETING_MANAGER,
              ROLES.STATISTICAL_STAFF,
              ROLES.HEAD_OF_CUSTOMER_SERVICE,
            ].includes(permissions) || record.approveStatus !== 'approved'
          }
        />
        <ApproveStatus label="Status" statusField="approveStatus" />
        <ApproveButtons
          permissions={permissions}
          service="batch-codes"
          statusField="approveStatus"
        />
        <EditButton />
        <ExportBatchCodes />
      </Datagrid>
    </List>
  )
}

export default BatchCodesList
