import { Edit } from 'react-admin'

import BundleForm from './BundleForm'

const BundleEdit = props => {
  return (
    <Edit mutationMode="undoable" {...props}>
      <BundleForm permissions={props.permissions} />
    </Edit>
  )
}

export default BundleEdit
