import {
  Datagrid,
  DateField,
  EditButton,
  List,
  TextField,
  TextInput,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const RoleFilter = props => {
  return (
    <FilterComponent {...props}>
      <TextInput label="Code" source="id" alwaysOn />
    </FilterComponent>
  )
}

const RoleList = props => {
  return (
    <List
      {...props}
      filters={<RoleFilter permissions={props.permissions} />}
      sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="name" />
        <TextField source="id" label="Code" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default RoleList
