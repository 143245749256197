import { Edit } from 'react-admin'

import CategoryGroupForm from './CategoryGroupForm'

const CategoryGroupEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <CategoryGroupForm editing />
    </Edit>
  )
}

export default CategoryGroupEdit
