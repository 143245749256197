import { Create } from 'react-admin'

import MarketingCampaignForm from './MarketingCampaignForm'

const MarketingCampaignCreate = props => (
  <Create redirect="list" {...props}>
    <MarketingCampaignForm />
  </Create>
)

export default MarketingCampaignCreate
