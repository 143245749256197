import {
  List,
  SelectInput,
  DateInput,
  TabbedShowLayout,
  Tab,
  SimpleShowLayout,
  TextField,
  useListContext,
  Loading,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import FilterComponent from '../../components/Filter'
import { FILTER_DATE } from '../../components/constants'

const CustomFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <SelectInput
        label="Filter"
        source="date"
        choices={FILTER_DATE}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const CustomDatagrid = props => {
  const { basePath, resource } = props
  const { data, isLoading } = useListContext()
  const user = data?.find(item => item.id === 'user')
  const subscription = data?.find(item => item.id === 'subscription')
  const content = data?.find(item => item.id === 'content')
  return isLoading ? (
    <Loading />
  ) : (
    <TabbedShowLayout
      {...{
        basePath,
        resource,
        record: {
          user: user,
          subscription: subscription,
          content: content,
        },
      }}>
      <Tab label="User">
        <Grid container>
          <Grid xs={6} item>
            <SimpleShowLayout>
              <TextField label="New users" source="user.data.newUsers" />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={6} item>
            <SimpleShowLayout>
              <TextField label="Total users" source="user.data.totalUsers" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Tab>
      <Tab label="Subscription">
        <Grid container>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Active month subscriptions"
                source="subscription.data.monthActive"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Active annual subscriptions"
                source="subscription.data.annualActive"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Total active subscriptions"
                source="subscription.data.totalActive"
              />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Tab>
      <Tab label="Content">
        <Grid container>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="New audiobooks"
                source="content.data.newBooks"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Total audiobooks"
                source="content.data.totalBooks"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="New book summaries"
                source="content.data.newBookSummaries"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Total book summaries"
                source="content.data.totalBookSummaries"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="New meditations"
                source="content.data.newMeditations"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Total meditations"
                source="content.data.totalMeditations"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="New sleep stories"
                source="content.data.newSleepStories"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Total sleep stories"
                source="content.data.totalSleepStories"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField label="New musics" source="content.data.newMusics" />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Total musics"
                source="content.data.totalMusics"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="New kids stories"
                source="content.data.newKidsStories"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid xs={4} item>
            <SimpleShowLayout>
              <TextField
                label="Total kids stories"
                source="content.data.totalKidsStories"
              />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Tab>
    </TabbedShowLayout>
  )
}

const KpiDashboard = props => (
  <List
    filters={<CustomFilter />}
    {...props}
    exporter={false}
    filterDefaultValues={{
      date: 'all',
    }}
    pagination={null}>
    <CustomDatagrid pros={useListContext} />
  </List>
)

export default KpiDashboard
