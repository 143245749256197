import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  DeleteButton,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const ArticleFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const B2BTrialUserList = props => (
  <List
    {...props}
    filters={<ArticleFilter />}
    bulkActionButtons={false}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={true}>
      <TextField source="id" label="ID" />
      <TextField source="organization.id" label="Organization ID" />
      <TextField source="email" />
      <DateField source="createdAt" />
      {/*<EditButton basePath="/b2b-trial-users" />*/}
      <DeleteButton basePath="/b2b-trial-users" />
    </Datagrid>
  </List>
)

export default B2BTrialUserList
