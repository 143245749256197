import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const SUBSCRIPTION_TYPE = [
  { id: 'month', name: 'Month' },
  { id: 'annual', name: 'Annual' },
]

const CsUserSubscriptionCreate = props => {
  return (
    <Create redirect="list" undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={6}>
            <ReferenceInput label="Username" source="userId" reference="users">
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
                validate={validateRequired('user')}
                filterToQuery={searchText => ({
                  username: { $iLike: `%${searchText}%` },
                })}
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              label="Type"
              source="type"
              choices={SUBSCRIPTION_TYPE}
              fullWidth
              variant="standard"
              allowEmpty={false}
              defaultValue="month"
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="reason"
              fullWidth
              variant="standard"
              required
              validate={validateRequired('Reason')}
            />
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default CsUserSubscriptionCreate
