import { useMemo } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
  NumberField,
  ReferenceInput,
  SelectInput,
  usePermissions,
} from 'react-admin'

import { ROLES } from '../../components/constants'
import FilterComponent from '../../components/Filter'

const AffiliatePaymentsFilter = props => (
  <FilterComponent {...props}>
    <ReferenceInput
      label="Affiliate"
      source="affiliateId"
      reference="users"
      filter={{ role: 'affiliate' }}
      alwaysOn>
      <SelectInput
        optionText="name"
        options={{
          fullWidth: true,
        }}
      />
    </ReferenceInput>
  </FilterComponent>
)

const AffiliatePaymentsList = () => {
  const { permissions } = usePermissions()

  const hasPermission = useMemo(
    () => permissions === ROLES.ADMIN || permissions === ROLES.ACCOUNTANT,
    [permissions],
  )

  return (
    <List
      filters={<AffiliatePaymentsFilter />}
      hasCreate={hasPermission}
      sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <ReferenceField
          label="Affiliate"
          source="affiliateId"
          reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="transferDate" />
        <NumberField source="amount" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        {hasPermission && <EditButton />}
        {hasPermission && <DeleteButton />}
      </Datagrid>
    </List>
  )
}

export default AffiliatePaymentsList
