import type { Components } from '@mui/material/styles'

import type { Theme } from '../types'

export const MuiTablePagination = {
  defaultProps: {
    slotProps: {
      // actions: { nextButtonIcon: { component: NextButtonIcon }, previousButtonIcon: { component: PreviousButtonIcon } },
      select: { size: 'small', variant: 'outlined' },
    },
  },
  styleOverrides: { input: { marginRight: '16px', padding: 0 } },
} satisfies Components<Theme>['MuiTablePagination']
