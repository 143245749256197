import { Edit } from 'react-admin'

import ReferralBookForm from './ReferralBookForm'

const ReferralBookEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <ReferralBookForm editing />
    </Edit>
  )
}

export default ReferralBookEdit
