import uploadFileCapable from './uploadFileCapable'
import { restClient } from './provider'
import feathersClient from './feathersClient'

const restClientOptions = {
  id: 'id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true, // Use PATCH instead of PUT for updates
}

export default () =>
  uploadFileCapable(restClient(feathersClient.get(), restClientOptions))
