import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  SelectInput,
  TextInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const OBJECT_TYPE = [
  { id: 'book', name: 'Book' },
  { id: 'ebook', name: 'Ebook' },
]

export const PURCHASE_TYPES = [
  {
    id: 'free',
    name: 'Free',
  },
  {
    id: 'direct',
    name: 'Direct',
  },
  {
    id: 'credit',
    name: 'Credit',
  },
  {
    id: 'code',
    name: 'Code',
  },
  {
    id: 'membership_inlcuded',
    name: 'Membership Included',
  },
  {
    id: 'manual',
    name: 'Manual',
  },
]
export const TYPE_CREDITS = [
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'annually',
    name: 'Annually',
  },
  {
    id: 'bundle_3_v2',
    name: 'Bundle 3',
  },
  {
    id: 'bundle_5_v2',
    name: 'Bundle 5',
  },
  {
    id: 'bundle_10_v2',
    name: 'Bundle 10',
  },
  {
    id: 'free',
    name: 'Free',
  },
]

const CsUserBookCreate = props => {
  return (
    <Create redirect="list" undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={6}>
            <ReferenceInput label="Username" source="userId" reference="users">
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
                validate={validateRequired('user')}
                filterToQuery={searchText => ({
                  username: { $iLike: `%${searchText}%` },
                })}
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              label="Type"
              source="type"
              choices={OBJECT_TYPE}
              fullWidth
              variant="standard"
              allowEmpty={false}
              defaultValue="book"
            />
          </Grid>

          <FormDataConsumer>
            {({ formData }) => {
              if (!formData) return null

              switch (formData.type) {
                case 'book':
                  return (
                    <Grid item xs={12}>
                      <ReferenceInput
                        label="Book"
                        source="bookId"
                        reference="books">
                        <AutocompleteInput
                          optionText="title"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                          filterToQuery={searchText => ({
                            title: { $iLike: `%${searchText}%` },
                          })}
                        />
                      </ReferenceInput>
                    </Grid>
                  )
                case 'ebook':
                  return (
                    <Grid item xs={12}>
                      <ReferenceInput
                        label="Ebook"
                        source="ebookId"
                        reference="ebooks">
                        <AutocompleteInput
                          optionText="title"
                          options={{
                            fullWidth: true,
                          }}
                          variant="standard"
                          filterToQuery={searchText => ({
                            title: { $iLike: `%${searchText}%` },
                          })}
                        />
                      </ReferenceInput>
                    </Grid>
                  )

                default:
                  return null
              }
            }}
          </FormDataConsumer>

          <Grid item xs={12}>
            <SelectInput
              label="Purchase type"
              source="purchaseType"
              choices={PURCHASE_TYPES}
              alwaysOn
              fullWidth
              required
              validate={validateRequired('Purchase type')}
            />
          </Grid>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData) return null

              switch (formData.purchaseType) {
                case 'credit':
                  return (
                    <Grid item xs={12}>
                      <SelectInput
                        label="Type credit"
                        source="creditType"
                        choices={TYPE_CREDITS}
                        alwaysOn
                        required
                        fullWidth
                        validate={validateRequired('Type credit')}
                      />
                    </Grid>
                  )
                default:
                  return null
              }
            }}
          </FormDataConsumer>

          <Grid item xs={12}>
            <TextInput
              source="reason"
              fullWidth
              variant="standard"
              required
              validate={validateRequired('Reason')}
            />
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default CsUserBookCreate
