import { Box } from '@mui/material'
import { Notification } from 'react-admin'

import LoginForm from './LoginForm'
import { Logo } from './Logo'

const Login = () => {
  return (
    <Box
      sx={{
        background: '#121621',
      }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
          p: { xs: 2, md: 3 },
        }}>
        <Box sx={{ maxWidth: '560px', width: '100%' }}>
          <Box mb={2}>
            <Logo />
          </Box>
          <LoginForm />
          <Notification />
        </Box>
      </Box>
    </Box>
  )
}

export default Login
