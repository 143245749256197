import { Edit } from 'react-admin'

import CampaignForm from './CampaignForm'

const CampaignEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <CampaignForm permissions={props.permissions} />
    </Edit>
  )
}

export default CampaignEdit
