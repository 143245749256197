import {
  NumberInput,
  SimpleForm,
  SelectInput,
  DateInput,
  ReferenceInput,
  minValue,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const AffiliatePaymentsForm = props => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={8}>
          <ReferenceInput
            label="Affiliate"
            source="affiliateId"
            reference="users"
            filter={{ role: 'affiliate' }}
            alwaysOn>
            <SelectInput
              optionText="name"
              source="affiliateId"
              options={{
                fullWidth: true,
              }}
              required
              variant="standard"
              validate={validateRequired('Affiliate')}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <DateInput
            label="Date"
            source="transferDate"
            alwaysOn
            fullWidth
            validate={validateRequired('Date')}
            required
            variant="standard"></DateInput>
        </Grid>

        <Grid item xs={12}>
          <NumberInput
            label="Amount"
            source="amount"
            fullWidth
            required
            variant="standard"
            validate={[validateRequired('Date'), minValue(0)]}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default AffiliatePaymentsForm
