import type { Components } from '@mui/material/styles'

import type { Theme } from '../types'

export const MuiTableContainer = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: 'var(--mui-palette-background-level1)',
      padding: '4px',
      [theme.breakpoints.up('lg')]: {
        padding: '24px',
      },
    }),
  },
} satisfies Components<Theme>['MuiTableContainer']
