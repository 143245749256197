import { Create } from 'react-admin'

import ArticleForm from './ArticleForm'

const ArticleCreate = props => (
  <Create redirect="list" {...props}>
    <ArticleForm />
  </Create>
)

export default ArticleCreate
