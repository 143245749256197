'use client'
import '@fontsource/inter' // Defaults to weight 400
import '@fontsource/inter/400.css' // Specify weight
import '@fontsource/inter/400-italic.css' // Specify weight and style
import { ReactNode } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import InitColorSchemeScript from '@mui/material/InitColorSchemeScript'
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  StyledEngineProvider,
} from '@mui/material/styles'

import { createTheme } from './create-theme'

export interface ThemeProviderProps {
  children: (theme: any) => ReactNode
}

function ThemeProvider({ children }: ThemeProviderProps): React.JSX.Element {
  const { theme, extendedTheme } = createTheme()

  return (
    <>
      <InitColorSchemeScript attribute="class" />

      <StyledEngineProvider injectFirst>
        <CssVarsProvider theme={extendedTheme}>
          <CssBaseline />

          {children(theme)}
        </CssVarsProvider>
      </StyledEngineProvider>
    </>
  )
}

export default ThemeProvider
