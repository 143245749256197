import moment from 'moment'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  minValue,
  maxValue,
  DateInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  useGetOne,
} from 'react-admin'
import { useWatch } from 'react-hook-form'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'
import { ROLES } from '../../components/constants'

const discountTypes = [
  { id: 'percent', name: 'Percent' },
  { id: 'direct', name: 'Direct' },
]

const Commission = () => {
  const validateCommission = [minValue(0), maxValue(100)]

  const affiliateId = useWatch({ name: 'affiliateId' })
  const { data: user, isLoading } = useGetOne(
    'users',
    { id: affiliateId },
    {
      enabled: !!affiliateId,
    },
  )

  if (!affiliateId || isLoading) return null

  return (
    <NumberInput
      label="Commission (0-100)"
      source="commission"
      fullWidth
      variant="standard"
      defaultValue={user?.defaultCommission}
      validate={validateCommission}
    />
  )
}

const DiscountsForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={8}>
          <TextInput
            source="name"
            required
            fullWidth
            variant="standard"
            validate={validateRequired('name')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label="Promo code"
            source="code"
            required
            fullWidth
            variant="standard"
            validate={validateRequired('promo code')}
          />
        </Grid>
        <Grid item xs={8}>
          <ReferenceInput
            label="Affiliate"
            source="affiliateId"
            reference="users"
            filter={{ role: ROLES.AFFILIATE }}
            allowEmpty>
            <AutocompleteInput
              optionText="name"
              fullWidth
              required
              variant="standard"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                role: ROLES.AFFILIATE,
              })}
            />
          </ReferenceInput>
          <Commission />
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            label="Employee"
            source="employeeId"
            reference="users"
            filter={{ role: 'support-agent' }}
            alwaysOn
            allowEmpty>
            <SelectInput
              optionText="name"
              source="employeeId"
              options={{
                fullWidth: true,
              }}
              variant="standard"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            fullWidth
            label="Product type"
            source="productType"
            choices={[
              { id: 'MONTHLY_SUBSCRIPTION', name: 'Monthly' },
              { id: 'QUARTERLY_SUBSCRIPTION', name: 'Quaterly' },
              { id: 'YEARLY_SUBSCRIPTION', name: 'Yearly' },
              { id: 'BUNDLE_3_CREDITS', name: '3 Credits Bundle' },
              { id: 'BUNDLE_5_CREDITS', name: '5 Credits Bundle' },
              { id: 'BUNDLE_10_CREDITS', name: '10 Credits Bundle' },
              { id: 'MONTHLY_SUBSCRIPTION_PRO', name: 'Monthly Premium Pro' },
              { id: 'YEARLY_SUBSCRIPTION_PRO', name: 'Yearly Premium Pro' },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            source="discountType"
            choices={discountTypes}
            fullWidth
            variant="standard"
            required
            defaultValue={'percent'}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            label="Discount value"
            source="value"
            fullWidth
            variant="standard"
            required
            validate={[minValue(0), validateRequired('name')]}
          />
        </Grid>
        <Grid item xs={12}>
          <DateInput
            source="expiredAt"
            fullWidth
            variant="standard"
            required
            validate={[
              validateRequired('expired at'),
              minValue(
                moment().format('YYYY-MM-DD'),
                'Expired at must be greater than today',
              ),
            ]}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default DiscountsForm
