import { useState, useEffect, useCallback, useMemo } from 'react'
import { List, SelectInput, useListContext } from 'react-admin'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { CircularProgress } from '@mui/material'
import debounce from 'lodash/debounce'

import FilterComponent from '../../components/Filter'
import useUpdateDataProvider from '../../hooks/useUpdateDataProvider'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
  margin: '0 auto',
})

const Datagrid = props => {
  const [items, setItems] = useState([])
  const updateDataProvider = useUpdateDataProvider()
  const { data, filterValues } = useListContext()
  useEffect(() => {
    if (!items.length && data) {
      setItems(
        Object.keys(data)
          .filter(x => x !== 'undefined' && !!x)
          .map(key => data[key])
          .sort((a, b) => a.order - b.order),
      )
    }
  }, [items, data])

  useEffect(() => {
    if (data) {
      setItems(
        Object.keys(data)
          .filter(x => x !== 'undefined' && !!x)
          .map(key => data[key])
          .sort((a, b) => a.order - b.order),
      )
    }
  }, [data, filterValues.type])

  const updateOrderCategories = useMemo(
    () =>
      debounce(newItems => {
        updateDataProvider(
          'order-categories',
          { data: newItems },
          {
            onSuccess: {
              notification: { body: 'Order categories updated', level: 'info' },
            },
            onFailure: {
              notification: {
                body: 'Error: categories not updated',
                level: 'warning',
              },
            },
          },
        )
      }, 2000),
    [updateDataProvider],
  )

  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index,
      )

      setItems(newItems)
      updateOrderCategories(newItems)
    },
    [items, updateOrderCategories],
  )

  return props.loading ? (
    <CircularProgress />
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={String(item.id)}
                index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}>
                    {item.name}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const CustomFilter = props => (
  <FilterComponent {...props}>
    <SelectInput
      source="type"
      choices={[
        { id: 'book', name: 'Book' },
        { id: 'book_summary', name: 'Book summary' },
      ]}
      alwaysOn
    />
  </FilterComponent>
)

const CategoryList = props => (
  <List
    {...props}
    perPage={1000}
    pagination={null}
    filters={<CustomFilter />}
    filterDefaultValues={{ type: 'book' }}
    actions={null}>
    <Datagrid bulkActionButtons={false} rowClick={false} {...props} />
  </List>
)

export default CategoryList
