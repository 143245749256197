import { Card, CardContent } from '@mui/material'
import { usePermissions } from 'react-admin'
import { useLocation } from 'react-router-dom'

import { ROLES } from '../constants'

import DashboardAffiliate from './DashboardAffiliate'
import DashboardAdmin from './DashboardAdmin'
import DashboardAffiliateManager from './DashboardAffiliateManager'
import DashboardPublisher from './DashboardPublisher'

const Dashboard = props => {
  const { permissions } = usePermissions()
  const renderContent = () => {
    switch (permissions) {
      case ROLES.PUBLISHER: {
        return <DashboardPublisher {...props} />
      }
      case ROLES.ADMIN:
      case ROLES.ACCOUNTANT:
      case ROLES.STATISTICAL_STAFF:
      case ROLES.FINANCE_DIRECTOR: {
        return <DashboardAdmin {...props} />
      }

      case ROLES.AFFILIATE:
        return <DashboardAffiliate {...props} />

      case ROLES.AFFILIATE_MANAGER:
        return <DashboardAffiliateManager {...props} />

      default:
        return <CardContent>Welcome back to Fonos</CardContent>
    }
  }
  return <Card>{renderContent()}</Card>
}

export default ({ ...rest }) => {
  const location = useLocation()

  return <Dashboard location={location} {...rest} />
}
