import { Edit } from 'react-admin'

import CollectionForm from './ChallengeRewardForm'

const ChallengeRewardEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <CollectionForm editing />
    </Edit>
  )
}

export default ChallengeRewardEdit
