import { useState } from 'react'
import { useTranslate, useSafeSetState, useLogin, useNotify } from 'react-admin'
import { Field, Form } from 'react-final-form'
import CardActions from '@mui/material/CardActions'
import TextField from '@mui/material/TextField'
import { Card, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

import useInterval from '../utils/useInterval'

import Button from './Button'

// const useStyles = makeStyles(
//   theme => ({
//     form: {
//       padding: '0 1em 1em 1em',
//     },
//     input: {
//       marginTop: '1em',
//     },
//     button: {
//       width: '100%',
//       marginTop: '0.5rem',
//     },
//     icon: {
//       marginRight: theme.spacing(1),
//     },
//   }),
//   { name: 'RaLoginForm' },
// )

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = { touched: false, error: '' },
  input: inputProps,
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const LoginForm = props => {
  const login = useLogin()
  const notify = useNotify()
  // const classes = useStyles(props)
  const translate = useTranslate()
  const [verifyStep, setVerifyStep] = useState(false)
  const [resendTime, setResendTime] = useState(0)
  const [loading, setLoading] = useSafeSetState(false)

  const { redirectTo } = props
  useInterval(
    () => {
      setResendTime(resendTime - 1)
    },
    resendTime !== 0 ? 1000 : null,
  )

  const validate = values => {
    const errors = { username: undefined, password: undefined }

    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  const handleLogin = values => {
    setLoading(true)
    login(values, redirectTo)
      .then(() => {})
      .catch(error => {
        if (
          error.message &&
          error.message.includes('Verification sent to email')
        ) {
          setVerifyStep(true)
          setResendTime(60)
        }
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                  ? error.message
                  : undefined,
          },
        )
      })
      .finally(() => setLoading(false))
  }

  const handleResend = values => {
    login(values, redirectTo)
      .then(() => {})
      .catch(error => {
        if (
          error.message &&
          error.message.includes('Verification sent to email')
        ) {
          setVerifyStep(true)
          setResendTime(60)
        }
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          'warning',
          {
            typeof:
              typeof error === 'string'
                ? error
                : error && error.message
                  ? error.message
                  : undefined,
          },
        )
      })
      .finally(() => setLoading(false))
  }

  return (
    <Form
      onSubmit={handleLogin}
      validate={validate}
      render={props => {
        const { handleSubmit, values } = props
        return (
          <form onSubmit={handleSubmit}>
            <Card sx={{ borderRadius: 4 }}>
              <Box sx={{ paddingY: 4, paddingX: 4 }}>
                {verifyStep ? (
                  <Field
                    autoFocus
                    id="code"
                    name="code"
                    component={renderInput}
                    label="Verification code"
                    disabled={loading}
                  />
                ) : (
                  <Stack spacing={2}>
                    <Field
                      autoFocus
                      id="username"
                      name="username"
                      component={renderInput}
                      label={translate('ra.auth.username')}
                      disabled={loading}
                    />
                    <Field
                      id="password"
                      name="password"
                      component={renderInput}
                      label={translate('ra.auth.password')}
                      type="password"
                      disabled={loading}
                      autoComplete="current-password"
                    />
                  </Stack>
                )}
              </Box>
              <CardActions
                sx={{
                  flexDirection: 'column',
                  paddingTop: 0.5,
                  paddingBottom: 2,
                  paddingX: 4,
                }}>
                <Button
                  loading={loading}
                  variant="contained"
                  type="submit"
                  color="primary"
                  sx={{
                    width: '100%',
                  }}
                  disabled={loading}>
                  {translate('ra.auth.sign_in')}
                </Button>
                <Button
                  sx={{
                    width: '100%',
                    marginTop: 1,
                  }}
                  LinkComponent={Link}
                  href="/forgot">
                  Forgot password
                </Button>

                {verifyStep && (
                  <Stack marginTop={2}>
                    <div>{`Didn't receive the verification code?`}</div>
                    <Stack spacing={2} direction="row">
                      <Button
                        onClick={() => handleResend(values)}
                        sx={{ flex: 1 }}
                        disabled={resendTime}>
                        {resendTime
                          ? `Resend in ${resendTime} seconds`
                          : 'Resend'}
                      </Button>
                      <Button
                        onClick={() => setVerifyStep(false)}
                        sx={{ flex: 1 }}
                        style={{ width: '100%' }}>
                        Back
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </CardActions>
            </Card>
          </form>
        )
      }}
    />
  )
}

export default LoginForm
