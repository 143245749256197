import { Create } from 'react-admin'

import ConfigurationSettingForm from './ConfigurationSettingForm'

const ConfigurationSettingCreate = props => (
  <Create redirect="list" {...props}>
    <ConfigurationSettingForm isCreating />
  </Create>
)

export default ConfigurationSettingCreate
