import { Create } from 'react-admin'

import KidsStoryForm from './KidsStoryForm'

const KidsStoryCreate = props => (
  <Create redirect="list" {...props}>
    <KidsStoryForm permissions={props.permissions} />
  </Create>
)

export default KidsStoryCreate
