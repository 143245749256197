import { Edit } from 'react-admin'

import BookSummaryForm from './BookSummaryForm'

const BookSummaryEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <BookSummaryForm permissions={props.permissions} />
    </Edit>
  )
}

export default BookSummaryEdit
