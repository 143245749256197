import { Create } from 'react-admin'

import FullAccessAccountsForm from './FullAccessAccountsForm'

const FullAccessAccountsCreate = props => (
  <Create redirect="list" {...props}>
    <FullAccessAccountsForm />
  </Create>
)

export default FullAccessAccountsCreate
