import {
  TextInput,
  Form,
  useDataProvider,
  useNotify,
  SaveButton,
  Title,
} from 'react-admin'
import { Card, CardContent } from '@mui/material'

import { validateRequired, match } from '../utils/validate'

const ChangePasswordPage = () => {
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const handleSubmit = data => {
    return dataProvider
      .create('users/changePassword', {
        data: {
          oldPassword: data.current_password,
          password: data.new_password,
        },
      })
      .then(() => {
        notify('Change password successfully', { type: 'success' })
      })
      .catch(error => {
        notify(error.message, { type: 'error' })
      })
  }

  return (
    <Card>
      <Title title="Change Password" />
      <CardContent>
        <Form onSubmit={handleSubmit}>
          <TextInput
            label="Current password"
            source="current_password"
            type="password"
            variant="standard"
            validate={validateRequired('Current Password')}
          />
          <TextInput
            label="New password"
            source="new_password"
            type="password"
            variant="standard"
            validate={validateRequired('New Password')}
          />
          <TextInput
            label="Confirm password"
            source="confirm_password"
            type="password"
            variant="standard"
            validate={[
              validateRequired('Confirm Password'),
              match('new_password', 'New password'),
            ]}
          />

          <SaveButton />
        </Form>
      </CardContent>
    </Card>
  )
}

export default ChangePasswordPage
