import { Create } from 'react-admin'

import CoachForm from './CoachForm'

const CoachCreate = props => (
  <Create redirect="list" {...props}>
    <CoachForm />
  </Create>
)

export default CoachCreate
