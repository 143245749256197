import { Create } from 'react-admin'

import CourseForm from './CourseForm'

const CourseCreate = props => (
  <Create redirect="list" {...props}>
    <CourseForm isCreating permissions={props.permissions} />
  </Create>
)

export default CourseCreate
