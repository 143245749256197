import { Edit } from 'react-admin'

import CategoryForm from './CategoryForm'

const CategoryEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <CategoryForm editing />
    </Edit>
  )
}

export default CategoryEdit
