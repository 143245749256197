import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const CsUserCodeCreate = props => {
  return (
    <Create redirect="list" undoable={false} {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <ReferenceInput label="Username" source="userId" reference="users">
              <AutocompleteInput
                optionText="username"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
                validate={validateRequired('user')}
                filterToQuery={searchText => ({
                  username: { $iLike: `%${searchText}%` },
                })}
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12}>
            <ReferenceInput
              label="Code"
              source="codeId"
              reference="codes"
              filter={{ status: true }}>
              <AutocompleteInput
                optionText="code"
                options={{
                  fullWidth: true,
                  required: true,
                }}
                variant="standard"
                validate={validateRequired('code')}
                filterToQuery={searchText => ({
                  code: searchText,
                  status: true,
                })}
              />
            </ReferenceInput>
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default CsUserCodeCreate
