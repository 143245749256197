import { Create } from 'react-admin'

import SleepStoryForm from './SleepStoryForm'

const SleepStoryCreate = props => (
  <Create redirect="list" {...props}>
    <SleepStoryForm permissions={props.permissions} />
  </Create>
)

export default SleepStoryCreate
