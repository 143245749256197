import { Edit } from 'react-admin'

import KidsStoryForm from './KidsStoryForm'

const KidsStoryEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <KidsStoryForm permissions={props.permissions} />
    </Edit>
  )
}

export default KidsStoryEdit
