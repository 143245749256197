import { TextInput, SimpleForm, SelectInput } from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'

const TopicForm = props => {
  const TYPES = [
    { id: 'book', name: 'Book', disabled: props.editing },
    { id: 'english_book', name: 'English book', disabled: props.editing },
    { id: 'book_summary', name: 'Book summary', disabled: props.editing },
    { id: 'ebook', name: 'Ebook', disabled: props.editing },
    { id: 'kids_story', name: 'Kids Story', disabled: props.editing },
  ]

  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        {props.editing && (
          <Grid item xs={12}>
            <TextInput source="slug" fullWidth variant="standard" disabled />
          </Grid>
        )}
        <Grid item xs={8}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={[validateRequired('Type')]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            source="type"
            choices={TYPES}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Type')}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default TopicForm
