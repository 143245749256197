import {
  TextInput,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  minValue,
  ImageInput,
  SelectInput,
  FormDataConsumer,
  maxLength,
} from 'react-admin'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'

import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'
import CustomImageField from '../../components/CustomImageField'
import { validateRequired, validateUnique } from '../../utils/validate'
import { COLLECTION_TYPES } from '../../components/constants'

const CollectionForm = props => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput source="name" fullWidth variant="standard" />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            source="price"
            fullWidth
            variant="standard"
            validate={[validateRequired('Price'), minValue(0)]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">VND</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            source="slug"
            fullWidth
            variant="standard"
            validate={[
              validateRequired('Slug'),
              maxLength(255, 'Do not enter more than 255 characters'),
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <ImageInput
            source="bannerImageUrl"
            label="Banner image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Banner Image')}>
            <CustomImageField source="src" title="Banner image" />
          </ImageInput>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <ImageInput*/}
        {/*    source="bannerBackgroundUrl"*/}
        {/*    label="Banner background"*/}
        {/*    accept="image/*"*/}
        {/*    multiple={false}*/}
        {/*    validate={validateRequired('Banner background')}>*/}
        {/*    <CustomImageField source="src" title="Banner background" />*/}
        {/*  </ImageInput>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <ArrayInput
            label="Items"
            source="items"
            fullWidth
            validate={[
              validateRequired('Items'),
              validateUnique('entityId', true),
              validateUnique('order', true),
            ]}>
            <SimpleFormIterator>
              <NumberInput
                source="order"
                label="Order"
                variant="standard"
                fullWidth
              />
              <SelectInput
                label="Type"
                source="type"
                choices={COLLECTION_TYPES}
                fullWidth
                variant="standard"
              />
              <FormDataConsumer>
                {({ scopedFormData }) => {
                  if (!scopedFormData?.type) {
                    return null
                  }
                  return (
                    <ReferenceInput
                      label={
                        COLLECTION_TYPES.find(
                          ({ id }) => id === scopedFormData.type,
                        ).name
                      }
                      source={'entityId'}
                      reference={
                        COLLECTION_TYPES.find(
                          ({ id }) => id === scopedFormData.type,
                        ).entity
                      }
                      rows={6}>
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                        filterToQuery={searchText => ({
                          title: { $iLike: `%${searchText}%` },
                        })}
                      />
                    </ReferenceInput>
                  )
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ArrayInput
            label="Quotes"
            source="quotes"
            fullWidth
            validate={[
              validateRequired('Quotes'),
              validateUnique('order', true),
            ]}>
            <SimpleFormIterator>
              <NumberInput
                source="order"
                label="Order"
                variant="standard"
                fullWidth
              />
              <ImageInput
                source="avatar"
                label="Avatar"
                accept="image/*"
                multiple={false}
                validate={validateRequired('Avatar')}>
                <CustomImageField source="src" title="Avatar" />
              </ImageInput>
              <TextInput
                source="name"
                label="Name"
                fullWidth
                variant="standard"
                validate={validateRequired('Name')}
              />
              <TextInput
                source="title"
                label="Title"
                fullWidth
                variant="standard"
              />
              <TextInput
                source="quote"
                label="Quote"
                resettable
                rows={4}
                fullWidth
                variant="standard"
                multiline
                validate={[
                  validateRequired('Quote'),
                  maxLength(255, 'Do not enter more than 255 characters'),
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default CollectionForm
