import { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  SelectInput,
  FunctionField,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const PAYMENT_METHOD = {
  IAP: 'In app purchase',
  momo: 'Momo',
  'bank-transfer': 'Bank transfer',
  payoo: 'Payoo',
  'credit-card': 'Credit card',
}

const ManageSubscriptionFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by username" source="username" alwaysOn />
    <SelectInput
      label="Payment method"
      source="payment_method"
      choices={[
        { id: 'IAP', name: 'In app purchase' },
        { id: 'momo', name: 'Momo' },
        { id: 'bank_transfer', name: 'Bank transfer' },
        { id: 'payoo', name: 'Payoo' },
        { id: 'credit_card', name: 'Credit card' },
      ]}
      alwaysOn
    />
  </FilterComponent>
)

const ManageSubscription = props => {
  return (
    <Fragment>
      <List
        {...props}
        filters={<ManageSubscriptionFilter />}
        sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="username" />
          <TextField source="last_signin_method" />
          <FunctionField
            source="payment_method"
            render={record => PAYMENT_METHOD[record.payment_method]}
          />
          <DateField source="start_date" label="Start date" />
          <DateField source="end_date" label="End date" />
          <TextField source="code" label="Promo code" />
        </Datagrid>
      </List>
    </Fragment>
  )
}

export default ManageSubscription
