import Grid from '@mui/material/Grid'
import {
  TextInput,
  SimpleForm,
  AutocompleteInput,
  ImageInput,
} from 'react-admin'

import CustomImageField from '../../components/CustomImageField'
import GridContainer from '../../components/GridContainer'

import countryData from './countries'

const AuthorForm = props => {
  const { renderAvatar, ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={6}>
          <TextInput source="name" fullWidth variant="standard" />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteInput
            source="country"
            label="Country"
            options={{
              fullWidth: true,
            }}
            variant="standard"
            choices={Object.keys(countryData).map(key => ({
              id: key,
              name: countryData[key]['name']['common'],
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          {renderAvatar ? (
            renderAvatar(props)
          ) : (
            <ImageInput
              source="avatar"
              label="Avatar"
              accept="image/*"
              variant="standard"
              multiple={false}>
              <CustomImageField source="src" title="Avatar" />
            </ImageInput>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="about"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default AuthorForm
