import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  NumberField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  DateInput,
  TabbedShowLayout,
  useRecordContext,
  useListFilterContext,
} from 'react-admin'
import moment from 'moment/moment'

import ListStatusField from '../../components/ListStatusField'
import TextFieldTruncate from '../../components/TextFieldTruncate'
import FilterComponent from '../../components/Filter'
import { FILTER_DATE } from '../../components/constants'
import { exportExcel } from '../../utils/exportExcel'

const starChoices = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
]

const BookCustomFilter = props => {
  const { filterValues } = useListFilterContext()
  return (
    <FilterComponent {...props}>
      <SelectInput
        label="Filter"
        source="date"
        choices={FILTER_DATE}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
      <ReferenceInput label="Book" source="entityId" reference="books" alwaysOn>
        <AutocompleteInput
          optionText="title"
          filterToQuery={searchText => ({
            title: { $iLike: `%${searchText}%` },
          })}
        />
      </ReferenceInput>
      <SelectInput
        source="bookContent"
        choices={starChoices}
        alwaysOn
        allowEmpty={true}></SelectInput>
      <SelectInput
        source="voice"
        choices={starChoices}
        alwaysOn
        allowEmpty={true}></SelectInput>
    </FilterComponent>
  )
}

const CourseCustomFilter = props => {
  const { filterValues } = useListFilterContext()

  return (
    <FilterComponent {...props}>
      <SelectInput
        label="Filter"
        source="date"
        choices={FILTER_DATE}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
      <ReferenceInput
        label="Course"
        source="entityId"
        reference="courses"
        alwaysOn>
        <AutocompleteInput
          optionText="title"
          filterToQuery={searchText => ({
            title: { $iLike: `%${searchText}%` },
          })}
        />
      </ReferenceInput>
      <SelectInput
        source="rating"
        choices={starChoices}
        alwaysOn
        allowEmpty={true}></SelectInput>
    </FilterComponent>
  )
}

const CustomUserField = () => {
  const record = useRecordContext()
  const { userId } = record
  return (
    <a style={{ color: '#0000EE' }} href={`#/user-details/${userId}/show`}>
      {userId}
    </a>
  )
}

const BookReviewList = props => (
  <List
    {...props}
    filters={<BookCustomFilter />}
    filterDefaultValues={{ entity: 'book' }}
    filter={{ entity: 'book' }}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <ReferenceField label="Book" source="entityId" reference="books">
        <TextField source="title" />
      </ReferenceField>
      <NumberField source="bookContent" />
      <NumberField source="voice" />
      <TextField source="headLine" />
      <CustomUserField label="User" />
      <TextFieldTruncate source="review" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ListStatusField resource="reviews" label="Status" />
    </Datagrid>
  </List>
)

const courseExporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      'PodCourse Id': record.entityId,
      rating: record.rating,
      'User Name': record.user && record.user.name,
      'User Id': record.userId,
      Positive: record.review,
      Negative: record.suggestion,
      'Created at':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      'Updated at':
        record.updatedAt && moment(record.updatedAt).format('DD/MM/YYYY'),
      status: record.status,
    }
  })

  exportExcel({
    data,
    title: 'PodCourse reviews',
    type: 'xlsx',
  })
}

const CourseReviewList = props => (
  <List
    {...props}
    exporter={courseExporter}
    filters={<CourseCustomFilter />}
    filterDefaultValues={{ entity: 'course' }}
    filter={{ entity: 'course' }}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <ReferenceField label="Course" source="entityId" reference="courses">
        <TextField source="title" />
      </ReferenceField>
      <NumberField source="rating" />
      <CustomUserField label="User" />
      <TextFieldTruncate source="review" label={'Positive'} />
      <TextFieldTruncate source="suggestion" label={'Negative'} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ListStatusField resource="reviews" label="Status" />
    </Datagrid>
  </List>
)

const ReviewList = () => (
  <List pagination={null}>
    <TabbedShowLayout record={{ id: 1, title: 'Hello world' }}>
      <TabbedShowLayout.Tab label="Audiobook">
        <BookReviewList />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="PodCourse">
        <CourseReviewList />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </List>
)

export default ReviewList
