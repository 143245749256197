import {
  TextInput,
  FileInput,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  SimpleForm,
  SelectInput,
  usePermissions,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import CustomFileField from '../../components/CustomFileField'
import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import { validateRequired } from '../../utils/validate'
import { ROLES } from '../../components/constants'
import GridContainer from '../../components/GridContainer'

const isFree = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]
const statuses = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]

const MeditationForm = () => {
  const { permissions } = usePermissions()

  return (
    <SimpleForm toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="title"
            fullWidth
            variant="standard"
            validate={[validateRequired('Title')]}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Is free?"
            source="isFree"
            choices={isFree}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Is Free Meditation')}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Publish"
            source="status"
            choices={statuses}
            default={false}
            fullWidth
            variant="standard"
            required
            validate={validateRequired('Publish')}
          />
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            label="Primary category"
            source="primaryCategoryId"
            filter={{
              type: 'meditation',
            }}
            reference="categories">
            <AutocompleteInput
              optionText="name"
              fullWidth
              required
              variant="standard"
              validate={validateRequired('Primary category')}
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                type: 'meditation',
              })}
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={8}>
          <ReferenceArrayInput
            label="Secondary categories"
            source="secondaryCategoryIds"
            filter={{
              type: 'meditation',
            }}
            reference="categories">
            <AutocompleteArrayInput
              optionText="name"
              fullWidth
              required
              variant="standard"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                type: 'meditation',
              })}
            />
          </ReferenceArrayInput>
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="description"
            resettable
            rows={5}
            fullWidth
            variant="standard"
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="coverImageUrl"
            label="Cover image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Cover Image')}>
            <CustomImageField source="src" title="Cover image" />
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <FileInput source="maleVoiceUrl" accept="audio/*" multiple={false}>
            <CustomFileField
              source="src"
              title="Male Voice"
              canDownload={permissions === ROLES.ADMIN}
            />
          </FileInput>
        </Grid>
        <Grid item xs={12}>
          <FileInput source="femaleVoiceUrl" accept="audio/*" multiple={false}>
            <CustomFileField
              source="src"
              title="Female Voice"
              canDownload={permissions === ROLES.ADMIN}
            />
          </FileInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default MeditationForm
