import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  email,
  minValue,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  maxValue,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const AffiliateCollectionCreate = props => {
  return (
    <Create redirect="list" {...props}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <TextInput
              label="Name"
              source="name"
              fullWidth
              validate={validateRequired('Name')}
              variant="standard"
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label="Email Address"
              source="email"
              type="email"
              fullWidth
              validate={email()}
              variant="standard"
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label="Url slug"
              source="slug"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <DateInput
              label="Start date"
              source="startAt"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <DateInput
              label="Expire date"
              source="expireAt"
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <NumberInput
              label="Commission"
              source="commission"
              fullWidth
              validate={(minValue(0), maxValue(100))}
              variant="standard"
            />
          </Grid>

          <Grid item xs={12}>
            <ReferenceInput
              variant="standard"
              label="Collection"
              source="collectionId"
              reference="collection-codes">
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true,
                }}
                filterToQuery={searchText => ({
                  name: { $iLike: `%${searchText}%` },
                })}
              />
            </ReferenceInput>
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Create>
  )
}

export default AffiliateCollectionCreate
