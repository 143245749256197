import Grid from '@mui/material/Grid'
import { TextInput, SimpleForm } from 'react-admin'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'

const VoiceActorForm = props => {
  return (
    <SimpleForm {...props} redirect="list">
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={validateRequired('Name')}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default VoiceActorForm
