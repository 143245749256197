import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  SelectInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

const isAutoRenew = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]

const FreeAccountsForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list" variant="standard" margin="normal">
      <GridContainer>
        <Grid item xs={6}>
          <ReferenceInput label="Username" source="userId" reference="users">
            <AutocompleteInput
              optionText="username"
              options={{
                fullWidth: true,
                disabled: props.editing ? true : false,
              }}
              variant="standard"
              validate={validateRequired('user')}
              filterToQuery={searchText => ({
                username: { $iLike: `%${searchText}%` },
              })}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Is auto renew?"
            source="autoRenew"
            choices={isAutoRenew}
            fullWidth
            variant="standard"
            required
            defaultValue={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Influencer name"
            source="influencerName"
            required
            fullWidth
            variant="standard"
            validate={validateRequired('influencer name')}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default FreeAccountsForm
