import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteButton,
  downloadCSV,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'

const ExportButton = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'userId', 'users').then(userOne => {
    fetchRelatedRecords(records, 'createdBy', 'users').then(userTwo => {
      const data = records.map(record => ({
        Id: record.id,
        Username: userOne[record.userId].username,
        'Created by': userTwo[record.createdBy].name,
        'Updated by': userTwo[record.updatedBy].name,
      }))

      jsonExport(
        data,
        {
          headers: ['Id', 'Username', 'Created by', 'Updated by'],
        },
        (err, csv) => {
          downloadCSV(csv, 'Full Access Account')
        },
      )
    })
  })
}

const FullAccessAccountsList = props => (
  <List
    {...props}
    exporter={ExportButton}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users" label="Username">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="createdBy" reference="users" label="Created by">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="updatedBy" reference="users" label="Updated by">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
      <DeleteButton undoable={true} />
    </Datagrid>
  </List>
)

export default FullAccessAccountsList
