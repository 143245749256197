const exportExcel = ({ data, title, type }) => {
  if (!window.XLSX) return

  const ws = window.XLSX.utils.json_to_sheet(data)

  const wb = window.XLSX.utils.book_new()
  window.XLSX.utils.book_append_sheet(wb, ws, title)

  window.XLSX.writeFile(wb, `${title}.${type}`)
  return
}

const tryMergeRows = (worksheet, value) => {
  try {
    worksheet.unMergeCells(value)
  } catch (e) {
    console.error(`Error on unMerge cells: ${e}`, e)
  }

  try {
    worksheet.mergeCells(value)
  } catch (e) {
    console.error(`Error on merge cells: ${e}`, e)
  }
}

export { exportExcel, tryMergeRows }
