import { Edit } from 'react-admin'

import PodcasterForm from './EpisodeForm'

const EpisodeEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <PodcasterForm permissions={props.permissions} />
    </Edit>
  )
}

export default EpisodeEdit
