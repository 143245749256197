import { Create } from 'react-admin'

import GuidedMeditationForm from './GuidedMeditationForm'

const GuidedMeditationCreate = props => {
  return (
    <Create redirect="list" {...props}>
      <GuidedMeditationForm isCreating />
    </Create>
  )
}

export default GuidedMeditationCreate
