import { Create } from 'react-admin'

import CategoryGroupForm from './CategoryGroupForm'

const CategoryGroupCreate = props => (
  <Create redirect="list" {...props}>
    <CategoryGroupForm />
  </Create>
)

export default CategoryGroupCreate
