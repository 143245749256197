import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
} from 'react-admin'

import ListStatusField from '../../components/ListStatusField'

const AUDIO_TYPE_TITLE = {
  music: 'Music',
  book_summary: 'Book summary',
  meditation: 'Meditation',
  sleep_story: 'Sleep story',
  kids_story: 'Kids story',
}

const NEWS_FEED_TITLE = {
  book: 'Book',
  audio: 'Audio',
  audio_list: 'Audio list',
  link_url: 'Link url',
}

const AUDIO_TYPE_REFERENCE = {
  music: 'musics',
  book_summary: 'book-summaries',
  meditation: 'meditations',
  sleep_story: 'sleep-stories',
  kids_story: 'kids-stories',
}

const TypeTextField = ({ source, record = {} }) => (
  <span>{NEWS_FEED_TITLE[record[source]]}</span>
)

const BookTextField = ({ record = {}, ...rest }) => {
  if (record.type !== 'book') return <span>-</span>

  return (
    <ReferenceField source="bookId" reference="books" record={record} {...rest}>
      <TextField source="title" />
    </ReferenceField>
  )
}

const AudioTextField = ({ record = {}, ...rest }) => {
  if (record.type !== 'audio') return <span>-</span>

  return (
    <ReferenceField
      source="audioId"
      reference={AUDIO_TYPE_REFERENCE[record.audioType]}
      record={record}
      {...rest}>
      <TextField source="title" />
    </ReferenceField>
  )
}

const AudioTypeTextField = ({ record = {} }) => {
  if (record.type === 'book') return <span>-</span>

  return <span>{AUDIO_TYPE_TITLE[record.audioType]}</span>
}

const NewsFeedList = props => (
  <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="title" />
      <TypeTextField source="type" />
      <BookTextField label="Book" />
      <AudioTypeTextField label="Audio type" />
      <AudioTextField label="Audio" />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ListStatusField resource="news-feeds" label="Status" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

export default NewsFeedList
