import { Edit } from 'react-admin'

import B2BOrganizationForm from './B2BTrialUserForm'

const B2BTrialUserEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <B2BOrganizationForm editing />
    </Edit>
  )
}

export default B2BTrialUserEdit
