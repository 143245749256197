import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DateField,
  TextInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  usePermissions,
} from 'react-admin'

import ListStatusField from '../../components/ListStatusField'
import IsFreeField from '../../components/IsFreeField'
import DeleteButton from '../../components/DeleteButton'
import FilterComponent from '../../components/Filter'

const Actions = props => {
  const { resource, currentSort, filterValues, exporter, basePath } = props
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={false}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={10000}
      />
    </TopToolbar>
  )
}

const Filter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
  </FilterComponent>
)

const KidsStoryList = () => {
  const { permissions } = usePermissions()

  return (
    <List
      bulkActionButtons={false}
      filters={<Filter />}
      actions={<Actions />}
      sort={{ field: 'updatedAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="title" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <IsFreeField resource="kids-stories" label="Is Free" />
        <ListStatusField resource="kids-stories" label="Publish" />
        <EditButton />
        <DeleteButton permissions={permissions} />
      </Datagrid>
    </List>
  )
}

export default KidsStoryList
