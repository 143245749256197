import { Create } from 'react-admin'

import ReferralBookForm from './ReferralBookForm'

const ReferralBookCreate = props => (
  <Create redirect="list" {...props}>
    <ReferralBookForm />
  </Create>
)

export default ReferralBookCreate
