import { useState } from 'react'
import { useRecordContext } from 'react-admin'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import get from 'lodash/get'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'

import { COURSE_STATUSES } from './constants'

const CourseStatusField = props => {
  const { disabled } = props
  const record = useRecordContext()
  const updateDataProvider = useUpdateDataProvider()
  const [status, setStatus] = useState(record?.status)

  const handleChange = async () => {
    const data = {
      status:
        status === COURSE_STATUSES.PUBLISHED
          ? COURSE_STATUSES.UNPUBLISHED
          : COURSE_STATUSES.PUBLISHED,
    }

    const response = await updateDataProvider(
      'courses',
      { id: record.id, data },
      {
        onSuccess: {
          notification: { body: 'Course updated', level: 'info' },
          redirectTo: '/courses',
        },
        onFailure: {
          notification: {
            body: 'Error: courses not updated',
            level: 'warning',
          },
        },
      },
    )
    if (get(response, 'data.status', null)) {
      setStatus(response.data.status)
    }
  }

  return [COURSE_STATUSES.PUBLISHED, COURSE_STATUSES.UNPUBLISHED].includes(
    record.status,
  ) ? (
    <FormControlLabel
      control={
        <Switch
          checked={status === COURSE_STATUSES.PUBLISHED}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={status}
    />
  ) : (
    status
  )
}

export default CourseStatusField
