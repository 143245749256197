import { useState } from 'react'
import { useRecordContext } from 'react-admin'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import get from 'lodash/get'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'

import { ENGLISH_BOOK_STATUSES } from './constants'

const EnglishBookStatusField = props => {
  const { disabled } = props
  const updateDataProvider = useUpdateDataProvider()
  const record = useRecordContext()
  const [status, setStatus] = useState(record.status)

  const handleChange = async () => {
    const data = {
      status:
        status === ENGLISH_BOOK_STATUSES.PUBLISHED
          ? ENGLISH_BOOK_STATUSES.UNPUBLISHED
          : ENGLISH_BOOK_STATUSES.PUBLISHED,
    }

    const response = await updateDataProvider(
      'english-books',
      { id: record.id, data },
      {
        onSuccess: {
          notification: { body: 'EnglishBook updated', level: 'info' },
          redirectTo: '/english-books',
        },
        onFailure: {
          notification: {
            body: 'Error: english-books not updated',
            level: 'warning',
          },
        },
      },
    )
    if (get(response, 'data.status', null)) {
      setStatus(response.data.status)
    }
  }

  return [
    ENGLISH_BOOK_STATUSES.PUBLISHED,
    ENGLISH_BOOK_STATUSES.UNPUBLISHED,
  ].includes(record.status) ? (
    <FormControlLabel
      control={
        <Switch
          checked={status === ENGLISH_BOOK_STATUSES.PUBLISHED}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={status}
    />
  ) : (
    status
  )
}

export default EnglishBookStatusField
