const TIER_PRICE = {
  tier5: 129000,
}

const PRICE_CHOICES = Object.values(TIER_PRICE).map(price => ({
  id: price,
  name: new Number(price).toLocaleString('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }),
}))

export { PRICE_CHOICES }
