import { Fragment } from 'react'
import {
  DateField,
  DateInput,
  Edit,
  email,
  FormDataConsumer,
  ImageInput,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { validateRequired } from '../../utils/validate'
import GridContainer from '../../components/GridContainer'
import PeriodCommissionsInput from '../../components/PeriodCommissionsInput'
import CustomImageField from '../../components/CustomImageField'

const validateCommission = [minValue(0), maxValue(100)]

export const publisherTypeOptions = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
]

export const transformDataBeforeSubmit = values => {
  let { publisherType, commissions, ...rest } = values

  if (commissions) {
    commissions = commissions.map(commission => {
      const { publisherType, ...restData } = commission
      return {
        ...restData,
        publisherType,
        ...(publisherType === '3' ? { taxFeePercentage: 0 } : {}),
        ...(publisherType === '4' ? { platformFeePercentage: 0 } : {}),
        ...(publisherType === '5' ? { taxFeePercentage: 5 } : {}),
      }
    })
  }
  return {
    ...rest,
    publisherType,
    commissions,
    ...(publisherType === '3' ? { taxFeePercentage: 0 } : {}),
    ...(publisherType === '4' ? { platformFeePercentage: 0 } : {}),
    ...(publisherType === '5' ? { taxFeePercentage: 5 } : {}),
  }
}

const UserEdit = props => {
  return (
    <Edit {...props} transform={transformDataBeforeSubmit}>
      <SimpleForm>
        <GridContainer>
          <Grid item xs={12}>
            <Typography variant="h6">General Information</Typography>
          </Grid>
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData) return null

              if (formData.role === 'publisher') {
                return (
                  <Fragment>
                    <Grid item xs={12}>
                      <ImageInput
                        source="avatarUrl"
                        label="Avatar"
                        accept="image/*"
                        multiple={false}>
                        <CustomImageField source="src" title="Avatar" />
                      </ImageInput>
                    </Grid>
                  </Fragment>
                )
              }
            }}
          </FormDataConsumer>
          <TextInput
            fullWidth
            variant="standard"
            label="Username"
            source="username"
            disabled
          />
          <TextInput
            fullWidth
            variant="standard"
            label="Email Address"
            source="email"
            type="email"
            validate={[validateRequired('Email'), email()]}
          />
          <TextInput fullWidth variant="standard" source="name" />
          <ReferenceInput label="Role" source="role" reference="roles" alwaysOn>
            <SelectInput
              optionText="name"
              fullWidth
              variant="standard"
              required
              validate={validateRequired('Role')}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (!formData) return null

              if (formData.role === 'publisher') {
                return (
                  <Fragment>
                    <Grid item xs={12}>
                      <TextInput
                        fullWidth
                        variant="standard"
                        key={1}
                        source="publisherCompany"
                        {...rest}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        source="description"
                        resettable
                        rows={3}
                        fullWidth
                        variant="standard"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Commissions</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <SelectInput
                        label="Publisher type"
                        source="publisherType"
                        choices={publisherTypeOptions}
                        required
                        fullWidth
                        variant="standard"
                        margin="normal"
                        validate={validateRequired('Publisher type')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {formData.publisherType === '3' ||
                      formData.publisherType === '5' ? (
                        <NumberInput
                          fullWidth
                          variant="standard"
                          label="% Platform fee (0-100)"
                          source="platformFeePercentage"
                          margin="normal"
                          validate={[
                            validateRequired('% Platform fee'),
                            minValue(0),
                            maxValue(100),
                          ]}
                          classes={{ root: 'number-input-without-arrows' }}
                        />
                      ) : null}
                      {formData.publisherType === '4' ||
                      formData.publisherType === '5' ? (
                        <NumberInput
                          fullWidth
                          variant="standard"
                          label="% Tax fee (0-100)"
                          source="taxFeePercentage"
                          margin="normal"
                          validate={[
                            validateRequired('% Tax fee'),
                            minValue(0),
                            maxValue(100),
                          ]}
                          classes={{ root: 'number-input-without-arrows' }}
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        fullWidth
                        variant="standard"
                        key={2}
                        label="Commission (0-100)"
                        source="defaultCommission"
                        validate={validateCommission}
                        classes={{ root: 'number-input-without-arrows' }}
                      />
                      <PeriodCommissionsInput
                        fullWidth
                        variant="standard"
                        label="Period commissions"
                        source="commissions"
                        entity="user"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6">Contract Information</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <SelectInput
                            source="periodicPayment"
                            choices={[
                              { id: 1, name: '1 tháng' },
                              { id: 2, name: '2 tháng' },
                              {
                                id: 3,
                                name: '3 tháng',
                              },
                              {
                                id: 4,
                                name: '4 tháng',
                              },
                              {
                                id: 6,
                                name: '6 tháng',
                              },
                              {
                                id: 12,
                                name: '12 tháng',
                              },
                              {
                                id: 0,
                                name: 'Không thanh toán',
                              },
                            ]}
                            fullWidth
                            variant="standard"
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <DateInput
                        label="Ngày ký hợp đồng"
                        source="contractSigningDate"
                        fullWidth
                        variant="standard"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DateInput
                        label="Ngày hết hạn hợp đồng"
                        source="contractExpirationDate"
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                  </Fragment>
                )
              }
            }}
          </FormDataConsumer>
          <Grid>
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
          </Grid>
        </GridContainer>
      </SimpleForm>
    </Edit>
  )
}

export default UserEdit
